import actions from './actions'

const initialState = {
  loading: true,
  loadingDetail: true,
  productData: [],
  productDetail: null,
  productDetailReview: [],
  productDetailReviewCount: 0,
  productDetailReviewStars: {
    star1: 0,
    star2: 0,
    star3: 0,
    star4: 0,
    star5: 0,
  },
  totalRating:0,
  productVariant: [],
  productDetailVariant: [],
  productDetailVariantColor: [],
  productImages: [],
  variants: [],
  variantType: [],
  variantDetail: [],
  variantValue: [],
  searchData: [],
  searchLoading: false,
  currentPage: 0,
  lastPage: 0,
  totalProduct: 0,
  addBeforeLogin: null
  
}

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
