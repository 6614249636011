import React, { useEffect } from 'react';
import { Container } from 'components/grid';
import Variables from 'utils/variables';
import { Col, Row, Badge } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { currencyFormat } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { YahooOutlined } from '@ant-design/icons';
const HomeBannerCategory = () => {
  const dispatch = useDispatch()
  const productData = useSelector(({ home }) => home.productData)
  const categoryData = useSelector(({ home }) => home.categoryData)
  const categoryLoading = useSelector(({ home }) => home.categoryLoading)
  const { t } = useTranslation()



  const c = categoryData.sort((a, b) => parseFloat(a.UrutanWebBanner) - parseFloat(b.UrutanWebBanner));
  return (
    <>
      <div className="bolde-home-category">
        <div className="bolde-home-category-wrapper">
          <Container>
            {
              categoryLoading &&
              <>
                <Skeleton height={340} width={340} shape="circle" className="mr-4 mb-2 mt-2" />
                <Skeleton height={340} width={340} shape="circle" className="mr-2 ml-2 mb-2 mt-2" />
                <Skeleton height={340} width={340} shape="circle" className="ml-4 mb-2 mt-2" />
              </>

            }
            {
              !categoryLoading &&
              <Row gutter={[{ xs: 8, sm: 48, md: 48, lg: 48 }]} justify="space-between">
                {
                  c.map(x => {
                    return <Col xs={{ span: 8, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }}>
                      <div className="bolde-categoryproduct text-center">
                        <div className="bolde-categoryproduct--img">
                          <img src={Variables.BASE_IMG_CATEGORY + x.Image} className="img-fluid" alt="Dream" />
                          <div className="bolde-product--fly_category -btn-a" style={{ borderColor: 'none !important' }}>
                            <h1 className="bolde-font-normal_bebas_regular "> {x.Category} </h1>
                            {
                              x.IsActive &&
                              <Link className=" bolde-bnt btn-global-bolde  px-3 shadow-sm my-1" style={{ borderColor: 'none !important' }} to={'/products'}>
                                {t('Button_Shop')}
                              </Link>
                            }
                          </div>
                        </div>
                      </div>
                    </Col>
                  })
                }
              </Row>
            }
          </Container>
        </div>
      </div>
    </>
  );
}

export default HomeBannerCategory;