import React, {Suspense}  from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Router from './router'
import reportWebVitals from 'reportWebVitals'

import './i18n';

import 'antd/dist/antd.css';
import './assets/styles/main.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; 
// middlewared
const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers(history), composeEnhancers(applyMiddleware(...middlewares)));
// const [navbar, setNavbar] =  useState(false)
sagaMiddleware.run(sagas);
   
ReactDOM.render(
  <Suspense fallback={(<div>Loading ---</div>)}>
  <Provider store={store}>
    <Router history={history} />
  </Provider>
  </Suspense>,
  document.getElementById('root'), 
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA 

serviceWorkerRegistration.register();

reportWebVitals();
export { store, history }
