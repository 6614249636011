import axios from 'axios'
import APIS from 'utils/apis'
import { getHeaderWoAuth } from 'utils/headers'

export function GET_VRT__ALL() {
  return axios.get(APIS.API_VARIANTS, {headers: getHeaderWoAuth()}).then(res => res.data)
}

export function GET_VRT__DETAIL() {
  return axios.get(APIS.API_VARIANT__DETAIL, {headers: getHeaderWoAuth()}).then(res => res.data)
}

export function GET_VRT__TYPE() {
  return axios.get(APIS.API_VARIANT__TYPE, {headers: getHeaderWoAuth()}).then(res => res.data)
}

export function GET_VRT__VALUE() {
  return axios.get(APIS.API_VARIANT__VALUE, {headers: getHeaderWoAuth()}).then(res => res.data)
}

