
import React, { useEffect, useState } from 'react';
import { Container } from 'components/grid'; 
import {Affix, Col,  Row, Modal } from 'antd'; 
import Variables from 'utils/variables';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MenuOutlined, ArrowLeftOutlined } from '@ant-design/icons'; 

const logo = Variables.BASE_IMG_LOCAL + '/logo-dream.png';

const  HeaderTransaksi = () => {
  const history = useHistory()
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn)
  const settings = useSelector(({ settings }) => settings);
  const categoryData = useSelector(({ settings }) => settings.categoryData)
  const categoryProduct = useSelector(({ settings }) => settings.categoryProduct)
  const categoryLoading = useSelector(({ settings }) => settings.categoryLoading)
  const searchLoading = useSelector(({ product }) => product.searchLoading)
  const searchData = useSelector(({ product }) => product.searchData)
  const cartTotalItems = useSelector(({ cart }) => cart.cartTotalItems) 
  const cartItems = useSelector(({ cart }) => cart.cartItems)
  const marqueetext = useSelector(({home}) => home.marquee)
  // const auth = useSelector(({ auth }) => auth)

  const [productByCategory, setProductByCategory] = useState([])
  const [categorySelected, setCategorySelected] = useState(null)
  const [visible, setVisible] = useState(false);
  const [keyword, setKeyword] = useState('')

  const { isSidebarOpen, isMobileView } = settings;
  // const accountUrl = auth.isLoggedIn ? '/account' : '/login';

  useEffect(() => {
    if (categoryData.length > 0) {
      setCategorySelected(categoryData[0]);
    }
    if (categoryProduct.length > 0) {
      const defProduct = categoryProduct.filter((x) => x.categoryID === categoryData[0].categoryID)
      setProductByCategory(defProduct);
    }


  }, [categoryProduct, categoryData])


  const handleSidebar = () => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isSidebarOpen',
        value: !isSidebarOpen,
      }
    });
  }
 
  const handleRedirect = () => {
    history.push('/')
  }
  useEffect(() => {
    if (categoryData.length > 0) {
      setCategorySelected(categoryData[0]);
    }
    if (categoryProduct.length > 0) {
      const defProduct = categoryProduct.filter((x) => x.categoryID === categoryData[0].categoryID)
      setProductByCategory(defProduct);
    }
  }, [categoryProduct, categoryData])


  

  return (
    <>
  
      <div className="bolde-header-transaksi">
        <Container position="static" justify="center">
          <Row justify="space-between mt-2 " align="middle">
            <Col xs={8} sm={8} md={8} lg={8}>
              <a style={{color:'#000', fontSize:'12px'}} href="/products"> <ArrowLeftOutlined /> </a>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8}>
              {
                !isMobileView &&
                <div className="bolde-logo text-center text-justify">
                  <a href="/">
                  <img   src={logo} style={{width:'35%'}} className="img-fluid" alt="" />
                  </a>
                </div>
              }
              {
                isMobileView &&
                <div className="bolde-logo mx-auto text-center text-justify">
                  <img src={logo}  style={{width:'60px'}} className="img-fluid" alt="" />
                </div>
              }
            </Col>
            <Col xs={8} sm={8} md={8} lg={8}>
               
               </Col>
          </Row>
        </Container>
      </div>  
    
   
    </>
  );
}

export default HeaderTransaksi;