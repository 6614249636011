import axios from 'axios'
import APIS from 'utils/apis'
import { getHeaderWoAuth } from 'utils/headers'

export function SIGNUP(payload) {
  return axios.post(APIS.API_SIGNUP, payload, {headers: getHeaderWoAuth()}).then(res => res.data)
}

export function SIGNUP__OTP(payload) {
  return axios.post(`${APIS.API_SIGNUP}/otp`, payload, {headers: getHeaderWoAuth()}).then(res => res.data)
}

export function SIGNUP_CONFIRM__OTP(payload) {
  return axios.post(`${APIS.API_SIGNUP}/otp/confirm`, payload, {headers: getHeaderWoAuth()}).then(res => res.data)
}

export function SIGNIN(payload) {
  return axios.post(APIS.API_LOGIN, payload, {headers: getHeaderWoAuth()}).then(res => res.data)
}

export function SIGNIN__OTP(payload) {
  return axios.post(`${APIS.API_LOGIN}/otp`, payload, {headers: getHeaderWoAuth()}).then(res => res.data)
}

export function SIGNIN_CONFIRM__OTP(payload) {
  return axios.post(`${APIS.API_LOGIN}/otp/confirm`, payload, {headers: getHeaderWoAuth()}).then(res => res.data)
}
export function GET_MARQUEE() {
  return axios.get(APIS.API_MARQUEE, {headers: getHeaderWoAuth()}).then(res => res.data)
}

 
