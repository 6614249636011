import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
// import translationEN from "assets/locales/en/translation.json";
// import translationID from "assets/locales/id/translation.json"; 

// const fallbackLng = ["en"];
const  Languages = ["id", "en-US"];

// const resources = {
//   en: {
//     translation: translationEN
//   },
//   id: {
//     translation: translationID
//   } 
// };

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
     
      useSuspense: false, 
    },
    // resources,
    // fallbackLng,

    detection: {
      checkWhitelist: true
    },
    // fallbackLng:'id',
    debug: false,

    whitelist: Languages,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
