import React from 'react';
import HeaderTransaksi from '../HeaderTransaksi'; 




const Transaksi = ({ children }) => {
    return (
    <>
            <HeaderTransaksi />
            {children} 
   </>
    )
}
export default Transaksi;