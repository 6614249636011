import React, { useState, useEffect } from 'react';
import Variables from 'utils/variables';  
import { Drawer, Button, Row, Col} from 'antd'; 
import { useDispatch, useSelector } from 'react-redux'; 
import { CartProduct} from 'components/cart_product';  
import { useTranslation } from 'react-i18next';

// import {LoadingOutlined } from '@ant-design/icons'; 




// const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />; 
export const CartDrawer = ({cartproduct}) =>
{ 
    const dispatch = useDispatch(); 
    const ShowDrawerCart = useSelector( ({settings}) => settings.ShowDrawerCart) 
    
    const isMobileView = useSelector(({ settings }) => settings.isMobileView)
    // const cartItems = useSelector(({ cart }) => cart.cartItems) 
    const cartItems = useSelector(({ cart }) => cart.cartItems)
    const cartID = useSelector(({ cart }) => cart.cartID)
    const cartNote = useSelector(({ cart }) => cart.cartNote) 
  
    // const loading  = useSelector(({account})=> account.loading)
    const [cartDetailID, setCartDetailID] = useState([])

    const widthcart = isMobileView ? '100%' : '410px'
    // const [total, setTotal] = useState(0)
    const [note, setNote] = useState('')
    // const [disableCheckout, setDisableCheckout] = useState(false)
    const { t } = useTranslation()
    useEffect(() => {
        let tot = 0
        const crtDtlID = []
        cartItems.map(x => {
          if (!crtDtlID.includes(x.cartItemID)) {
            crtDtlID.push(x.cartItemID)
          }
          return tot += x.cartItemTotal
        })
        // cartItems.filter(x => x.cartWarning === true).length > 0 ? setDisableCheckout(true) : setDisableCheckout(false)
        setNote(cartNote)
        // setTotal(tot);
        setCartDetailID(crtDtlID)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [cartItems])
    
    const onClose = () => {
        dispatch({
            type:'settings/HANDLE_STATE',
            field:'ShowDrawerCart',
            value:false 
          })
      };

      const handleCheckOut = () => {
        dispatch({
          type: 'cart/CHECKOUT',
          payload: {
            CartId: cartID,
            CartDetailId: cartDetailID,
            Note: note
          }
        })
      } 
     
    return(
 
    <> 
        <Drawer  
            style={{borderBottom:'0px !important'}}
            title={<h1 className=" bolde-font__lg">{t('TextLabel_Cart')}</h1>} placement="right" 
            onClose={onClose} 
            visible={ShowDrawerCart} 
            width={widthcart}>
                {
                    cartItems.length > 0 &&
                    cartproduct.map((x,y)=> {

                        return <CartProduct key={y} data={x} />
                    } )


                }
                {
                    cartItems.length < 1 && 
                    <>
                    <div className="text-center mt-5"
                     style={{
                      background: 'rgba(255, 255, 255, 0.6)',
                      zIndex:1,
                      width: '100%',
                      minHeight: '100%',
                      marginTop:'150px !important',
                      filter:'none'
                    }}> 
                     <img  width={100} src={Variables.BASE_IMG_LOCAL + '/keranjang kosong.png'} alt=""/> 
                      <p className="text-center mt-2"> {t('Cart_text1')} <br/> {t('Cart_text2')}</p>
                    </div> 
                    </>
                }

            <div
                style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'center',
                left: 0,
                height:'50px',
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                }}
              >
         

            {
                cartItems.length > 0 &&
                <Row gutter={[16, 16]}>
                {/* <Col className="text-left" span={12}>
                  <p>Total</p> 
                </Col>
                <Col className="text-right"  span={12}>
                  <p>Rp. {currencyFormat(total)}</p>
                </Col> */}
                <Col span={24}>
                    <Button className="btn-dream-primary" block onClick={handleCheckOut} type="primary">
                      {t('TextButton_Cart1')}
                      </Button>
                </Col>
              </Row>          
            }
            {
                cartItems.length < 1 && 
              <Row gutter={[16, 16]}>
               <Col span={24}>
                  <Button className="btn-dream-primary" onClick={()=> window.location.href='/products'}  block type="primary">
                    {t('TextButton_Cart2')}
                    </Button>
              </Col>
              </Row> 
            }

       
          </div>
           
         </Drawer>
 
         </>

    )

}