import actions from './actions'

const initialState = { 
  isLoading: false, 
  journeyDetail:[],
  status: null,
}

export default function journeyReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}