import { all, takeEvery, put, call, take } from 'redux-saga/effects'
import actions from './actions'
import { GET_ARTC, GET_CATALOG } from 'services/axios/article'
import { GET_BANNER } from 'services/axios/banner'
import { GET_CTG } from 'services/axios/category'
import { GET_PRD } from 'services/axios/product'
import { GET_MARQUEE } from 'services/axios/auth'
import { GET_TESTIMONIAL } from 'services/axios/chat'


// function BannerDepan(res)
// {

//   const bannerutama = []
//   if (res.length > 0) {
//     res.map(x => {
//       const varDtl = []
//       const varVal = []
//       x.variant_detail.map(y => {
//         if (parseInt(y.VariantId) === parseInt(x.VariantId)) {
//           varDtl.push(parseInt(y.VariantDetailId))
//         }
//       })
//       x.variant_detail.map(y => {
//         if (!varVal.includes(y.value.ValueId))
//           varVal.push(parseInt(y.value.ValueId))
//       })
//       if (!x.DeletedBy) {

//         bannerutama.push({
  
//           variantIsDiskon : x.isdiskon,
//         })

//       }
//     })
//   }
//   return detailVariant
// }
export function* GET_CATALOG_LIST(){

  yield put({
    type: 'home/SET_STATE',
    payload: {
      catalogLoading: true
    }
  })
  try {
    const res = yield call(GET_CATALOG);  
    yield put({
      type: 'home/SET_STATE',
      payload: {
        catalogData: res.Catalog, 
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'home/SET_STATE',
      payload: {
        catalogLoading: false
      }
    })
  }

}
export function* GET_BANNERS() {
  yield put({
    type: 'home/SET_STATE',
    payload: {
      bannerLoading: true
    }
  })
  try {
    const res = yield call(GET_BANNER);
    yield put({
      type: 'home/SET_STATE',
      payload: {
        bannerData: res.data,
        bannerPath: res.path
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'home/SET_STATE',
      payload: {
        bannerLoading: false
      }
    })
  }
}

export function* GET_ARTICLE() {
  yield put({
    type: 'home/SET_STATE',
    payload: {
      articleLoading: true
    }
  })
  try {
    const res = yield call(GET_ARTC);
    yield put({
      type: 'home/SET_STATE',
      payload: {
        articleData: res.data,
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'home/SET_STATE',
      payload: {
        articleLoading: false
      }
    })
  }
}

export function* GET_TESTIMONIAL_ALL() {
  yield put({
    type: 'home/SET_STATE',
    payload: {
      testimoniloading: true
    }
  })
  try {
    const res = yield call(GET_TESTIMONIAL);
    yield put({
      type: 'home/SET_STATE',
      payload: {
        testimoniData: res,
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'home/SET_STATE',
      payload: {
        testimoniloading: false
      }
    })
  }
}


function listSubmenu(ctg, prd)
{ 
    
  const menu = [];
  ctg.map(x =>{

      const submenu = [];

        if(x.category.length > 0)
        {
          x.category.map(val =>{

              const product = [];
              const submenuproduct = prd.filter((xprod) => xprod.CategoryId == val.CategoryId)
              if(submenuproduct.length > 0)
              {
                submenuproduct.map(value =>{ 

                    product.push({ 
                      ProductId:value.ProductId,
                      ProductName:value.ProductName,
                      CategoryId:value.CategoryId,
                      productSlug:value.Slug  
                    })  
                })  
              } 

              submenu.push({ 
                  CategoryId:val.CategoryId,
                  Category:val.Category,
                  ParentId:val.ParentId,
                  Image:val.Image,
                  subSlug:val.Slug,
                  IsActive:val.IsActive,
                  productMenu:product
             

              }) 
          })
        }
            menu.push({

              CategoryId:x.CategoryId,
              Category:x.Category,
              ParentId:x.ParentId,
              Image:x.Image,
              Slug:x.Slug,
              IsActive:x.IsActive,
              subMenu:submenu,
              UrutanWebBanner:x.UrutanWebBanner,
              Urutan:x.Urutan 
              
            })
        
  }) 
    return menu;
  
 
}

export function* GET_CATEGORIES() {
  yield put({
    type: 'home/SET_STATE',
    payload: {
      categoryLoading: true
    }
  })
  try {
    const ctg = yield call(GET_CTG);  
    const prd = yield call(GET_PRD);  
    yield put({
      type: 'home/SET_STATE',
      payload: {
        categoryData: listSubmenu(ctg.data, prd.data)
        
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'home/SET_STATE',
      payload: {
        categoryLoading: false
      }
    })
  }
}

export function* GET_PRODUCT() {
  yield put({
    type: 'home/SET_STATE',
    payload: {
      productLoading: true
    }
  })
  try {
    const res = yield call(GET_PRD);
    yield put({
      type: 'home/SET_STATE',
      payload: {
        productData: res.data,
      }
    })
    } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'home/SET_STATE',
      payload: {
        productLoading: false
      }
    })
  }
}

export function* GET_MARQUEE_INFO() {
  yield put({
    type: 'home/SET_STATE',
    payload: {
      marqueeLoading: true
    }
  })
  try {
    const res = yield call(GET_MARQUEE);
    yield put({
      type: 'home/SET_STATE',
      payload: {
        marquee: res,
      }
    })
    } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'home/SET_STATE',
      payload: {
        marqueeLoading: false
      }
    })
  }
}


export function* HANDLE_STATE({ field, value }) {
  yield put({
    type: 'home/SET_STATE',
    payload: {
      [field]: value
    }
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.HANDLE_STATE, HANDLE_STATE),
    takeEvery(actions.BANNER, GET_BANNERS),
    takeEvery(actions.CATEGORY, GET_CATEGORIES),
    takeEvery(actions.PRODUCT, GET_PRODUCT),
    takeEvery(actions.ARTICLE, GET_ARTICLE),
    takeEvery(actions.CATALOG, GET_CATALOG_LIST),
    takeEvery(actions.TESTIMONIAL, GET_TESTIMONIAL_ALL),
    // takeEvery(actions.GET_MARQUEETEXT, GET_MARQUEE_INFO),
    GET_MARQUEE_INFO(),
    GET_CATEGORIES()
  ])
}
