import React, { useEffect , useState } from 'react';
import { Helmet } from 'react-helmet';

import Variables from 'utils/variables';
import HomeSliderComponent from './slider';
import BannerStatic from './staticbanner';
import HomeTestimonialComponent from './testimonial';
import HomeIntroComponent from './intro';
import HomeCategoriesComponent from './category';
import HomeProductsComponent from './product';
import HomeVideosComponent from './video';
import HomeArticleComponent from './article';
import HomeBannerCategory from './bannercategory';
import { useDispatch } from 'react-redux'; 


const HomePage = () => {
 
  const dispatch = useDispatch()
    
  useEffect(() => {
    dispatch({
      type: 'home/BANNER',
    })
    dispatch({
      type: 'home/CATEGORY',
    })
  }, []) 

  
 
 
  return (
    <>
      <Helmet>
        <title>Dream</title>
      </Helmet>
     
      <BannerStatic  />
      {/* <HomeSliderComponent />  */}
      <HomeBannerCategory /> 
      <HomeProductsComponent />  
      <HomeTestimonialComponent /> 
      {/* <HomeCategoriesComponent /> */}
      <HomeArticleComponent />
      
        {/* <HomeVideosComponent />   */}
    </>
  );
}

export default HomePage;