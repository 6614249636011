import React from 'react'
import { Col, Row } from 'antd'

export const Container = ({ children, justify = 'center', position = 'relative' }) => {
  return (
    <Row gutter={0} justify={justify} className={`bolde-antd-container`}>
      <Col xs={22} style={{ position }} sm={22} md={22} lg={22}>
        {children}
      </Col>
    </Row>
  );
}