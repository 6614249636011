const actions = {
    SET_STATE: 'chat/SET_STATE',
    HANDLE_STATE: 'chat/HANDLE_STATE', 
    GET_MESSAGE: 'chat/GET_MESSAGE', 
    GET_MESSAGE_NOAUTH: 'chat/GET_MESSAGE_NOAUTH', 
    POST_NEW_MESSAGE: 'chat/POST_NEW_MESSAGE',
    POST_NEW_MESSAGE_WOHEADER: 'chat/POST_NEW_MESSAGE_WOHEADER',
    TOKEN_NOAUTH: 'chat/TOKEN_NOAUTH',
    ADD_TOKENAUTH: 'chat/ADD_TOKENAUTH',
  }
  
  export default actions
  