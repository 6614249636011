const actions = {
  SET_STATE: 'cart/SET_STATE',
  HANDLE_STATE: 'cart/HANDLE_STATE',
  GET: 'cart/GET',
  ADD: 'cart/ADD',
  UPDATE: 'cart/UPDATE',
  DELETE: 'cart/DELETE',
  CHECKOUT: 'cart/CHECKOUT',
  CITY: 'cart/GET_CITY',
  SUBDISTRICT: 'cart/GET_SUBDISTRICT',
  SHIPMENT: 'cart/GET_SHIPMENT',
  ORDER: 'cart/ORDER',
  GET_PRODUCT: 'cart/PRODUCT',
  POST_NOTE_ORDER: 'cart/POST_NOTE_ORDER',
  GET_VOUCHER: 'cart/GET_VOUCHER',
  PAYMENTMETHOD: 'cart/PAYMENTMETHOD',
}

export default actions
