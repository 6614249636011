import React, { useEffect, useState } from 'react';
import Variables from 'utils/variables';   
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';

import { Col, Row, Input,  message, Card, Spin} from 'antd'; 
import { currencyFormat } from 'utils/helpers';  
import {LoadingOutlined } from '@ant-design/icons'; 

const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />; 

export const CartProduct = ({data}) =>
{
    const dispatch = useDispatch();  
    const cartItems = useSelector(({ cart }) => cart.cartItems) 
    const wishlist = useSelector(({account})=> account.wishlist) 
    const loading  = useSelector(({account})=> account.loading)
    const [qty, setQty] = useState(data.cartItemQty)
    const [favorit, setFavorit] = useState(false)

    useEffect(() => {
        setQty(data.cartItemQty)
    }, [data]);
   
  useEffect(() => {
    const cekwishlist = wishlist.filter((x) => x.VariantId === data.cartItemVariantID)
    if(cekwishlist.length > 0){
      setFavorit(true) 
    } else{
      setFavorit(false) 
    }
  

  }, [wishlist, data])

    const tambah = () => {
        const cartItem = cartItems.filter(x => x.cartItemID === data.cartItemID)
        const angka = qty + 1;


        if (angka > parseInt(cartItem[0].cartItemStock)) {
          setQty(qty)
            message.error(`Stok tidak mencukupi. Stok tersisa ${parseInt(cartItem[0].cartItemStock)}`)
          } else {

            setQty(angka)
            dispatch({
              type: 'cart/UPDATE',
              payload: {
                cartItemID: data.cartItemID,
                VariantId: data.cartItemVariantID,
                Value: angka
              }
            })
          } 
      }
      const kurang = () => {   
        if (qty < 2) { 


          } else {

            const angka = qty - 1;
            setQty(angka)
            dispatch({
              type: 'cart/UPDATE',
              payload: {
                cartItemID: data.cartItemID,
                VariantId: data.cartItemVariantID,
                Value: angka
              }
            })
          } 
      }

const  deleteProduct = (param) =>{ 
  dispatch({
    type: 'cart/DELETE',
    payload: {
      CartDetailId: param,
    }
  })
}

const addWishlist = (param) => {
    dispatch({
      type:'account/ADD_WISHLIST',
      payload:{
        VariantId:param
      }

      
    })

}
const removeWishlist = (param) => {
    dispatch({
      type:'account/DELETE_WISHLIST',
      payload:{
        VariantId:param
      }

      
    })

}
      return( 
        
            <Card className="card-data-product mb-3"  bordered={false}     style={{ width: '100%' }}> 
              <Spin indicator={spinIcon} spinning={loading}>
              <Row className="mb-3 ">
                    <Col    xs={{ span: 24 }} sm={{ span: 24 }} md={{span:6}}> 
                    {
                      data.cartItemIsDiscount &&
                      <div class="discount-label-cart"> <span>{data.cartItemDiscountPercent} %</span> </div> 
                    }
                            <img src={Variables.BASE_IMG_PRODUCT + data.cartItemImage } width={100} className="img-fluid" alt="Dream" /> 
                    </Col>
                    <Col    xs={{ span: 24 }} sm={{ span: 24 }} md={{span:18}}>
                        <div className="pl-2 "> 
                            <p className="color-standar mt-2" style={{fontSize:'19px'}}>{data.cartItemName}</p>   
                            {
                               data.cartItemIsDiscount &&
                              <> 
                                <span className="bolde-font__sm   color-standar bolde-font__coret mr-2"  style={{fontFamily:'Helvetica', fontWeight:'600'}}>
                                  Rp. {currencyFormat(data.cartItemPrice)}
                                </span>
                                <span className="bolde-font__md     disc-font "  style={{fontFamily:'Helvetica', fontWeight:'600'}}>
                                   Rp. {currencyFormat(data.cartItemDiscountEndPrice)}
                                </span>
                              </>
                              }
                              {
                                !data.cartItemIsDiscount &&
                                <span className="bolde-font__sm   color-standar   mr-2"  style={{fontFamily:'Helvetica', fontWeight:'600'}}>
                                 Rp. {currencyFormat(data.cartItemPrice)}
                              </span>
                              }

                        </div>   
                        <div className="pr-3 float-left mt-2">    
                          <div class="d-flex bd-highlight">
                            <div class="p-2 flex-grow-1 bd-highlight"> 
                             <div className="number-input">
                                  <button  onClick={kurang} ></button>
                                  <Input className="quantity number_input_cart"   max="10"  min={1} value={qty}   name="quantity"   type="number"/>
                                  <button onClick={tambah}  className="plus"></button>
                              </div>
                            </div>
                            <div class="p-2 bd-highlight">
                            {
                                favorit &&
                                <img onClick={()=>removeWishlist(data.cartItemVariantID)} className="py-1" src={Variables.BASE_IMG_LOCAL + '/svg/Favorit02.svg'} width="25" alt="Wishlist" /> 
                              }
                              {
                                !favorit &&
                                <img onClick={()=>addWishlist(data.cartItemVariantID)}  className="py-1" src={Variables.BASE_IMG_LOCAL + '/Ikon Favorit 1.png'} width="25" alt="Wishlist" /> 
                              } 
                              
                              </div>
                            <div class="p-2 bd-highlight"><DeleteOutlined   className="py-1" onClick={()=>deleteProduct(data.cartItemID)}  style={{fontSize:'22px',}}/></div>
                          </div> 
                        </div>  
                       </Col>
                    {/* <Col    xs={{ span: 24 }} sm={{ span: 24 }} md={{span:6}}>
                        <div className="pr-3 float-right mb-3">  
                     

                        {
                          favorit &&
                          <img onClick={()=>removeWishlist(data.cartItemVariantID)} className="mr-2" src={Variables.BASE_IMG_LOCAL + '/svg/Favorit02.svg'} width="25" alt="Wishlist" />

                        }
                        {
                          !favorit &&
                          <img onClick={()=>addWishlist(data.cartItemVariantID)} className="mr-2" src={Variables.BASE_IMG_LOCAL + '/Ikon Favorit 1.png'} width="25" alt="Wishlist" />

                        }
                       
                        <DeleteOutlined  onClick={()=>deleteProduct(data.cartItemID)} className="mr-2" style={{fontSize:'22px',}}/>
                        </div>  
                        <div className="pr-3 float-right">  
                            <div className="number-input">
                                <button  onClick={kurang} ></button>
                                <Input className="quantity number_input_cart" min="1" max="10"  min={1} value={qty}   name="quantity"   type="number"/>
                                <button onClick={tambah}  className="plus"></button>
                            </div>
                        </div>  
                    </Col> */}
                </Row>
                 
              
              </Spin> 
            </Card>  
      )


}