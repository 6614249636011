// const ENDPOINTS = {
//   EP_BANNER: 'api/banner',
//   EP_BANNER__CTG: 'api/bannercategory',

//   EP_CATEGORY: 'api/category',

//   EP_PRODUCT: 'api/product',

//   EP_VARIANTS: 'api/variant',
//   EP_VARIANT__DETAIL: 'api/variantdetail',
//   EP_VARIANT__TYPE: 'api/varianttype',
//   EP_VARIANT__VALUE: 'api/value',

//   EP_ARTICLE: 'api/article',
// }



const ENDPOINTS = {
  EP_SIGNUP: 'api/signup',
  EP_LOGIN: 'api/login',

  EP_SUBCRIPTION: 'api/subcription',

  EP_BANNER: 'api/banner',
  EP_BANNER__CTG: 'api/bannercategory',

  EP_CATEGORY: 'api/category',

  EP_PRODUCT: 'api/product',

  EP_CART: 'api/cart',
  EP_CART__QTY: 'api/cart/qty',
  EP_CHECKOUT: 'api/checkout',

  EP_VARIANTS: 'api/variant',
  EP_VARIANT__DETAIL: 'api/variantdetail',
  EP_VARIANT__TYPE: 'api/varianttype',
  EP_VARIANT__VALUE: 'api/value',
  EP_ARTICLE: 'api/article',
  EP_PROVINCE: 'api/province',
  EP_CITY: 'api/city',
  EP_SUBDISTRICT: 'api/subdistrict',
  EP_SHIP: 'api/shippingcharge',
  EP_PAYMENT: 'api/payment',
  EP_ORDER: 'api/order',
  EP_PROFILE: 'api/profile',
  EP_PROFILE__ADDRESS: 'api/profile/address',
  EP_REVIEW: 'api/review',
  EP_RESELLER: 'api/reseller/members',
  EP_MEMBER_ADDRESS: 'api/reseller/members/address',
  EP_MEMBER_POINT: 'api/point',
  EP_MARQUEE: 'api/marquee',
  EP_WISHLIST: 'api/wishlist',
  EP_CATALOG: 'api/catalog',
  EP_SKIN_ANALIS: 'api/skinanalist',
  EP_ORDER_NOTE: 'api/cart/note',
  EP_ORDER_NOTIF: 'api/profile/notif',
  EP_CONTACT_US: 'api/contactus',
  EP_VOUCHER: 'api/voucher',
  EP_CANCEL: 'api/order/cancel',
  EP_TRACE: 'api/order/trace',
  EP_CHAT: 'api/customercare',
  EP_CERITA: 'api/ceritadream',
  EP_METHODPAYMENT: 'api/payment/method',
  EP_JOURNEY: 'api/journey',
  EP_JENIS_KULIT: 'api/jeniskulit',
  EP_KEBIASAAN: 'api/kebiasaanharian',
  EP_PERAWATANKULIT: 'api/perawatankulit',
  EP_BA: 'api/badoctoream',
  EP_BA_REV: 'api/badoctoream2',



}
export default ENDPOINTS