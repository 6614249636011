import { all, takeEvery, put, call } from 'redux-saga/effects'
// import store from 'store'
// import qs from 'qs'
// import moment from 'moment'
// import { history, store as reduxStore } from 'index'

import {store} from 'index'; 
import actions from './actions'
import { GET_QUESTION, POST_QUESTION} from 'services/axios/skinanalis' 





export function* GET_QUESTIONSKIN()
{ 
    yield put({
        type: 'skinanalis/SET_STATE',
        payload: {
            skinanalisloading: true
        }
      })
      try {
        const question = yield call(GET_QUESTION) 
        yield put({
          type: 'skinanalis/SET_STATE',
          payload: {
            skinQuestion: question.data, 
          }
        })
      } catch (error) {
        console.log(error);
      } finally {
        yield put({
          type: 'skinanalis/SET_STATE',
          payload: {
            skinanalisloading: false
          }
        })
      } 
} 

export function* POST_QA({payload}){
  yield put({ 
    type: 'skinanalis/SET_STATE',
    payload: {
      skinanalisloading: true
    }
  })
  try {
    const res = yield call(POST_QUESTION, payload);
    if (res.status) { 
          // msg.success('Item successfully added to wishlist')
          // yield GET_LIST_WISHLIST() 
          yield put({
            type: 'skinanalis/SET_STATE',
            payload: {
              resultSkin: res.data[0], 
            }
          }) 
          store.dispatch({
            type: 'skinanalis/HANDLE_STATE',
            field: 'pageSetting',
            value:  'skinresult'
        }) 
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'skinanalis/SET_STATE',
      payload: {
        skinanalisloading: false
      }
    })
  }

} 





export function* HANDLE_STATE({ field, value }) {
    yield put({
      type: 'skinanalis/SET_STATE',
      payload: {
        [field]: value
      }
    })
  }

export default function* rootSaga() {
    yield all([
      takeEvery(actions.HANDLE_STATE, HANDLE_STATE), 
      takeEvery(actions.GET_QUESTIONSKIN, GET_QUESTIONSKIN),
      takeEvery(actions.POST_QA, POST_QA),
    
    ])
  }