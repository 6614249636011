import { all, takeEvery, put, call, take } from 'redux-saga/effects'
import actions from './actions'
import { GET_ARTC } from 'services/axios/article'
import { GET_BANNER } from 'services/axios/banner'

export function* GET_BANNERS() {
  yield put({
    type: 'article/SET_STATE',
    payload: {
      bannerLoading: true
    }
  })
  try {
    const res = yield call(GET_BANNER);
    yield put({
      type: 'article/SET_STATE',
      payload: {
        bannerData: res.data,
        bannerPath: res.path
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'article/SET_STATE',
      payload: {
        bannerLoading: false
      }
    })
  }
}

export function* GET_ARTICLE() {
  yield put({
    type: 'article/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(GET_ARTC);
    yield put({
      type: 'article/SET_STATE',
      payload: {
        articleData: res.data,
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'article/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* HANDLE_STATE({ field, value }) {
  yield put({
    type: 'article/SET_STATE',
    payload: {
      [field]: value
    }
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.HANDLE_STATE, HANDLE_STATE),
    takeEvery(actions.DATA, GET_ARTICLE),
    takeEvery(actions.BANNER, GET_BANNERS),
  ])
}
