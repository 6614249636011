import React, {useState} from 'react';
import { Collapse, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Sidebar from 'react-sidebar';
import { CloseOutlined } from '@ant-design/icons';
import { Container } from 'components/grid';
import { useTranslation } from 'react-i18next';  
import Variables from 'utils/variables'; 
import Skeleton from 'react-loading-skeleton'; 
const { Panel } = Collapse

const SidebarComponent = ({ children }) => {
  
  const history = useHistory()
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector(({ settings }) => settings.isSidebarOpen);
  // const categoryData = useSelector(({ settings }) => settings.categoryData) 
  const categoryData = useSelector(({home}) => home.categoryData)
  const searchLoading = useSelector(({ product }) => product.searchLoading)
  const searchData = useSelector(({ product }) => product.searchData)
  
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn)
  const [visible, setVisible] = useState(false);
  const [keyword, setKeyword] = useState('') 
  const { t } = useTranslation()

  const handleSidebar = () => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isSidebarOpen',
        value: !isSidebarOpen,
      }
    })
 
  }

const handleSearchBar = () => {

  dispatch({ 
    type: 'settings/CHANGE_SETTING',
    payload: {
      setting: 'isSidebarOpen',
      value: !isSidebarOpen,
    }
  })

  setVisible(true)
}
  
  const handleClose = () => {
    setVisible(false)
    setKeyword('')
    dispatch({
      type: 'product/HANDLE_STATE',
      field: 'searchData',
      value: [],
    });
  }
 
 
 
  const handleLogout = () => {
    dispatch({
      type: 'auth/LOGOUT'
    })
  }
  const handleChange = (e) => {
    const { value } = e.target
    setKeyword(value)
    if (value !== '') {
      dispatch({
        type: 'product/SEARCH',
        payload: {
          keyword: value,
        }
      });
    } else {
      dispatch({
        type: 'product/HANDLE_STATE',
        field: 'searchData',
        value: [],
      });
    }
  }
 
  function renderTitle() {
    return (
      <>
        <input
          placeholder="Cari nama produk..."
          onChange={handleChange}
          value={keyword}
        />
      </>
    )
  }
  

const homeComingSoon = () => {
  handleSidebar();
  history.push('/coming-soon')
}

  const contentSidebar = <>
    <div className="bolde-sidebar ">
      <Container>
        <div className="bolde-sidebar--top">
          <Link to="#!" className="close-sidebar" onClick={handleSidebar}>
            <CloseOutlined />
          </Link>
        </div>
        <div className="bolde-sidebar--menu py-3">
        {
            isLoggedIn &&
            <Collapse accordion expandIconPosition="right" className="bolde-antd-collapse"> 
              <Panel  header={<span className="bolde-font-custom_caviar_bold"> <img src={Variables.BASE_IMG_LOCAL + '/account.png'}  style={{width:'20px'}}  alt="Dream" /> Account</span>} key="account"> 
              <div className="my-3"  key="account">
                <Link  className="bolde-font-custom_caviar_bold" onClick={handleSidebar} to="/account">{t('Account_Menu1')}</Link>
              </div>   
              <div className="my-3"  key="account">
                <Link  className="bolde-font-custom_caviar_bold" onClick={handleSidebar} to="/order">{t('Account_Menu2')}</Link>
              </div>   
              <div className="my-3"  key="account">
                <Link  className="bolde-font-custom_caviar_bold" onClick={handleSidebar} to="/list-address">{t('Account_Menu3')}</Link>
              </div>   
              <div className="my-3"  key="account">
                <Link  className="bolde-font-custom_caviar_bold" onClick={handleSidebar} to="/review">{t('Account_Menu4')}</Link>
              </div>   
              <div className="my-3"  key="account">
                <Link  className="bolde-font-custom_caviar_bold" onClick={handleSidebar} to="/wishlist">{t('Account_Menu5')}</Link>
              </div>   
              <div className="my-3"  key="account">
                <Link  className="bolde-font-custom_caviar_bold" onClick={handleSidebar} to="/account">{t('Account_Menu6')}</Link>
              </div>   
              </Panel>
            </Collapse>
          }
          <ul className="menu-sidebar-circle">
         
            {/* <li  >
              <Link className="bolde-font-custom_caviar_bold" onClick={handleSidebar} to="/"> <img src={Variables.BASE_IMG_LOCAL + '/account.png'}  style={{width:'20px'}}  alt="Dream" /> Account</Link>
            </li> */}
            <li  >
              <Link className="bolde-font-custom_caviar_bold" onClick={handleSearchBar}  to="/"> <img src={Variables.BASE_IMG_LOCAL + '/search.png'}  style={{width:'20px'}}  alt="Dream" /> Search</Link>
            </li>
          </ul>
          <Collapse accordion expandIconPosition="right" className="bolde-antd-collapse"> 
               {
                 categoryData.map((x, y) => 
                <>
                  {
                    !x.IsActive &&
                    <Panel   header={<span onClick={()=>homeComingSoon()}  className="bolde-font-custom_caviar_bold">{x.Category}</span>} key={x.Category + y}> 
                    </Panel>
                  }

                  { 
                    x.IsActive && 
                  <Panel  header={<span className="bolde-font-custom_caviar_bold">{x.Category}</span>} key={x.Category + y}> 
                    {
                          x.subMenu.length > 0 &&
                              x.subMenu.map((val, key)=>   
                              <> 
                                    <div className="my-3"  key={'sub'+key}>
                                      <Link  className="bolde-font-custom_caviar_bold" onClick={handleSidebar}   to={`/category/${val.subSlug}`}>{val.Category}</Link>
                                    </div>  
                              </> 
                              )  
                        }  

                  </Panel>
                      }
                    </>
                 )


               } 
          </Collapse>
          <ul className="menu-sidebar-circle">
            <li>
               <Link  className="bolde-font-custom_caviar_bold"  onClick={handleSidebar}  to="/dreamers-say">{t('Menu1')}</Link>
            </li>
            <li>
              <Link  className="bolde-font-custom_caviar_bold"  onClick={handleSidebar} to="/magazine" >{t('Menu2')}</Link>
            </li>
            <li>
              <Link  className="bolde-font-custom_caviar_bold"  onClick={handleSidebar} to="/catalog" >{t('Menu4')}</Link>
            </li>
            <li>
              <Link  className="bolde-font-custom_caviar_bold"  onClick={handleSidebar} to="/coming-soon" >{t('Menu3')} </Link>
            </li>
            {
            !isLoggedIn &&
             <li>
              <Link  className="bolde-font-custom_caviar_bold"  onClick={handleSidebar} to="/login">{t('Sidebar_mobile_login')}</Link>
            </li>  
            }
            {
            isLoggedIn &&
             <li>
              <Link  className="bolde-font-custom_caviar_bold"   onClick={handleLogout}  to="/login">Logout</Link>
            </li>  
            }
          </ul>
        </div>
        <div className="bolde-sidebar--bottom py-3">
        </div>
      </Container>
    </div>
  </>


  return (
    <>
      <Sidebar
        sidebar={contentSidebar}
        open={isSidebarOpen}
        onSetOpen={handleSidebar}
      >
        {children}
      </Sidebar>

      <Modal
        title={renderTitle()}
        visible={visible}
        onOk={handleClose}
        onCancel={handleClose}
        width={1000}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
        footer={null}
        className="bolde-search"
      >
        <div className="bolde-search--wrapper">
          {
            searchLoading &&
            <div className="bolde-search--items">
              <div className="mb-1">
                <Skeleton height={30} width={300} />
              </div>
              <Skeleton height={20} width={150} />
            </div>
          }
          {
            !searchLoading &&
            searchData.map((val, key) =>
              <div key={key} className="bolde-search--items">
                <a onClick={handleClose} href={`/product-detail/${val.productSlug}`}>
                  {val.productName}
                </a>
                <p>{val.productCategory}</p>
              </div>
            )
          }
          {
            !searchLoading && searchData.length < 1 &&
            <div className="bolde-search--items bolde-search--items__no-border">
              <p>Coba 'Facial Wash'</p>
            </div>
          }
        </div>
      </Modal>
    </>
  );
}

export default SidebarComponent;