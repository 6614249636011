const actions = {
  SET_STATE: 'contact/SET_STATE',
  HANDLE_STATE: 'contact/HANDLE_STATE',
  SEND_MESSAGE: 'contact/SEND_MESSAGE',
  POST_CONTACTUS: 'contact/POST_CONTACTUS',
  POST_FORMULIR: 'contact/POST_FORMULIR',
  GET_KIND_OF_SKIN: 'contact/GET_KIND_OF_SKIN',
  GET_DAYACTIVITY: 'contact/GET_DAYACTIVITY',
  GET_SKINCARE: 'contact/GET_SKINCARE',
}

export default actions
