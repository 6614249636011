import { message as msg } from 'antd';
import { store } from 'index';
import { all, takeEvery, put, call, take } from 'redux-saga/effects'
import { SIGNIN, SIGNIN_CONFIRM__OTP, SIGNIN__OTP, SIGNUP, SIGNUP_CONFIRM__OTP, SIGNUP__OTP } from 'services/axios/auth'
import { CART__ADD } from 'services/axios/cart';
import actions from './actions'


export function* GET_REGISTER({ payload }) {

  // console.log(payload, 'optnj')
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(SIGNUP__OTP, payload); 
    if (res.status) {
      msg.success(res.message) 
      // localStorage.setItem('token', res.jwt) 
      localStorage.setItem('IsReseller', res.data.IsReseller)  
      localStorage.setItem('user', JSON.stringify(res.data)) 
      yield put({
        type: 'auth/SET_STATE',
        payload: {
          otpModal: true,
          account: payload.Account, 
          otpStatus: 'signup'
        }
      })
    }

  } catch (error) {
    const { data: { message } } = error.response
    console.log(error.response);
    msg.error(message)
  } finally {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* GET_RESEND_OTP({ payload }) {
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(SIGNUP__OTP, payload);
    if (res.status) {
      msg.success(res.message)
      yield put({
        type: 'auth/SET_STATE',
        payload: {
          otpModal: true
        }
      })
    }

  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* CONFIRM_OTP_REGISTER({ payload }) {
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(SIGNUP_CONFIRM__OTP, payload); 
    if (res.status) {
     localStorage.setItem('token', res.jwt) 
      localStorage.setItem('IsReseller', res.data.IsReseller)  
      localStorage.setItem('user', JSON.stringify(res.data)) 
      const setLogin = () => {
        store.dispatch({
          type: 'auth/SET_STATE',
          payload: {
            token: res.jwt,
            user: {
              email: res.data.Email ?? '',
              name: res.data.Name ? res.data.Name : '',
              phone: res.data.PhoneNumber ? res.data.PhoneNumber : '' ,
              queue: res.data.Queue ? res.data.Queue : '',
              userCode: res.data.UserCode ?  res.data.UserCode :  '',
              isReseller: res.data.IsReseller ?  res.data.IsReseller : '',
            }, 
            isLoggedIn: res.jwt ? true : false
          }
        })
        msg.success(res.message)
      }

      const toAccount = () => window.location.href = '/account'

      yield setLogin() 
      if( res.jwt !== undefined){
        yield toAccount()
      }
     
    }

  } catch (error) {
    const { data: { message } } = error.response
    msg.error(message)
  } finally {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* GET_LOGIN({ payload }) {
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(SIGNIN__OTP, payload);
    if (res.status) {
      yield put({
        type: 'auth/SET_STATE',
        payload: {
          otpModal: true,
          account: payload.Account,
          otpStatus: 'signin'
        }
      })

    }
  } catch (error) {
    const { data: { message } } = error.response
    msg.error(message)
  } finally {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

function redirectTo(slug = null) {
  if (slug) {
    return window.location.href = `/product-detail/${slug}`
  } else {
    return window.location.href = `/account`
  }
}

function setLogin(params) { 
  store.dispatch({
    type: 'auth/SET_STATE',
    payload: {
      token: params.jwt,  
      user: { 
        // email: params.data.Email ??  '',
        name: params.data.Name ? params.data.Name : '',
        phone: params.data.PhoneNumber ? params.data.PhoneNumber : '' ,
        queue: params.data.Queue ? params.data.Queue : '',
        userCode: params.data.UserCode ?  params.data.UserCode : '',
        // isReseller: params.data.IsReseller ?   params.data.IsReseller  : '',
      }, 
      isLoggedIn: true
    }
  })
}

function* addToCart(params) { 
  const res = yield call(CART__ADD, params);
  const lang = localStorage.getItem('i18nextLng')
  console.log(res, 'dssas');
  if (res.status & res.Cart > 0) {
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        cartTotalItems: params.currentCartTotalItems + 1
      }
    })
    if(lang ==='id'){ 
      msg.success('Produk berhasil ditambahkan ke kranjang')
    }else{ 
      msg.success('Item successfully added to cart')
    }
  }
  return res.status
}

export function* CONFIRM_OTP_LOGIN({ payload }) {
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(SIGNIN_CONFIRM__OTP, payload);

 
    localStorage.setItem('token', res.jwt)
    localStorage.setItem('IsReseller', res.data.IsReseller)  
    localStorage.setItem('user', JSON.stringify(res.data))
    msg.success(res.message)
    yield call(setLogin, res) 
    const addCart = yield call(addToCart, payload)
    if (addCart) { 
      if(payload.link === ''){ 
        yield call(redirectTo, payload.dtlSlug)  
      }else{
        return window.location.href = `/skin-analys` 
      }
    }   
  
  } catch (error) {
    const { data: { message } } = error.response
    msg.error(message)
  } finally {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* GET_LOGOUT() {
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('IsReseller')
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        token: '',
        user: [],
        exp: '',
        iat: '',
        iss: '', 
        IsReseller:'',
        isLoggedIn: false,
      }
    })

    msg.success('Logout Success')
    const toLogin = () => window.location.href = '/login'
    yield toLogin()
  } catch (error) {
    console.log('message ', error);
  } finally {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* AUTH() {
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : null
  const reseller = localStorage.getItem('IsReseller') ? localStorage.getItem('IsReseller') : null
  const setToken = () => {
    if (token) {
      store.dispatch({
        type: 'auth/SET_STATE',
        payload: {
          token,
          isLoggedIn: token ? true : false,
          IsReseller: reseller ? true : false,
        }
      })
    }
  }
  yield setToken();
}

export function* HANDLE_STATE({ field, value }) {
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      [field]: value
    }
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.HANDLE_STATE, HANDLE_STATE),
    takeEvery(actions.REGISTER, GET_REGISTER),
    takeEvery(actions.CONFIRM_OTP_REGISTER, CONFIRM_OTP_REGISTER),
    takeEvery(actions.LOGIN, GET_LOGIN),
    takeEvery(actions.CONFIRM_OTP_LOGIN, CONFIRM_OTP_LOGIN),
    takeEvery(actions.LOGOUT, GET_LOGOUT),
    AUTH(),
  ])
}
