import { all, takeEvery, put, call, take } from 'redux-saga/effects'
import actions from './actions'
import { POST_MESSAGE, POST_CONTACT_US, POST_JOURNEY, GET_JENISKULIT, GET_KEBIASAAN, GET_PERAWATANKULIT} from 'services/axios/contact'
import { message } from 'antd';

import {store} from 'index';


export function* SEND_MESSAGE({ payload }) {

  const lang = localStorage.getItem('i18nextLng')
  yield put({
    type: 'contact/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(POST_MESSAGE, payload);
    if (res.status) {
      if(lang === 'id') 
      {
        message.success("Terima kasih telah mengikuti kami.")
      }else{ 
        message.success("Thank you for following us.")
      }   
    

      localStorage.setItem('showModalSubs', false)
      store.dispatch({
        type: 'settings/HANDLE_STATE',
        field: 'showModalSubs',
        value: false
      })
    }
  } catch (error) {
    
    message.error("Terjadi kesalahan. Silahkan coba beberapa saat lagi.")
  } finally {
    yield put({
      type: 'contact/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* POST_CONTACTUS({ payload }) {
  
  const lang = localStorage.getItem('i18nextLng')
  yield put({
    type: 'contact/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(POST_CONTACT_US, payload);
    if (res === "ok") { 
      if(lang === 'id') 
      {
        message.success("Terima kasih telah menghubungi kami, silahkan menuggu respon dari kami dalam waktu 1-3 hari") 

      }else{ 
        message.success("Thank you for contacting us, please wait for a response from us within 1-3 days") 
      }   
    
    }
  } catch (error) {
    
    message.error("Terjadi kesalahan. Silahkan coba beberapa saat lagi.")
  } finally {
    yield put({
      type: 'contact/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}



export function* GET_KIND_OF_SKIN() {
  yield put({
    type: 'contact/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const jns = yield call(GET_JENISKULIT); 
    yield put({
      type: 'contact/SET_STATE',
      payload: {
        jenisKulit: jns,
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'contact/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}




export function* GET_DAYACTIVITY() {
  yield put({
    type: 'contact/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const jns = yield call(GET_KEBIASAAN); 
    yield put({
      type: 'contact/SET_STATE',
      payload: {
        kebiasaanHarian: jns,
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'contact/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* GET_SKINCARE() {
  yield put({
    type: 'contact/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const jns = yield call(GET_PERAWATANKULIT); 
    yield put({
      type: 'contact/SET_STATE',
      payload: {
        perawatanKulit: jns,
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'contact/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}



export function* POST_FORMULIR({ payload }) {
  
  const lang = localStorage.getItem('i18nextLng')
  yield put({
    type: 'contact/SET_STATE',
    payload: {
      loading: true
    }
  })
 
  try {
    const res = yield call(POST_JOURNEY, payload); 
      console.log(res, 'formulir');
    // if (res === "ok") { 
    //   if(lang === 'id') 
    //   {
    //     message.success("Terima kasih telah menghubungi kami, silahkan menuggu respon dari kami dalam waktu 1-3 hari") 

    //   }else{ 
    //     message.success("Thank you for contacting us, please wait for a response from us within 1-3 days") 
    //   }   
    
    // }
  } catch (error) { 
    message.error("Terjadi kesalahan. Silahkan coba beberapa saat lagi.")
  } finally {
    yield put({
      type: 'contact/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* HANDLE_STATE({ field, value }) {
  yield put({
    type: 'contact/SET_STATE',
    payload: {
      [field]: value
    }
  })
}

 
export default function* rootSaga() {
  yield all([
    takeEvery(actions.HANDLE_STATE, HANDLE_STATE),
    takeEvery(actions.SEND_MESSAGE, SEND_MESSAGE),
    takeEvery(actions.POST_CONTACTUS, POST_CONTACTUS),
    takeEvery(actions.POST_FORMULIR, POST_FORMULIR),
    takeEvery(actions.GET_KIND_OF_SKIN, GET_KIND_OF_SKIN), 
    takeEvery(actions.GET_DAYACTIVITY, GET_DAYACTIVITY), 
    takeEvery(actions.GET_SKINCARE, GET_SKINCARE), 
  ])
}
