import React, { useEffect , useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector, useDispatch } from 'react-redux'; 
import Slider from 'react-slick'; 
import { Col, Row } from 'antd';
import Variables from 'utils/variables'; 
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";  
import { useTranslation } from 'react-i18next';
import { Container } from 'components/grid'; 
 


const Testimonial = () => { 
 
  const isMobileView = useSelector(({ settings }) => settings.isMobileView) 
  const testimoniloading = useSelector(({ home }) => home.testimoniloading) 
  const testimoniData = useSelector(({home}) => home.testimoniData)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: numberSlideTo(testimoniData),
    slidesToScroll: 1,
    autoplay: true, 
    className: "center",
    centerMode: true, 
    centerPadding: "0px",  
    responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        }
      ],
    nav:true,
  };

  function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow";
    const char = props.type === "next" ? <BsChevronRight/>  : <BsChevronLeft/>; 
    return (
      <span className={className} onClick={props.onClick}>
        {char}
      </span>
    );
  }
  
  function customPaging(i) {
    return <span>{i + 1}</span>;
  }
  
  function appendDots(dots) {
    return (
      <div style={{ backgroundColor: "#eee" }}>
        <ul style={{ margin: "3px" }}> {dots} </ul>
      </div>
    );
  }
  
 function numberSlideTo(testimoniData)
 { 
  if(testimoniData.length > 3){
    return 3;
  }else{
    return 1;
  } 
 }

useEffect(() => { 
  dispatch({
    type:'home/TESTIMONIAL'
  })
}, []);

  return (  
<>  
{
  testimoniData && (testimoniData.length > 0) &&
  <div className="bolde-testimonial" >  
  { !isMobileView && 
  <Container>
        <h1 className=" text-center mb-5 bolde-font-normal_bebas_regular color-standar" style={{ fontSize:'40px'}}>
        {t('Home_title_testimonials')}
        </h1> 
      {
      testimoniloading && 
      <>   
            <Row gutter={[40, 80]} justify="space-between">
              <Col xs={{ span: 8}} sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }}> 
                <div className="text-center">
                  <Skeleton  height={150} width={150} className="mr-2 mb-2 mt-2 rounded-circle" /> 
                </div> 
                <div className="text-center"> 
                  <Skeleton  height={20} width={'30%'} className="mr-3 mb-2 mt-2" /> 
                  <Skeleton  height={20} width={'80%'} className="mr-3 mb-2 mt-2" /> 
                  <Skeleton  height={20} width={'100%'} className="mr-3 mb-2 mt-2" /> 
                  <Skeleton  height={20} width={'90%'} className="mr-3 mb-2 mt-2" /> 
                  <Skeleton  height={20} width={'80%'} className="mr-3 mb-2 mt-2" /> 
                  <Skeleton  height={20} width={'70%'} className="mr-3 mb-2 mt-2" /> 
                </div> 
              </Col>
              <Col xs={{ span: 8}} sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }}> 
                <div className="text-center">
                  <Skeleton  height={150} width={150} className="mr-2 mb-2 mt-2 rounded-circle" /> 
                </div> 
                <div className="text-center"> 
                  <Skeleton  height={20} width={'30%'} className="mr-3 mb-2 mt-2" /> 
                  <Skeleton  height={20} width={'80%'} className="mr-3 mb-2 mt-2" /> 
                  <Skeleton  height={20} width={'100%'} className="mr-3 mb-2 mt-2" /> 
                  <Skeleton  height={20} width={'90%'} className="mr-3 mb-2 mt-2" /> 
                  <Skeleton  height={20} width={'80%'} className="mr-3 mb-2 mt-2" /> 
                  <Skeleton  height={20} width={'70%'} className="mr-3 mb-2 mt-2" /> 
                </div> 
              </Col>
              <Col xs={{ span: 8}} sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }}> 
                <div className="text-center">
                  <Skeleton  height={150} width={150} className="mr-2 mb-2 mt-2 rounded-circle" /> 
                </div> 
                <div className="text-center"> 
                  <Skeleton  height={20} width={'30%'} className="mr-3 mb-2 mt-2" /> 
                  <Skeleton  height={20} width={'80%'} className="mr-3 mb-2 mt-2" /> 
                  <Skeleton  height={20} width={'100%'} className="mr-3 mb-2 mt-2" /> 
                  <Skeleton  height={20} width={'90%'} className="mr-3 mb-2 mt-2" /> 
                  <Skeleton  height={20} width={'80%'} className="mr-3 mb-2 mt-2" /> 
                  <Skeleton  height={20} width={'70%'} className="mr-3 mb-2 mt-2" /> 
                </div> 
              </Col> 
            </Row>  
         </>
    }   
    {
      !testimoniloading &&
      <Slider    nextArrow={<Arrow type="next" />}
                prevArrow={<Arrow type="prev" />}
                dots={true}
                customPaging={customPaging}
                appendDots={appendDots} 
                {...settings}   >  
          {
            testimoniData.map((x) =>   
                <div className="bolde-testimonial--item  text-center"> 
                  <div className="mb-4 bolde-testimonial--item-img  text-center">
                      <img src={Variables.BASE_IMG_TESTIMONIAL + x.Image }  className="img-fluid" />
                  </div>
                  <div className=" bolde-testimonial--item-quote  text-center">
                      <img src={Variables.BASE_IMG_LOCAL + '/quote.png'} className="img-fluid" /> 
                    </div>  
                  <div className="mb-3 bolde-font__light text-center"  style={{fontSize:'20px'  }}> 
                     {x.Customer}
                  </div> 
                  <span className="bolde-font__light bolde-font__md text-justify color-standar_hightlight"  style={{lineHeight:'1.4'}}> 
                      <div  style={{textAlign:'center'}} dangerouslySetInnerHTML={{ __html: x.Desc }} /> 
                  </span>
                </div> 
            ) 
          }
     
        
      </Slider> 
    }  
    
      </Container> 

  }
  </div>

}
 
    </>
  );
}

export default Testimonial;