import actions from './actions'

const initialState = {
  loading: true,
  priceLoading: false,
  cartID: null,
  cartItemID: null,
  cartTotalItems: 0,
  cartItems: [],
  cities: [],
  voucher:[],
  voucherOngkir:[], 
  voucherSelected: { 
          VoucherID:'',
          VoucherNama:'',
          VoucherRingkasan:'',
          VoucherKeterangan:'',
          VoucherKuota:'',
          VoucherSingleVoucher:'',
          VoucherMinimalBelanja:0,
          VoucherMaksimalPotongan:0,
          VoucherImage:'', 
          VoucherBatasPerUser:'',
          VoucherPersentase:'',
      },
  voucherOngkirSelected: { 
        VoucherOngkirID:'',
        VoucherOngkirNama:'',
        VoucherOngkirRingkasan:'',
        VoucherOngkirBatasPerUser:'',
        VoucherOngkirKeterangan:'',
        VoucherOngkirKuota:'', 
        VoucherOngkirMinimalBelanja:0,
        VoucherOngkirMaksimalPotongan:0,
        VoucherOngkirImage:'',
        VoucherOngkirPersentase:''
      },
  subdistricts: [],
  shipment: [],
  productDataCart:[],
  methodPaymentList:[],
  cartNote: null,
  cartTotalPrice: 0,
  shipLoading: false,
  showModalVoucher:false,
}
 

 

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
