import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import settings from './settings/reducers'
import home from './home/reducers'
import contact from './contact/reducers'
import article from './article/reducers'
import cart from './cart/reducers'
import product from './product/reducers'
import auth from './auth/reducers'
import account from './account/reducers'
import reseller from './reseller/reducers'
import skinanalis from './skinanalis/reducers'
import chat from './chat/reducers'
import journey from './journey/reducers'


/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default history => combineReducers({
  router: connectRouter(history),
  settings,
  home,
  contact,
  article,
  cart,
  product,
  auth,
  account,
  reseller,
  skinanalis,
  chat,
  journey
})
