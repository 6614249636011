import actions from './actions'

const initialState = {
  loading: true, 
  listmember:[], 
  memberSelected:{
    memberId:'',
    nama:'',
    email:'',
    noHandphone:'',
  }
  
}

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}