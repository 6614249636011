import axios from 'axios'
import APIS from 'utils/apis'
import { getHeaderWoAuth } from 'utils/headers'

export function REG__PROVINCE() {
  return axios.get(APIS.API_PROVINCE, {headers: getHeaderWoAuth()}).then(res => res.data)
}

export function REG__CITY(payload) {
  const { province_id } = payload
  return axios.get(`${APIS.API_CITY}?province_id=${province_id}`, {headers: getHeaderWoAuth()}).then(res => res.data)
}

export function REG__SUBDISTRICT(payload) {
  const { city_id } = payload
  return axios.get(`${APIS.API_SUBDISTRICT}?city_id=${city_id}`, {headers: getHeaderWoAuth()}).then(res => res.data)
}

