
import React, { useEffect, useState } from 'react';
import { Container } from 'components/grid';
import { Affix, Col, Row, Modal } from 'antd';
import Variables from 'utils/variables';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MenuOutlined, GlobalOutlined, InstagramOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import { SiInstagram, SiTiktok } from 'react-icons/si'
const logo = Variables.BASE_IMG_LOCAL + '/logo-dream.png';
const HeaderMarket = () => {
  const history = useHistory()
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn)
  const settings = useSelector(({ settings }) => settings);
  const categoryData = useSelector(({ settings }) => settings.categoryData)
  const categoryProduct = useSelector(({ settings }) => settings.categoryProduct)
  const categoryLoading = useSelector(({ settings }) => settings.categoryLoading)
  const searchLoading = useSelector(({ product }) => product.searchLoading)
  const searchData = useSelector(({ product }) => product.searchData)
  const cartTotalItems = useSelector(({ cart }) => cart.cartTotalItems)
  const cartItems = useSelector(({ cart }) => cart.cartItems)
  const marqueetext = useSelector(({ home }) => home.marquee)
  // const auth = useSelector(({ auth }) => auth)

  const [productByCategory, setProductByCategory] = useState([])
  const [categorySelected, setCategorySelected] = useState(null)
  const [visible, setVisible] = useState(false);
  const [keyword, setKeyword] = useState('')

  const { isSidebarOpen, isMobileView } = settings;
  // const accountUrl = auth.isLoggedIn ? '/account' : '/login';

  const redirectWeb = () => {
    window.location.href = "https://www.doctorxeam.com/";
  }

  const redirectSocMed = () => {
    window.location.href = "https://instagram.com/doctor_eam_official?igshid=YmMyMTA2M2Y=";
  }

  const redirectTiktok = () => {
    window.location.href = "https://www.tiktok.com/@doctor_eam_official";
  }
  const redirectWa = () => {
    window.location.href = "https://wa.me/6281112122208?text=Hi Dream";
  }
  const redirectplaystore = () => {
    window.location.href = "https://play.google.com/store/apps/details?id=com.dream.id";
  }

  return (
    <>

      <div className="bolde-market">
        <Container>
          <div className="bolde-market_container">
            {/* <div className="my-5">  
                            <div className="color-standar text-center pt-5  ">No. Virtual Account</div>
                              <h1 className="color-standar text-center font-weight-bold pt-2  pb-2 bolde-font__md"> 
                              
                              </h1> 
                              <h1 className="text-center color-standar   pb-2"   role="button"> Salin </h1> 
                    </div>
                      <div className="my-5 mb-5">  
                        <div className="panel-collapse-guide"> 
                              <Row className="mb-1">
                                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{span:24}}>
                                  
                                 
                                  </Col>
                              </Row>  
                          </div> 
                      </div>  */}
            <div className="bolde-market_container_marketview">
              <div className="my-5 mb-5">
                <div className="text-center my-4 d-flex justify-content-center">
                  <div className="text-center "
                    style={{ margin: 'auto' }}
                    onClick={() => redirectWeb()}
                    className="px-4 bolde-bnt  btn-global-bolde-round py-2 mr-1"
                    style={{ border: "none", width: "300px", cursor: 'pointer', fontSize: "12px" }}>
                    <Row>
                      <Col xs={{ span: 4 }} sm={{ span: 4 }} md={{ span: 4 }}  >
                        <GlobalOutlined style={{ fontSize: "25px" }} />
                      </Col>
                      <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 20 }}  >
                        <h1 style={{ fontSize: '14px', color: 'white', marginTop: '5px' }}>WWW.DOCTORXEAM.COM </h1>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="text-center my-4 d-flex justify-content-center">
                  <div onClick={() => redirectSocMed()}
                    className="px-4 bolde-bnt  btn-global-bolde-round py-2  mr-1"
                    style={{ border: "none", width: "300px", cursor: 'pointer', fontSize: "12px" }}>
                    <Row>
                      <Col xs={{ span: 4 }} sm={{ span: 4 }} md={{ span: 4 }}  >
                        <InstagramOutlined style={{ fontSize: "25px" }} />
                      </Col>
                      <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 20 }}  >
                        <h1 style={{ fontSize: '14px', color: 'white', marginTop: '5px' }}> @doctor_eam_official </h1>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="text-center my-4 d-flex justify-content-center">
                  <div onClick={() => redirectTiktok()}
                    className="px-4 bolde-bnt  btn-global-bolde-round py-2  mr-1"
                    style={{ border: "none", width: "300px", cursor: 'pointer', fontSize: "12px" }}>
                    <Row>
                      <Col xs={{ span: 4 }} sm={{ span: 4 }} md={{ span: 4 }}  >
                        <SiTiktok style={{ fontSize: "25px" }} />
                      </Col>
                      <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 20 }}  >
                        <h1 style={{ fontSize: '14px', color: 'white', marginTop: '5px' }}> doctor_eam_official </h1>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="text-center my-4 d-flex justify-content-center">
                  <div onClick={() => redirectplaystore()}
                    className="px-4 bolde-bnt  btn-global-bolde-round py-2   mr-1"
                    style={{ border: "none", width: "300px", cursor: 'pointer', fontSize: "12px" }}>
                    <Row>
                      <Col xs={{ span: 4 }} sm={{ span: 4 }} md={{ span: 4 }}  >
                        <img src={Variables.BASE_IMG_LOCAL + '/logo-dream.jpg'} width={25} />
                      </Col>
                      <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 20 }}  >
                        <h1 style={{ fontSize: '14px', color: 'white', marginTop: '5px' }}> DR+EAM App </h1>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default HeaderMarket;