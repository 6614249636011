import { all, takeEvery, put, call } from 'redux-saga/effects'
import store from 'store'
import qs from 'qs'
import moment from 'moment'
import { history, store as reduxStore } from 'index'
import actions from './actions'
import { GET_PRD } from 'services/axios/product'
import { GET_CTG } from 'services/axios/category'
import { REG__PROVINCE } from 'services/axios/region' 

const menuCtgProccess = (ctg) => {
  const res = []
  ctg.map((x)=> (
    
    res.push({
      categoryID: x.CategoryId,
      categoryName: x.Category,
      categoryDesc: x.Desc,
      categoryImage: x.Image,
      categorySlug: x.Slug,
    })
  ))
  return res
}

const menuPrdProccess = (prd) => {
  const res = []
  prd.map((x) => (
    res.push({
      categoryID: parseInt(x.CategoryId),
      productID: x.ProductId,
      productName: x.ProductName,
      productSlug: x.Slug,
      productImage: (Array.isArray(x.product_images) && x.product_images.length > 0) ? x.product_images[0].Image : null,
    })
  ))
  return res
}

export function* CHANGE_SETTING({ payload: { setting, value } }) {
  yield store.set(`app.settings.${setting}`, value)
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      [setting]: value,
    },
  })
}

export function* SETUP() {
  // load settings from url on app load
  const changeSettings = search => {
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    Object.keys(query).forEach(key => {
      let value
      switch (query[key]) {
        case 'false':
          value = false
          break
        case 'true':
          value = true
          break
        default:
          value = query[key]
          break
      }
      if (key === 'theme') {
        reduxStore.dispatch({
          type: 'settings/SET_THEME',
          payload: {
            theme: value,
          },
        })
        return
      }
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: key,
          value,
        },
      })
    })
  }

  yield changeSettings(history.location.search)
  yield history.listen(params => {
    const { search } = params
    changeSettings(search)
  })

  // detect isMobileView setting on app load and window resize
  const isMobileView = (load = false) => {
    const currentState = global.window.innerWidth < 768
    const prevState = store.get('app.settings.isMobileView')
    if (currentState !== prevState || load) {
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMobileView',
          value: currentState,
        },
      })
    }
  }

  // detect viewport width on app load and window resize
  const isMenuToggled = () => {
    const shouldToggle = global.window.innerWidth < 1024
    const prevState = store.get('app.settings.isMenuCollapsed')
    if (shouldToggle || prevState) {
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMenuCollapsed',
          value: true,
        },
      })
    }
  }
  yield isMobileView(true)
  yield isMenuToggled()
  yield window.addEventListener('resize', () => {
    isMobileView()
    isMenuToggled()
  })
}

export function* SUBSCRIBE_MODAL(){

  const date = new Date(new Date().getTime());
  const tglskrg = date.setHours(0, 0, 0, 0); 
  const tgl_now = moment(tglskrg)
  const dateclose = localStorage.getItem('showModalSubsClose')

  const tgl_tutup = moment(dateclose)
   
  if(tgl_now > tgl_tutup)
  {
    localStorage.setItem('showModalSubs', true) 
    localStorage.setItem('showModalSubsClose', '')
  } 


  const showModalSubs = localStorage.getItem('showModalSubs')
    if (showModalSubs === null) {
      localStorage.setItem('showModalSubs', 'true')
      yield put({
        type: actions.SET_STATE,
        payload: {
          showModalSubs: true,
        }
      })
    }
    if (showModalSubs === 'true') {
      yield put({
        type: actions.SET_STATE,
        payload: {
          showModalSubs: true,
        }
      })
    }
    if (showModalSubs === 'false') {
      yield put({
        type: actions.SET_STATE,
        payload: {
          showModalSubs: false,
        }
      })
    }



} 
export function* GET_CATEGORIES() {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      categoryLoading: true
    }
  })
  try {
    const prv = yield call(REG__PROVINCE)
    const ctg = yield call(GET_CTG);
    const prd = yield call(GET_PRD, {perpage: 50, page: 1});
    const resCtg = menuCtgProccess(ctg.data)
    const resPrd = menuPrdProccess(prd.data)
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        categoryData: resCtg,
        categoryProduct: resPrd,
        provinces: prv
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        categoryLoading: false
      }
    })
  }
}

// export function* HANDLE_STATE({ field, value }) {


//   let r = (Math.random() + 1).toString(36).substring(7);
// console.log("random", r);
//   yield put({
//     type: 'settings/SET_STATE',
//     payload: {
//       [field]: value
//     }
//   })
// }
export function* HANDLE_STATE({ field, value }) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      [field]: value
    }
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.HANDLE_STATE, HANDLE_STATE),
    takeEvery(actions.CHANGE_SETTING, CHANGE_SETTING),
    takeEvery(actions.CATEGORY, GET_CATEGORIES),
    GET_CATEGORIES(),
    SETUP(), // run once on app load to init listeners
    SUBSCRIBE_MODAL(),
  ])
}
