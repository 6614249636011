import axios from 'axios'
import APIS from 'utils/apis'
import { getHeaderWoAuth } from 'utils/headers'

 

export function GET_PRD(payload) {
  return axios.post(APIS.API_PRODUCT, payload, { headers: getHeaderWoAuth() }).then(res => res.data)
}


export function GET_PRD_ALL() {
  return axios.post(APIS.API_PRODUCT, { headers: getHeaderWoAuth() }).then(res => res.data)
}
export function GET_PRD_SEARCH(payload) {
  const { keyword } = payload
  return axios.get(`${APIS.API_PRODUCT}?search=${keyword}`, { headers: getHeaderWoAuth() }).then(res => res.data)
}

export function GET_PRD_DETAIL(payload) {
  const { slug } = payload
  return axios.get(`${APIS.API_PRODUCT}/${slug}`, { headers: getHeaderWoAuth() }).then(res => res.data)
}

export function GET_PRD_DETAIL_REVIEW(payload) {
  const { slug } = payload
  return axios.get(`${APIS.API_PRODUCT}/review/${slug}`, { headers: getHeaderWoAuth() }).then(res => res.data)
}
 

export function GET_PRD_CAT(payload) {
  const { perpage, page, slug } = payload
  let pageQuery = ''
  let onPageQuery = ''

  if (perpage) {
    pageQuery = `?perpage=${perpage}`
  }
  if (page) {
    onPageQuery = `&page=${page}`
  }
  return axios.get(`${APIS.API_CATEGORY}/${slug}${pageQuery}${onPageQuery}`, { headers: getHeaderWoAuth() }).then(res => res.data)
} 

