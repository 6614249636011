import axios from 'axios'
import fs from 'fs'
import APIS from 'utils/apis'
import { getHeaderWoAuth, getHeaderMultipart } from 'utils/headers'

export function POST_MESSAGE(payload) {
  return axios.post(APIS.API_SUBCRIPTION, payload, { headers: getHeaderWoAuth() }).then(res => res.data)
}
export function POST_BA(payload) {
  return axios.post(APIS.API_BA, payload, { headers: getHeaderWoAuth() }).then(res => res.data)
}
export function POST_REG_REV(payload) {
  return axios.post(APIS.API_BA_REV, payload, { headers: getHeaderWoAuth() }).then(res => res.data)
}
export function POST_CONTACT_US(payload) {
  return axios.post(APIS.API_CONTACT_US, payload, { headers: getHeaderWoAuth() }).then(res => res.data)
}
export function GET_JOURNEY(payload) {
  const { kode } = payload
  return axios.get(`${APIS.API_JOURNEY}/${kode}`, { headers: getHeaderWoAuth() }).then(res => res.data)
}
export function POST_JOURNEY(payload) {
  const { kode } = payload
  return axios.post(`${APIS.API_JOURNEY}/${kode}`, payload, { headers: getHeaderWoAuth() }).then(res => res)
}
export function GET_JENISKULIT() {
  return axios.get(APIS.API_JENIS_KULIT, { headers: getHeaderWoAuth() }).then(res => res.data)
}
export function GET_KEBIASAAN() {
  return axios.get(APIS.API_KEBIASAAN, { headers: getHeaderWoAuth() }).then(res => res.data)
}
export function GET_PERAWATANKULIT() {
  return axios.get(APIS.API_PERAWATANKULIT, { headers: getHeaderWoAuth() }).then(res => res.data)
}
