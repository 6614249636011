
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Container } from 'components/grid';
// import { Link } from 'react-router-dom';
import Variables from 'utils/variables';
import Slider from 'react-slick';


import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const settings = {
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: true,
  fade: true,
  autoplaySpeed: 7000,
  speed: 1800,
  slidesToShow: 1,
  slidesToScroll: 1,


  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
      }
    }
  ],



};




const slickbanner = {
  autoplay: true,
  autoplaySpeed: 10000,
  speed: 600,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
  dots: true,
  pauseOnDotsHover: true,
  cssEase: 'linear',
  // fade:true,
  draggable: false,
  prevArrow: '<button className="PrevArrow"></button>',
  nextArrow: '<button className="NextArrow"></button>',

}

function Arrow(props) {
  let className = props.type === "next" ? "nextArrow arrowiconNextBanner" : "prevArrow arrowiconPrevBanner";
  className += " arrow arrowicon";
  const char = props.type === "next" ? <BsChevronRight /> : <BsChevronLeft />;
  // const char = props.type === "next" ? " " : " ";
  return (
    <span className={className} onClick={props.onClick}>
      {char}
    </span>
  );
}


const BannerStatic = () => {

  const bannerData = useSelector(({ home }) => home.bannerData)
  const bannerLoading = useSelector(({ home }) => home.bannerLoading)
  const banner = bannerData.filter(x => x.banner_category.BannerCategory === "Static_Banner")

  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);
    console.log('scrolling scrollTop ', scrolling, scrollTop)
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    console.log('scroll');
  }, [window.scroll])



  return (
    <>

      <Container>
        <div className="bolde-masthead">
          {
            bannerLoading &&
            <Skeleton height={600} />
          }
          {
            !bannerLoading &&
            <Slider
              nextArrow={<Arrow type="next" />}
              prevArrow={<Arrow type="prev" />}
              {...settings}>
              {
                banner.map((val, key) =>
                  <div key={key} className="bolde-masthead-static_banner">
                    <a key={key} href={val.Link}>
                      <img src={Variables.BASE_IMG_BANNER + val.BannerImage} alt="Dream" />
                      {/* <div className="info">
                        <div>
                          <h3>{val.Link}</h3>
                          <h5>You can easily add image, html formatted texts and video layers over each slide and each layer accepts unique animation.</h5>
                        </div>
                      </div> */}
                    </a>
                  </div>
                )
              }
            </Slider>

          }




        </div>






      </Container>
    </>
  );
}

export default BannerStatic;