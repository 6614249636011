import imageExists from 'image-exists'
// import i18next from 'i18next'
// import Variables from './variables';

export function currencyFormat(param, tf) {
  const toFixed = tf !== undefined ? tf : 0
  const str = Number(param)
    .toFixed(toFixed)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  return str
}

export function checkImg(img) {
  let result = img
  imageExists(img, (exists) => {
    if (exists) {
      result = img;
    }
  });
  return result
}

export function paymentStatus(param) {
  const lang = localStorage.getItem('i18nextLng')
  switch (param) {
    case 'pending':
    
      if(lang === 'id'){
        return 'Menunggu Pembayaran'; 
      }else{
        return 'Waiting Payment'; 
      }

    case 'settlement':
  
      if(lang === 'id'){
        return 'Dibayar'; 
      }else{
        return 'Paid'; 
      }

    case 'expire': 
      if(lang === 'id'){
        return 'Tidak berlaku';
      }else{
        return 'Expire'; 
      }

    case 'cancel': 
      if(lang === 'id'){
        return 'Dibatalkan';
      }else{
        return 'Cancel'; 
      }

    default:
     
      if(lang === 'id'){
        return 'Belum Memilih Pembayaran';
      }else{
        return 'Have not selected payment';
      }
  }
}


  
export function ReplaceHari(Service)
{
  
  const lang = localStorage.getItem('i18nextLng')
  const str1 = Service
  const str2 = "HARI" 
  const result = Service.split(' ');
  if(result[0] !== 0){ 
    if(str1.indexOf(str2) !== -1)
      {
        if(lang === 'id'){ 

          return str1.replace("HARI", "Hari")
        }else{
          return str1.replace("HARI", "Day")
        }
      }else{  
        if(lang === 'id'){  
          return str1 + " Hari"
        }else{
          return str1 + " Day"
        }
      } 
  }else{ 
    if(lang === 'id'){  
      return  "Hari ini" 
     }else{
      return  "Today" 
    }
  }
 
}



export function paymentOrder(param, payment) {
  
  const lang = localStorage.getItem('i18nextLng')
  if (payment === 'settlement') {
    switch (param) {
      case 'shipping':
      

        if(lang === 'id'){
          return 'Dalam Pengiriman';
        }else{
          return 'On delivery';
        }
  
      case 'delivered':
       
        if(lang === 'id'){
          return 'Diterima';
        }else{
          return 'Delivered';
        }
  
      default:
       
        if(lang === 'id'){
          return 'Diproses';
        }else{
          return 'On process';
        }
    }
  } else {
    return '-';
  }
}

export function HistoryStatus(param)
{
  const lang = localStorage.getItem('i18nextLng')
  switch(param)
  {
    case 1: 
      if(lang === 'id'){
        return 'Menunggu Pembayaran';
      }else{
        return 'Waitting payment';
      } 
    case 2:
      if(lang === 'id'){
        return 'Pembayaran Gagal'; 
      }else{
        return 'Payment failed';
      } 
    case 3: 
      if(lang === 'id'){
        return 'Pembayaran Berhasil';
      }else{
        return 'Payment success';
      } 
    case 4: 
      if(lang === 'id'){
        return 'Sedang Dalam Pengiriman';
      }else{
        return 'On delivery';
      } 
    case 5: 
      if(lang === 'id'){
        return 'Terkirim';
      }else{
        return 'Sent';
      } 
    case 6: 
      if(lang === 'id'){
        return 'Diterima';
      }else{
        return 'Received';
      } 
    case 7: 
      if(lang === 'id'){
        return 'Diulas'; 
      }else{
        return 'Reviewed';
      } 
    case 8: 
      if(lang === 'id'){
         return 'Pembatalan'; 
      }else{
        return 'Cancelled';
      }
 

    default: 
          return '-';  
  }
}

export function imgStts(param)
{
  switch(param)
  {
    case 1:  
        return 'notes order.png'; 
        
    case 2: 
        return 'notes order.png';  
    case 3:  
        return 'notes order.png'; 
    case 4:  
        return 'shipping.png';  
    case 5:  
        return 'icon terkirim.png'; 
    case 6:  
        return 'icon diterima.png'; 
    case 7:  
        return 'review icon.png';  
    case 8:  
         return 'cancel_order.png';   
    default: 
          return '-'; 
  }

}
export function SttsLabel(param)
{
  switch(param)
  {
    case 1:  
        return 'ORDER'; 
    case 2: 
        return 'ORDER';  
    case 3:  
        return 'ORDER'; 
    case 4:  
        return 'SHIPPING';  
    case 5:  
        return 'SHIPPING'; 
    case 6:  
        return 'ENJOY'; 
    case 7:  
        return 'REVIEW';  
    case 8:  
         return 'CANCEL';   
    default: 
          return '-'; 
  }

}

export function prdProccess (prd) {
   
  const res = []
  function vrnProc(prdVariant = []) {
   
    const variants = []
    let isDiscount = false
    // eslint-disable-next-line array-callback-return
    prdVariant.map(x => {
      if (x.isdiskon) {
        if (x.isdiskon === true) {
          isDiscount = true
        }
      }
      variants.push({
        variantID: x.VariantId,
        price: x.Price,
        stock: x.Stok,
        isDiscount: x.isdiskon,
        discountPercent: x.DiscountPercent,
        discountAmount: x.DiscountAmount,
        discountEndPrice: x.DiscountEndPrice,
        discountKuota: x.DiscountKuota,
        discountStartDate: x.DiscountStartDate,
        discountEndDate: x.DiscountEndDate,
        discountLabel: x.DiscountLabel,
      })
    })

    // return variants
    return {variants, isDiscount}
  }
  // console.log(prdVariant, 'safaas');
  prd.map(x => 
    res.push({
      categoryID: parseInt(x.CategoryId),
      productID: x.ProductId,
      productName: x.ProductName,
      productIsMain: x.IsMainProduct,
      productSlug: x.Slug,
      productCategory: x.category.Category,
      productRating: x.Rating,
      productMiniDesc: x.MiniDesc,
      productImage: x.product_images[0].Image,
      ...vrnProc(x.variant)
    })
  )
  return res
}
