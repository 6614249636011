const Variables = {
  BASE_URL: window.location.origin,
  BASE_IMG_LOCAL: window.location.origin + '/img',

  BASE_IMG_PROFILE: 'https://dashboard.doctorxeam.com/asset/userclass/',
  BASE_IMG_NOIMAGE: 'https://dashboard.doctorxeam.com/asset/',
  BASE_IMG_BANNER: 'https://dashboard.doctorxeam.com/banner/images/',
  BASE_IMG_CATEGORY: 'https://dashboard.doctorxeam.com/category/images/',
  BASE_IMG_PRODUCT: 'https://dashboard.doctorxeam.com/product/images/',
  BASE_IMG_CATALOG: 'https://dashboard.doctorxeam.com/catalog/',
  BASE_IMG_VARIANT: 'https://dashboard.doctorxeam.com/variant/images/',
  BASE_IMG_ARTICLE: 'https://dashboard.doctorxeam.com/artikel/images/',
  BASE_IMG_VOUCHER: 'https://dashboard.doctorxeam.com/voucher/images/',
  BASE_IMG_TESTIMONIAL: 'https://dashboard.doctorxeam.com/ceritadream/images/',
  BASE_IMG_LOGO: 'https://dashboard.doctorxeam.com/logo_bank/',

  // BASE_IMG_PROFILE: 'https://demodashboard.dreamxskin.com/asset/userclass/',
  // BASE_IMG_BANNER: 'https://demodashboard.dreamxskin.com/banner/images/',
  // BASE_IMG_CATEGORY: 'https://demodashboard.dreamxskin.com/category/images/',
  // BASE_IMG_PRODUCT: 'https://demodashboard.dreamxskin.com/product/images/',
  // BASE_IMG_CATALOG: 'https://demodashboard.dreamxskin.com/catalog/',
  // BASE_IMG_VARIANT: 'https://demodashboard.dreamxskin.com/variant/images/',
  // BASE_IMG_ARTICLE: 'https://demodashboard.dreamxskin.com/artikel/images/',
  // BASE_IMG_VOUCHER: 'https://demodashboard.dreamxskin.com/voucher/images/',
  // BASE_IMG_TESTIMONIAL: 'https://demodashboard.dreamxskin.com/ceritadream/images/',
  // BASE_IMG_LOGO: 'https://demodashboard.dreamxskin.com/logo_bank/',


}

export default Variables; 