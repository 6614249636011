import axios from 'axios'
import APIS from 'utils/apis'
import { getHeader, getHeaderWoAuth } from 'utils/headers'

export function GET_CHAT() {  
  return axios.get(APIS.API_CHAT, {headers: getHeader()}).then(res => res.data)
}
export function GET_CHAT_WOHEADER(payload) { 
  
  const {AccountId} =   payload 
  return axios.get(`${APIS.API_CHAT}?AccountId=${AccountId}`, {headers: getHeaderWoAuth()}).then(res => res.data)
}


export function POST_MESSAGE(payload) {  
  return axios.post(APIS.API_CHAT, payload,  {headers: getHeader()}).then(res => res.data)
}
export function POST_MESSAGE_WOHEADER(payload) {  
  return axios.post(APIS.API_CHAT, payload,  {headers: getHeaderWoAuth()}).then(res => res.data)
}

export function ADD_TOKEN(payload) {  
  return axios.post(`${APIS.API_CHAT}/addtoken`, payload, {headers: getHeader()}).then(res => res.data)
  
}

export function ADD_TOKEN_NOAUTH(payload) {  
  return axios.post(`${APIS.API_CHAT}/addtoken`, payload, {headers: getHeaderWoAuth()}).then(res => res.data)
  
}

export function GET_TESTIMONIAL(){ 
  return axios.get(APIS.API_CERITA, {headers:getHeaderWoAuth()}).then(res => res.data)
}


// export function GET_IP() { 
//   const getData = async () => {
//     const res = await axios.get('https://geolocation-db.com/json/')
//     console.log(res.data);
//     setIP(res.data.IPv4)
//   }
  
// }