import React, { useEffect, useRef,  useState } from "react";
import Variables from 'utils/variables';
import { useDispatch,   useSelector } from "react-redux";
import moment from 'moment';

import socketClient from 'socket.io-client';
function CustomerCare() {

  const chatMessages = useSelector(({chat}) =>  chat.chatMessage)
  const loading = useSelector(({chat}) =>  chat.loading)

  const Mobile = useSelector(({ settings }) => settings.isMobileView)

  const width = Mobile ? '44px' : '195px';



  const  chatlist = chatMessages.sort((a, b) => new Date(a.created_at) -   new Date(b.created_at));
  const [chatdata, setChatData] = useState([])

  const [OpenChat, setOpenChat] = useState('offline')
  const [ChatHeader, setChatHeader] = useState('docked')
  const [ChatMessage, setChatMessage] = useState('')
  const [DataMessage, setDataMessage] = useState('')
  const [TokenChat, setTokenChat] = useState('')
  const [socketIO, setSocketIO] = useState('')
  const [hidebox, setHidebox] = useState('')
  const dispatch = useDispatch()



  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({  behavior: "smooth" })
  }

  function click_chatBox(){

    if(OpenChat === 'offline')
    {
      setOpenChat('online')
      setChatHeader('')
    }else{
      setOpenChat('offline')
      setChatHeader('docked')
    }
  }


  useEffect(() => {
    dispatch({
      type:'chat/GET_MESSAGE'
    })
  }, []);

  useEffect(() => {
    configureSocket()
  }, [])

  useEffect(() => {

    setChatData(chatlist)
    // scrollToBottom()
    if(!loading){

      scrollToBottom()
    }
  }, [chatMessages]);


  const configureSocket = ( ) => {
    let socket = socketClient("https://socket.dreamxskin.com/");
    socket.on("onNewToken", (data) => {

      let token = localStorage.getItem('token') ?  localStorage.getItem('token') : 'null';
     if(token !== 'null'){
        dispatch({
          type:'chat/ADD_TOKENAUTH',
          payload:{
            Token :data
          }
        })
      }
        if(token === 'null'){
        dispatch({
          type:'chat/TOKEN_NOAUTH',
          payload:{
            AccountId :localStorage.getItem('ip_address'),
            Token : data
          }
        })
     }
    });
    socket.on("onReceivedMessage", (data) => {
      let token = localStorage.getItem('token') ?  localStorage.getItem('token') : 'null';
      if(token !== 'null'){
        dispatch({
          type:'chat/GET_MESSAGE'
        })
      }
      if(token === 'null'){
      dispatch({
        type:'chat/GET_MESSAGE_NOAUTH',
        payload:{
          AccountId:localStorage.getItem('ip_address')
        }
      })
    }
    });
    setSocketIO(socket)
  }


  const handleKeyDown = (event) => {
      if(ChatMessage!==''){
        if (event.key === 'Enter') {
          postMessage()
          if(!loading){

            scrollToBottom()
          }

  }
    }

  }
  const sendMessage = () => {
    if(ChatMessage!==''){
    postMessage()
      if(!loading){

        scrollToBottom()
      }
    }
  }


  const postMessage = () => {

    const token = localStorage.getItem('token') ?  localStorage.getItem('token') : 'null';
    if(token !== 'null'){
      dispatch({
        type:'chat/POST_NEW_MESSAGE',
        payload:{
          Message:ChatMessage,
        }
      })
    }
    if(token === 'null'){
      dispatch({
        type:'chat/POST_NEW_MESSAGE_WOHEADER',
        payload:{
          AccountId :localStorage.getItem('ip_address'),
          Message:ChatMessage,
        }
      })

    }

    setChatMessage('')
  }

  const onKeyPress = (e: React.KeyboardEvent) => {
    const specialCharRegex = new RegExp("[a-zA-Z0-9@._,%\"]");
    const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (!specialCharRegex.test(pressedKey)) {
       e.preventDefault();
       return false;
    }
 }

 // onClick={()=>setHidebox('hide')}
  return (



       <section className={"chat-window " + hidebox +"  "+ ChatHeader} style={{width: (OpenChat === 'offline') ?  width : '300px'}} >
        <div className={"chat-header " + OpenChat}  onClick={()=>click_chatBox()} >
            {
              (OpenChat === 'offline') &&
              <>
              {
                    Mobile &&
                    <p><img className="img-chat_icon" src={Variables.BASE_IMG_LOCAL + '/customer_care_icon.png'} width="30px" />    </p>
              }
              {
                    !Mobile &&
                    <p> Chat With Us   </p>
              }
              </>
            }
            {
              (OpenChat === 'online') &&
                <>
                  <p><img src={Variables.BASE_IMG_LOCAL + '/customer_care_icon.png'} width="30px" />    Customer Care</p>

                      <span className="close"  onClick={()=>setHidebox('hide')}  ></span>
                  </>
            }


          </div>
          <div className="chat-body">
            <div className="message-container">

            {
             (chatMessages.length > 0) && chatdata.map(x =>
                <>
                  {
                    (x.ChatType === "admin") &&
                    <div className="message">
                      <div className="profile">
                        <img src={Variables.BASE_IMG_LOCAL + '/customer care icon.png'} />
                      </div>
                      <div className="message-content-left">
                        <p>{x.Message}</p>
                      </div>
                      <div className="message-meta">
                         <p>{moment(x.created_at).calendar()}</p>
                      </div>
                    </div>

                }
                {
                    (x.ChatType === "customer") &&
                    <div className="message user">
                      <div className="profile">
                        <img  src={Variables.BASE_IMG_LOCAL + '/profile.png'}  />
                      </div>
                      <div className="message-content-right">
                        <p>{x.Message}</p>
                      </div>
                      <div className="message-meta">
                         <p>{moment(x.created_at).calendar()}</p>
                      </div>
                    </div>
                }
                </>
              )
            }
              <div  className="message"  style={{height:'40px'}} ref={messagesEndRef} />




            </div>
          </div>
          <div className="chat-footer">
          <div className="progress-indicator hide">
            <p>Isobel is typing</p>
          </div>
          <div className="form-area">
            <input type="text" value={ChatMessage} onKeyPress={e => onKeyPress(e)} onKeyDown={handleKeyDown} onChange={(e)=>setChatMessage(e.target.value)} placeholder="Send Message" />
            <button  onClick={()=>sendMessage()}></button>
          </div>
      </div>
      </section>

  );
}



export default CustomerCare
