import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './actions'
import { UPDATE_MEMBER_ADDRESS, MEMBER_UPDATE_DATA, GET_MEMBER, MEMBER_ADD_NEW} from 'services/axios/reseller'
import { message as msg } from 'antd';
import {store} from 'index'; 

 
 
function DataToArray(data)
{ 
  const res = []
  data.members.map(val =>
    res.push({
      memberId:val.id,
      codeUser:val.UserCode,
      name:val.Name,
      email:val.Email,
      noHandphone:val.PhoneNumber,
      tglLahir:val.TanggalLahir,
      bulanLahir:val.BulanLahir,
      tahunLahir:val.TahunLahir,
      birthDate:val.BirthDate,
      gender:val.Gender,
      kodereseller:val.ResellerCode,
      kodereferal:val.ReferralCode
    })
    ) 
    return res  
} 

export function* ADD_NEW_MEMBER({payload})
{
 yield put({
    type:'reseller/SET_STATE',
    palyload:{
      loading:true
    } 
 })
 try{
  const res = yield call(MEMBER_ADD_NEW, payload); 
  const lang = localStorage.getItem('i18nextLng')
  if(res.status === true){
    if(lang === 'id'){

      msg.success('Member baru berhasil disimpan.')
    }else{
      msg.success('New member successfully added.')
   
    }

   
    store.dispatch({
        type:'settings/HANDLE_STATE',
        field:'showModalFormMember',
        value:false
    })
      const res = yield call(GET_MEMBER);
        yield put({
          type:'reseller/SET_STATE',
          payload:{
            listmember:DataToArray(res)
          }
        })
  } 

 } catch (error) {
   console.log(error) 
 } finally { 
      yield put({
        type:'reseller/SET_STATE',
        payload:{
          loading:false
        }
      })

 }
}
 
export function* UPDATE_MEMBER_N({payload})
{
 
 yield put({
    type:'reseller/SET_STATE',
    palyload:{
      loading:true
    }

 })
 
 try{
  const res = yield call(MEMBER_UPDATE_DATA, payload);
  
  const lang = localStorage.getItem('i18nextLng')
  if(res.status){
    if(lang === 'id'){ 
      msg.success('Member berhasil diubah.')
    }else{ 
      msg.success('Member successfully updated.')
    }
    store.dispatch({
      type:'settings/HANDLE_STATE',
      field:'showModalFormMember',
      value:false
  })
      const res = yield call(GET_MEMBER);
        yield put({
          type:'reseller/SET_STATE',
          payload:{
            listmember:DataToArray(res)
          }
        })
  } 

 } catch (error) {
   console.log(error)

 } finally {

      yield put({
        type:'reseller/SET_STATE',
        payload:{
          loading:false
        }
      })

 }
}
 


export function* GET_LIST_MEMBER()
{ 
  const token = localStorage.getItem('token')
  yield put({
    type:'reseller/SET_STATE',
    payload:{
      loading:true
    }
  })
  try{
    if(token)
    {
      const res = yield call(GET_MEMBER);
      yield put({
        type:'reseller/SET_STATE',
        payload:{
          listmember:DataToArray(res)
        }
      })

    }
  }catch(error)
  {
    console.log(error)
  }finally {
    yield put({
      type:'reseller/SET_STATE',
      payload:{
        loading:true
      }
    })
  }  
}
 

export function* UPDATE_ADDRESS_MEMBER({payload})
{
      yield put({
          type: 'reseller/SET_STATE',
          payload:{
              loading:true
          }
      })
      try{
        const res = yield call(UPDATE_MEMBER_ADDRESS, payload);  
        const lang = localStorage.getItem('i18nextLng')
        if(res.status)
        {
          if(lang === 'id'){ 
            msg.success('Alamat berhasil disimpan') 
          }else{
            msg.success('Address saved successfully') 
          }
            store.dispatch({
                          type: 'settings/HANDLE_STATE',
                          field: 'showModalFormAddress',
                          value: false
                        })
        } 
      }catch (error){ 
        console.log(error);
      }finally{ 
        yield put({
            type:'reseller/SET_STATE',
            payload:{
                loading:false
            } 
        })
      }
 
}
export function* HANDLE_STATE({ field, value }) {
  yield put({
    type: 'reseller/SET_STATE',
    payload: {
      [field]: value
    }
  })
}

export default function* rootSaga() {
  yield all([ 
    
    takeEvery(actions.HANDLE_STATE, HANDLE_STATE),
    takeEvery(actions.UPDATE_ADDRESS_MEMBER, UPDATE_ADDRESS_MEMBER),
    takeEvery(actions.GET_MEMBER, GET_LIST_MEMBER),
    takeEvery(actions.UPDATE_MEMBER, UPDATE_MEMBER_N),
    takeEvery(actions.ADD_MEMBER, ADD_NEW_MEMBER),
  ])
}
