import axios from 'axios'
import APIS from 'utils/apis'
import { getHeaderWoAuth } from 'utils/headers'

export function GET_QUESTION() {
  return axios.get(APIS.API_SKIN_ANALIS, {headers: getHeaderWoAuth()}).then(res => res.data)
}

export function POST_QUESTION(paylod) {
  return axios.post(APIS.API_SKIN_ANALIS, paylod, {headers: getHeaderWoAuth()}).then(res => res.data)
}

// export function GET_VRT__TYPE() {
//   return axios.get(APIS.API_VARIANT__TYPE, {headers: getHeaderWoAuth()}).then(res => res.data)
// }

// export function GET_VRT__VALUE() {
//   return axios.get(APIS.API_VARIANT__VALUE, {headers: getHeaderWoAuth()}).then(res => res.data)
// }

