const actions = {
  SET_STATE: 'account/SET_STATE',
  HANDLE_STATE: 'account/HANDLE_STATE',
  ORDER: 'account/ORDER',
  ORDER_DETAIL: 'account/ORDER_DETAIL',
  GET_ORDER_DETAIL_BY_INV: 'account/GET_ORDER_DETAIL_BY_INV',
  GET_PROFILE: 'account/GET_PROFILE',
  UPDATE_PROFILE: 'account/UPDATE_PROFILE',
  GET_ADDRESS: 'account/GET_ADDRESS',
  UPDATE_ADDRESS: 'account/UPDATE_ADDRESS',
  ADD_ADDRESS: 'account/ADD_ADDRESS',
  GET_REVIEW: 'account/ORDER_REVIEW',
  ADD_REVIEW: 'account/ADD_REVIEW',
  SUBSCRIBE:'account/SUBSCRIBE',
  LIST_ADDRESS:'account/LIST_ADDRESS',
  GET_MYPOINT:'account/GET_MYPOINT',
  POST_WISHLIST:'account/ADD_WISHLIST',
  DELETE_WISHLIST:'account/DELETE_WISHLIST',
  DELETE_ADDRESS:'account/DELETE_ADDRESS',
  CANCEL_ORDER:'account/CANCEL_ORDER',
  GET_TRACKING:'account/GET_TRACKING',
  // GET_NOTIF:'account/GET_NOTIF',
}

export default actions
