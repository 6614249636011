import { all, takeEvery, put, call } from 'redux-saga/effects'
import { GET_PRD, GET_PRD_DETAIL, GET_PRD_DETAIL_REVIEW, GET_PRD_SEARCH } from 'services/axios/product';
import { GET_VRT__ALL, GET_VRT__DETAIL, GET_VRT__TYPE, GET_VRT__VALUE } from 'services/axios/variants';
import { prdProccess } from 'utils/helpers';
import actions from './actions'

// const srcPrdProccess = (prd) => {
//   const res = []
//   prd.map(x => {
//     res.push({
//       categoryID: parseInt(x.CategoryId),
//       productID: x.ProductId,
//       productName: x.ProductName,
//       productSlug: x.Slug,
//       productCategory: x.category.Category
//     })
//   })
//   return res
// }

export function* GET_DATA({ payload }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const prd = yield call(GET_PRD, payload);
    // console.log( prdProccess(prd.data), 'gjhghg')
    yield put({
      type: 'product/SET_STATE',
      payload: {
        // productData: prd.data,
        productData: prdProccess(prd.data),
        currentPage: prd.current_page,
        lastPage: prd.last_page,
        totalProduct: prd.total
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'product/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* GET_BY_CAT({ payload }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const prd = yield call(GET_PRD, payload);
    yield put({
      type: 'product/SET_STATE',
      payload: {
        productData: prd.product,
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'product/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* GET_BY_NAME({ payload }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      searchLoading: true
    }
  })
  try {
    const prd = yield call(GET_PRD_SEARCH, payload);
    if (prd.data.length > 0) {
      const res = prdProccess(prd.data)
      yield put({
        type: 'product/SET_STATE',
        payload: {
          searchData: res
        }
      })
    } else {
      yield put({
        type: 'product/SET_STATE',
        payload: {
          searchData: []
        }
      })
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'product/SET_STATE',
      payload: {
        searchLoading: false
      }
    })
  }
}

function getProductVariant(prdVrs) { 

  const detailVariant = []
  const data = prdVrs[0]

  if (prdVrs.length > 0) {
    prdVrs.map((x) => {
      const varDtl = []
      const varVal = []
      x.variant_detail.map(y => {
        if (parseInt(y.VariantId) === parseInt(x.VariantId)) {
          varDtl.push(parseInt(y.VariantDetailId))
        }
      })
      x.variant_detail.map(y => {
        if (!varVal.includes(y.value.ValueId))
          varVal.push(parseInt(y.value.ValueId))
      })
      if (!x.DeletedBy) {

        detailVariant.push({
          productID: parseInt(x.ProductId),
          variantID: parseInt(x.VariantId),
          variantSKU: x.SKU,
          variantImage: x.Image,
          variantPrice: x.Price,
          variantDetail: varDtl,
          variantValue: varVal,
          variantStock: x.Stok,
          variantKuotaDisc : x.DiscountKuota,
          variantDisc : x.DiscountAmount,
          variantPriceDisc : x.DiscountEndPrice,
          variantDiscPercent : x.DiscountPercent,
          variantDiscLabel : x.DiscountLabel,
          variantIsDiskon : x.isdiskon,
          variantSoldOrder : x.order_sum,
        })

      }
    })
  }
  return detailVariant
}

function getDetailVariant(prdVrs) {
  const detailVariant = []
  if (prdVrs.length > 0) {
    prdVrs.map(x =>
      x.variant_detail.map(y => {
        if (!detailVariant.find(z => z.productValueID == y.ValueId)) {
          if (!x.DeletedBy) {

            detailVariant.push({
              productID: parseInt(x.ProductId),
              productValueID: parseInt(y.ValueId),
              productVarianTypeID: parseInt(y.VarianTypeId),
              productVariantDetailID: parseInt(y.VariantDetailId),
              productVariantID: parseInt(y.VariantId),
              productVariantValueIsColor: parseInt(y.value.IsColor),
              productVariantValue: y.value.Value
            })
          }
        }
      })
    )
  }

  return detailVariant
}

function getDetailVariantColor(prdVrs) {
  const detailVariant = []
  if (prdVrs.length > 0) {
    prdVrs.map(x =>
      x.variant_detail.map(y => {
        if (!detailVariant.find(z => z.productValueID == y.ValueId)) {
          if (!x.DeletedBy && parseInt(y.value.IsColor)) {

            detailVariant.push({
              productID: parseInt(x.ProductId),
              productValueID: parseInt(y.ValueId),
              productVarianTypeID: parseInt(y.VarianTypeId),
              productVariantDetailID: parseInt(y.VariantDetailId),
              productVariantID: parseInt(y.VariantId),
              productVariantValueIsColor: parseInt(y.value.IsColor),
              productVariantValue: y.value.Value
            })
          }
        }
      })
    )
  }

  return detailVariant
}

function* getVariants(vrsAll) {
  const variants = []

  if (Array.isArray(vrsAll) && vrsAll.length > 0) {
    vrsAll.map(x =>
      variants.push({
        productID: parseInt(x.ProductId),
        variantID: parseInt(x.VariantId),
        variantSKU: x.SKU,
        variantImage: x.Image,
      })
    )
  }
  return variants
}

function* getVariantDetail(vrsDtl) {
  const variantDetail = []

  if (Array.isArray(vrsDtl) && vrsDtl.length > 0) {
    vrsDtl.map(x =>
      variantDetail.push({
        variantDetailID: parseInt(x.VariantDetailId),
        variantID: parseInt(x.VariantId),
        variantTypeID: parseInt(x.VariantTypeId),
        variantValueID: parseInt(x.ValueId),
        variantPrice: x.Price
      })
    )
  }
  return variantDetail
}

function* getVariantType(vrsTyp) {
  const variantType = []

  if (Array.isArray(vrsTyp) && vrsTyp.length > 0) {
    vrsTyp.map(x =>
      variantType.push({
        variantTypeID: parseInt(x.VariantTypeId),
        variantType: x.VariantType,
      })
    )
  }
  return variantType
}

function* getVariantValue(vrsVal) {
  const variantValue = []

  if (Array.isArray(vrsVal) && vrsVal.length > 0) {
    vrsVal.map(x =>
      variantValue.push({
        variantValueID: parseInt(x.ValueId),
        variantValue: x.Value,
        variantIsColor: parseInt(x.IsColor),
      })
    )
  }
  return variantValue
}

function* getImages(param) {
  const imagesArr = []
  if (Array.isArray(param) && param.length > 0) {
    param.map(x => {
      if (!x.DeletedBy) {

        imagesArr.push({
          productID: parseInt(x.ProductId),
          variantID: x.VariantId,
          productImage: x.Image,
          isDiscount: x.isdiskon ? parseInt(x.isdiskon) : 0,
          discountPercent: x.DiscountPercent,
          isVariant: x.VariantId !== undefined ? true : false
        })
      }
    }
    )
  }
  return imagesArr
}

function countStar(star, data) {
  const res = data.filter(x => parseInt(x.Rating) === star)
  return res.length
}


function TotalStar(data){

    const count1 = data.filter(x => parseInt(x.Rating) === 1) 
    const count2 = data.filter(x => parseInt(x.Rating) === 2) 
    const count3 = data.filter(x => parseInt(x.Rating) === 3) 
    const count4 = data.filter(x => parseInt(x.Rating) === 4) 
    const count5 = data.filter(x => parseInt(x.Rating) === 5) 

    const st1 = (count1 ? parseInt(count1) : 0) * 1 
    const st2 = (count2 ? parseInt(count2) : 0) * 2 
    const st3 = (count3 ? parseInt(count3) : 0) * 3 
    const st4 = (count4 ? parseInt(count4) : 0) * 4 
    const st5 = (count5 ? parseInt(count5) : 0) * 5  
    const totalUser =   (count1 ? parseInt(count1) : 0) +  (count2 ? parseInt(count2) : 0) +  (count3 ? parseInt(count3) : 0) +  (count4 ? parseInt(count4) : 0) +   (count5 ? parseInt(count5) : 0) 



    const totalStar = parseFloat(st1 + st2 + st3 + st4 + st5)
    const ttlRating = parseFloat(totalStar /  totalUser) 

    return ttlRating;

}

export function* GET_DETAIL({ payload }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      loadingDetail: true
    }
  })
  try {
    const prd = yield call(GET_PRD_DETAIL, payload)
    const review = yield call(GET_PRD_DETAIL_REVIEW, payload)
    const vrsAll = yield call(GET_VRT__ALL)
    const vrsDtl = yield call(GET_VRT__DETAIL)
    const vrsTyp = yield call(GET_VRT__TYPE)
    const vrsVal = yield call(GET_VRT__VALUE)

    const variants = yield call(getVariants, vrsAll.data)
    const variantDetail = yield call(getVariantDetail, vrsDtl.data)
    const variantType = yield call(getVariantType, vrsTyp.data)
    const variantValue = yield call(getVariantValue, vrsVal.data)
    const imagesProduct = yield call(getImages, prd.product_images)
    const imagesVariant = yield call(getImages, prd.variant)

    const productDetailVariant = yield call(getDetailVariant, prd.variant)
    const productDetailVariantColor = yield call(getDetailVariantColor, prd.variant)
    const productVariant = yield call(getProductVariant, prd.variant) 
 
    const starreview = review.avg ? parseInt(review.avg) : 0;
 
    yield put({
      type: 'product/SET_STATE',
      payload: {
        productDetail: prd,
        productDetailReview: review.data,
        productDetailReviewCount: review.count,
        productDetailReviewStars: {
          star1: countStar(1, review.data),
          star2: countStar(2, review.data),
          star3: countStar(3, review.data),
          star4: countStar(4, review.data),
          star5: countStar(5, review.data),
        },
        totalRating: starreview,
        productDetailVariant,
        productDetailVariantColor,
        productVariant,
        productImages: imagesVariant,
        productImages:  imagesProduct,
        variants,
        variantType,
        variantDetail,
        variantValue,
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'product/SET_STATE',
      payload: {
        loadingDetail: false
      }
    })
  }
}

export function* HANDLE_STATE({ field, value }) {
  yield put({
    type: 'product/SET_STATE',
    payload: {
      [field]: value
    }
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.HANDLE_STATE, HANDLE_STATE),
    takeEvery(actions.DATA, GET_DATA),
    takeEvery(actions.BY_CAT, GET_BY_CAT),
    takeEvery(actions.SEARCH, GET_BY_NAME),
    takeEvery(actions.DETAIL, GET_DETAIL), 
  ])
}
