import axios from 'axios'
import APIS from 'utils/apis'
import { getHeader } from 'utils/headers'

export function UPDATE_MEMBER_ADDRESS(payload) { 
  return axios.put(APIS.API_RESELLER, payload, {headers: getHeader()}).then(res => res.data)
}
export function GET_MEMBER()
{
  return axios.get(APIS.API_RESELLER, {headers:getHeader()}).then(res=>res.data)
}
export function MEMBER_ADD_NEW(payload){ 
  return axios.post(APIS.API_RESELLER, payload, {headers: getHeader()}).then(res => res.data)
}
export function MEMBER_UPDATE_DATA(payload){
   
  return axios.put(APIS.API_RESELLER, payload, {headers: getHeader()}).then(res => res.data)
}
