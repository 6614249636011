import actions from './actions'

const initialState = {
  loading: false,
  loadingpage: true,
  orderData: [],
  orderReview: [],
  orderDetail: [],
  voucher:'',
  orderStatus:'',
  dataCeheckout:[],
  profileData: {},
  myPoint:'',
  wishlist:[],
  wishlistCount:'',
  notification:'',
  countNotif:'',
  orderIdSelected:'',
  profileAddress: {},
  trackingStatus:'',
  profileAddressSelected:{
    customerAddressId:'',
        customerId: '',
        namaDepan: '',
        namaBelakang: '',
        perusahaan: '',
        alamat: '',
        jenisAlamat: '',
        kecamatan: '',
        kodePos: '',
        noHandphone: '',
        provinceID: '',
        provinceName: '',
        cityID: '',
        cityType: '',
        cityName: '',
        subdistrictID: '',
        subdistrictName: '',
        postCode: '',
  },
  listaddress:[],
  reviewData: [],
}

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
