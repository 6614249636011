import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'components/grid';
import Variables from 'utils/variables';
import { Col, Input, Row, Form,  Modal, Button, Spin } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { SiInstagram, SiTiktok } from 'react-icons/si'

import { FaRegEnvelope } from 'react-icons/fa'
import { ImWhatsapp } from 'react-icons/im'
import { LoadingOutlined } from '@ant-design/icons';
import OtpInput from 'react-otp-input';
import Countdown from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ModalBerlangganan } from 'components/modal';
import CustomerCare from 'components/customer_care';



const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const spinIcon2 = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const logo = Variables.BASE_IMG_LOCAL + '/logo-dream.png';

const FooterComponent = () => {

  const dispatch = useDispatch()
  const countDownRef = useRef(null)
  const history = useHistory()
  const [form] = Form.useForm();
  const otpModal = useSelector(({ auth }) => auth.otpModal)
  const loading = useSelector(({ auth }) => auth.loading)
  const loading2 = useSelector(({ contact }) => contact.loading)
  const account = useSelector(({ auth }) => auth.account)
  const otpStatus = useSelector(({ auth }) => auth.otpStatus)
  const addBeforeLogin = useSelector(({ product }) => product.addBeforeLogin)
  const isMobileView = useSelector(({ settings }) => settings.isMobileView)
  const [startTime, setStartTime] = useState('')
  const [OpenChat, setOpenChat] = useState('offline')
  const [ChatHeader, setChatHeader] = useState('docked')
  const [otp, setOtp] = useState('')
  const { t } = useTranslation()
  const logowidth = isMobileView ? '40%':'60%';
  const [loadClient, setLoadClient] = useState(true);


  const widthIcon = isMobileView ? '60%'  : '100%';
  

  useEffect(() => {
    setStartTime(Date.now() + 59000)
    // countDownRef.current.start()
  }, [otpModal])



  useEffect(() => {
    if (otp.length === 6) {
      let accountType = account.indexOf('@') > 0 ? 'email' : 'whatsapp'
      const type = otpStatus === 'signin' ? 'auth/CONFIRM_OTP_LOGIN' : 'auth/CONFIRM_OTP_REGISTER'
      if (addBeforeLogin) {
        dispatch({
          type,
          payload: {
            Account: account,
            AccountType: accountType,
            Segment: otpStatus,
            Code: otp,
            VariantId: addBeforeLogin.VariantId,
            Qty: addBeforeLogin.Qty,
            Note: addBeforeLogin.Note,
            currentCartTotalItems: addBeforeLogin.currentCartTotalItems,
            dtlSlug: addBeforeLogin.dtlSlug
          }
        })

      } else {
        dispatch({
          type,
          payload: {
            Account: account,
            AccountType: accountType,
            Segment: otpStatus,
            Code: otp,
          }
        })

      }
    }
  }, [otp])

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span><Button type="link bolde-font__light bolde-font__white" onClick={backRequest}> {t('Text_VerificationTimerToSend3')}</Button> </span>;
    } else {
      return <span className="bolde-font__light">{t('Text_VerificationTimerToSend1')} {seconds}  {t('Text_VerificationTimerToSend2')}</span>;
    }
  };




  const backRequest = () => {
    dispatch({
      type: 'auth/LOGIN',
      payload: {
        Account: account,
        wa: true,
        // Account: email,
        // Password: password
      }
    })
    countDownRef.current.start()
    setStartTime(Date.now() + 59000)
  }

  function renderTitle() {
    return (
      <>
        <h1 className="text-center title bolde-font__bold bolde-font__white">{t('Text_VerificationCode')}</h1>
      </>
    )
  }
  const handleRedirect = () => {
    history.push('/')
  }

  const onFinishh = async (values) => {
    await dispatch({
      type: 'contact/SEND_MESSAGE',
      payload: {
        "Email": values.email,
      }
    })
    form.setFieldsValue({
      email: '',
    })

  }


  const onKeyPress = (e: React.KeyboardEvent) => {
    const specialCharRegex = new RegExp("[a-zA-Z0-9@._]");
    const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (!specialCharRegex.test(pressedKey)) {
       e.preventDefault();
       return false;
    }
 }


  return (
    <>

      <div className="bolde-footer py-lg-5 py-3 mt-5"  >

      <div className="d-flex justify-content-between" style={{marginTop:'-100px' }}>
        <div className="mx-auto float-input" style={{padding:'20px'}} >
          <div className="d-flex justify-content-center">
            <h1 className="text-center mb-2 title-popup bolde-font-custom__normal" >{t('Footer_form')}</h1>
          </div>
          <Spin indicator={spinIcon2} spinning={loading2}>
              <Form
                name="checkout"
                initialValues={{ remember: true }}
                onFinish={onFinishh}
                form={form}
              >
              <Row    gutter={12} justify="space-between" >

                <Col   xs={{ span: 17 }}   sm={{ span: 18 }} lg={{span:20}} className="mb-2" >
                <div className="d-flex justify-content-center" style={{width:'100%'}} >
                  <Form.Item
                        name="email"  style={{width:'100%'}}
                        rules={[{
                          required: true,
                          type: "email",
                          message: "Format email xxxxxxxx@xxxx.xxx"
                        }
                        // ,
                        //   {
                        //     pattern: new RegExp(/^[A-Za-z0-9@._" "]+$/ ),
                        //     message: 'Hanya huruf, angka dan spesial karakter yang ditentukan (., _ )'
                        //   }
                        ]}
                      >
                        <Input   onKeyPress={e => onKeyPress(e)} className="form-control input-langganan  bolde-font-custom__thin" placeholder={'example@email.com'}/>
                      </Form.Item>
                </div>
                </Col>
                <Col   xs={{ span: 7 }} sm={{ span: 6 }} lg={{ span:4}}  className="mb-2">
                  <div  className="d-flex justify-content-center" style={{width:'100%'}}>
                  <Button className="btn btn-default btn-follow " htmlType="submit"> {t('Footer_formButton')}</Button>
                  </div>
                </Col>

              </Row>
              </Form>
                </Spin>
        </div>
      </div>

        <Container>
          <Row gutter={12}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }} lg={{ span: 5 }}>
              <h1 className="bolde-footer--title bolde-font__lg mt-5 mb-1 text-uppercase text-sm-left text-center"> </h1>
              <div className={isMobileView ? 'd-flex flex-nowrap bd-highlight  mt-2  justify-content-center' : 'd-flex flex-nowrap bd-highlight  mt-2'}  >

               <img  onClick={handleRedirect} src={logo} width={logowidth} className="img-fluid" alt="" style={{cursor:"pointer"}} />
                {/* <div className="order-3 p-2 bd-highlight text-cente"><img src={Variables.BASE_IMG_LOCAL + '/logo-non-alkohol.png'}  alt="Alcohol Free" className="img-fluid img-footer-alchohol"   /></div>
                <div className="order-2 p-2 bd-highlight text-cente"><img src={Variables.BASE_IMG_LOCAL + '/logo-bpom.png'}   alt="BPOM logo" className="img-fluid img-footer-bpom" style={{ width:'86px', height:'75px'}} /></div>  */}
              </div>
            </Col>


            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
              <h1 className="bolde-footer--title    mt-5 mb-4 text-uppercase text-sm-left text-center">{t('Footer_title_help')}</h1>
              <ul className="mb-lg-5 mb-sm-3 bolde-footer--list__with-icon " >
                <li className=" d-sm-block bolde-font-custom__light">
                  {/* <SiInstagram /> */}

                  <Link to="/help/terms">{t('Footer_TermCondition')}</Link>
                </li>
                <li className=" d-sm-block bolde-font-custom__light">
                  {/* <ImWhatsapp /> */}
                  <Link to="/contact-us">{t('Footer_CallUs')}</Link>
                </li>
                <li className="  d-sm-block bolde-font-custom__light">
                  {/* <FaRegEnvelope /> */}
                  <Link to="/help/faq">{t('Footer_FAQ')}</Link>
                </li>
                <li className=" d-sm-block bolde-font-custom__light">
                  {/* <FaRegEnvelope /> */}
                  <Link to="/help/send-refund">{t('Footer_SendRefund')}</Link>
                </li>
              </ul>
            </Col>


            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
              <h1 className="bolde-footer--title  mt-5 mb-4 text-uppercase text-sm-left text-center">{t('Footer_title_about')}</h1>
              <ul className="mb-lg-2 mb-sm-3 bolde-footer--list__with-icon" style={{color:'#7561a9'}}>
                <li className="d-inline-flex d-sm-block">
                  <Link to="/about-us">{t('Footer_About')}</Link>
                </li>

              </ul>

              <h1 className="bolde-footer--title    mt-3 mb-4 text-uppercase text-sm-left text-center">{t('Footer_title_findus')}</h1>
              <div className="d-flex flex-row bd-highlight mb-3  social_media-icon" style={{color:'#7561a9'}}>
                <div className="p-2 bd-highlight"><a target='_blank' href="https://instagram.com/dreamxskin_official" style={{color:"rgb(117, 97, 169)"}}><SiInstagram /></a></div>
                <div className="p-2 bd-highlight"><a target='_blank' href="https://wa.me/6281112122208?text=Hi Dream" style={{color:"rgb(117, 97, 169)"}}><ImWhatsapp /></a></div>
                <div className="p-2 bd-highlight"><a target='_blank' href="mailto:customercare@dreamxskin.com"  style={{color:"rgb(117, 97, 169)"}}> <FaRegEnvelope /> </a></div>
                <div className="p-2 bd-highlight"><a target='_blank' href="https://www.tiktok.com/@dreamxskin_official"  style={{color:"rgb(117, 97, 169)"}}> <SiTiktok /> </a></div>
              </div>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
              <h1 className="bolde-footer--title   mt-5 mb-4 text-uppercase text-sm-left text-center">{t('Footer_title_join')}</h1>
              <ul className="mb-lg-5 mb-sm-3 bolde-footer--list__with-icon" style={{color:'#7561a9'}}>
                <li className="d-inline-flex d-sm-block">
                  <a  href="#"  style={{color:"rgb(117, 97, 169)"}}>{t('Footer_Reseller')}</a>
                </li>
              </ul>
            </Col>




              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 4 }}>
                 <div className="d-flex flex-row bd-highlight  mt-5 mb-4  social_media-icon"  >
                     <div className="p-2 bd-highlight">
                     <a   target="_blank" href="https://apps.apple.com/id/app/dream/id1612420759"  >
                          <img src={Variables.BASE_IMG_LOCAL + '/appstore.png'} style={{width: widthIcon, height:'auto' }} className="mb-3" />
                          </a>
                       <a  target="_blank" href="https://play.google.com/store/apps/details?id=com.dream.id"  >
                        <img   src={Variables.BASE_IMG_LOCAL + '/playstore.png'}  style={{width: widthIcon , height:'auto' }}   />
                       </a>
                     </div>
                 </div>
           </Col>

          </Row>
        </Container>
      </div>

       <CustomerCare  />

      <Modal
        title={false}
        visible={otpModal}
        className="bolde-otp"
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}
        footer={null}
        closable={false}
      >
        <Spin indicator={spinIcon} spinning={loading}>
          {renderTitle()}
          <div className="bolde-search--wrapper">
            <div className="text-center bolde-font__md bolde-font__light bolde-font__white mb-3">
            {t('Text_SendVerificationTo')}
              <div className="bolde-font__bold">
                {account}
              </div>
            </div>
            <OtpInput
              numInputs={6}
              className="otp"
              containerStyle="justify-content-between"
              value={otp}
              onChange={otp => setOtp(otp)}
              isInputNum={true}
            />
            <div className="text-center bolde-font__xs bolde-font__light bolde-font__white mt-3">
              <Countdown
                ref={countDownRef}
                date={startTime}
                renderer={renderer}
              />
            </div>

          </div>
        </Spin>
      </Modal>

      <ModalBerlangganan    />
    </>
  );
}

export default FooterComponent;
