import React, { useState } from 'react';
import Variables from 'utils/variables';
import { MdClose, MdDelete } from 'react-icons/md'
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';

const innerWidth = global.window.innerWidth

export const CartComponent = () => {
  const isMobileView = useSelector(({ settings }) => settings.isMobileView)

  const [cartQty, setCartQty] = useState(1)

  return (
    <>
      {
        !isMobileView &&
        <Row Row gutter={[15, 15]}>
          <Col span={9}>
            <div className="bolde-cart--field bolde-font--center">
              Produk
            </div>
          </Col>
          <Col span={3}>
            <div className="bolde-cart--field bolde-font--center">
              Harga
            </div>
          </Col>
          <Col span={6}>
            <div className="bolde-cart--field bolde-font--center">
              Quantity
            </div>
          </Col>
          <Col span={4}>
            <div className="bolde-cart--field bolde-font--center">
              Total
            </div>
          </Col>
          <Col span={2}>
            <div className="bolde-cart--field bolde-font--center">
              Hapus
            </div>
          </Col>
        </Row>
      }
      <div className="bolde-cart--item">
        <Row gutter={[15, 15]} justify="center" align="middle">
          <Col xs={24} sm={13} md={9}>
            <div className="bolde-cart--product">
              <div className="bolde-cart--product--img">
                <img src={`${Variables.PATH_LOCAL_IMG}product/sku-01.jpg`} alt={process.env.REACT_APP_WEBSITE_NAME} className="bolde-img" />
              </div>
              <div className="bolde-cart--product--detail">
                <div className="bolde-cart--product--detail--name">
                  Bed Cover Signature Single Tone
                </div>
                <div className="bolde-cart--product--detail--size">
                  Ukuran:
                </div>
                <div className="bolde-cart--product--detail--color">
                  Warna:
                </div>
                {
                  isMobileView &&
                  <div className="bolde-cart--product--detail--price">
                    Rp 543.000
                  </div>
                }
                {
                  innerWidth < 576 &&
                  <>
                    <div className="bolde-cart--qty">
                      <button
                        className="bolde-button--cart"
                      >
                        -
                      </button>
                      <input
                        value={cartQty}
                        onChange={event => setCartQty(event.target.value.replace(/\D/, ''))}
                        className="bolde-input--qty"
                      />
                      <button
                        className="bolde-button--cart"
                      >
                        +
                      </button>
                      <button
                        className="bolde-button--cart" style={{ marginLeft: '10px' }}
                      >
                        <MdDelete style={{ verticalAlign: 'middle' }} />
                      </button>
                    </div>
                  </>
                }
              </div>
            </div>
          </Col>
          {
            !isMobileView &&
            <Col md={3}>
              <div className="bolde-cart--price">
                Rp 543.000
              </div>
            </Col>
          }
          <Col xs={0} sm={7} md={6}>
            <div className="bolde-cart--qty">
              <button
                className="bolde-button--cart"
              >
                -
              </button>
              <input
                value={cartQty}
                onChange={event => setCartQty(event.target.value.replace(/\D/, ''))}
                className="bolde-input--qty"
              />
              <button
                className="bolde-button--cart"
              >
                +
              </button>
            </div>
          </Col>
          {
            !isMobileView &&
            <Col xs={24} sm={12} md={4}>
              <div className="bolde-cart--total">
                Rp 543.000
              </div>
            </Col>
          }
          <Col xs={0} sm={2} md={2}>
            <div className="bolde-cart--remove">
              <button
                className="bolde-button--cart"
              >
                <MdClose style={{ verticalAlign: 'middle' }} />
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export const CartInfoComponent = ({ withHader = true}) => {
  const isMobileView = useSelector(({ settings }) => settings.isMobileView)

  return (
    <>
      {
        withHader && !isMobileView &&
        <Row gutter={[15, 15]}>
          <Col span={14}>
            <div className="bolde-cart--field bolde-font--center">
              Produk
            </div>
          </Col>
          <Col span={4}>
            <div className="bolde-cart--field bolde-font--center">
              Qty
            </div>
          </Col>
          <Col span={6}>
            <div className="bolde-cart--field bolde-font--center">
              Total
            </div>
          </Col>
        </Row>
      }
      <div className="bolde-cart--item">
        <Row gutter={[15, 15]} justify="center" align="middle">
          <Col xs={24} md={14}>
            <div className="bolde-cart--product">
              <div className="bolde-cart--product--img">
                <img src={`${Variables.PATH_LOCAL_IMG}product/sku-01.jpg`} alt={process.env.REACT_APP_WEBSITE_NAME} className="bolde-img" />
              </div>
              <div className="bolde-cart--product--detail">
                <div className="bolde-cart--product--detail--name">
                  Bed Cover Signature Single Tone
                </div>
                <div className="bolde-cart--product--detail--size">
                  Ukuran:
                </div>
                <div className="bolde-cart--product--detail--color">
                  Warna:
                </div>
                {
                  isMobileView &&
                  <>
                    <div className="bolde-cart--product--detail--price">
                      <strong>Qty: 5</strong> x @Rp 543.000
                    </div>
                    <div className="bolde-cart--product--detail--price">
                      <strong>Total</strong> Rp 2.643.000
                    </div>
                  </>
                }
              </div>
            </div>
          </Col>
          <Col xs={0} md={4}>
            <div className="bolde-cart--qty">
              1
            </div>
          </Col>
          <Col xs={0} md={6}>
            <div className="bolde-cart--total">
              Rp 543.000
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
