import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './actions'
import { GET_JOURNEY, POST_JOURNEY, POST_BA, POST_REG_REV } from 'services/axios/contact';

export function* GET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoading: true
    }
  })
  try {
    const res = yield call(GET_JOURNEY, payload)
    yield put({
      type: actions.SET_STATE,
      payload: {
        journeyDetail: res,
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoading: false
      }
    })
  }
}

export function* POST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoading: true
    }
  })
  try {
    const res = yield call(POST_JOURNEY, payload)
    const { status } = res
    if (status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          status
        }
      })
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoading: false
      }
    })
  }
}
export function* POST_REGBA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoading: true
    }
  })
  try {
    const res = yield call(POST_BA, payload)
    const { status } = res
    if (status === 'success') {
      yield put({
        type: actions.SET_STATE,
        payload: {
          status
        }
      })
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoading: false
      }
    })
  }
}
export function* POST_REG_REVIEW({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoading: true
    }
  })
  try {
    const res = yield call(POST_REG_REV, payload)
    const { status } = res
    if (status === 'success') {
      yield put({
        type: actions.SET_STATE,
        payload: {
          status
        }
      })
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoading: false
      }
    })
  }
}

export function* HANDLE_STATE({ field, value }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      [field]: value
    }
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.HANDLE_STATE, HANDLE_STATE),
    takeEvery(actions.GET_JOURNEY, GET),
    takeEvery(actions.POST_JOURNEY, POST),
    takeEvery(actions.POST_BAREG, POST_REGBA),
    takeEvery(actions.POST_REG_REVIEW, POST_REG_REVIEW),
  ])
}