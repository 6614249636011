 import React from 'react'
import { connect } from 'react-redux' 
import { withRouter } from 'react-router' 
import Public from './Public';
import Transaksi from './Transaksi';
import Market from './Market';
 

const Layouts = {
  public: Public,
  transaksi: Transaksi,
  market: Market,
}

const mapStateToProps = ({ user, router }) => ({ user, pathname: router.location.pathname })

const Layout = ({ children, pathname }) => {
  // const isLogin = true
  
  const getLayout = () => {
    if (/^\/transaksi(?=\/|$)/i.test(pathname)) {
      return 'transaksi'
    } else
    if (/^\/market(?=\/|$)/i.test(pathname)) {
   
      return 'market'
    } else{
      return 'public'

    }
    // if (/^\/market(?=\/|$)/i.test(pathname)) {
    //   return 'market'
    // }
    // return 'public'
  }
  
  const Containier = Layouts[getLayout()]

  const BootstrappedLayout = () => {
    return <Containier>{children}</Containier>
  }

  return (
    <>
      {/* <Helmet title={`Pasarro | Marketplace B2B dengan ongkir termurah di Indonesia`} /> */}
      {BootstrappedLayout()}
    </>
  );
}

export default withRouter(connect(mapStateToProps)(Layout));

// export default Layout;