
import { message as msg } from 'antd';
import { store } from 'index';
import { all, takeEvery, put, call, take } from 'redux-saga/effects'
import { GET_CHAT, POST_MESSAGE,  ADD_TOKEN_NOAUTH, POST_MESSAGE_WOHEADER,  GET_CHAT_WOHEADER,  ADD_TOKEN} from 'services/axios/chat' 
import actions from './actions'

 
function DataChat(data){

    const chatlist = []
    data.chat.map(x => { 
        chatlist.push({ 
          // ChatId: x.ChatId,
          ChatType:x.ChatType,
          // RoomId:x.RoomId,
          Message:x.Message,
          AttachmentURL:x.AttachmentURL,
          Status:x.Status,
          created_at:x.created_at   
        })

    })
    return chatlist

}

export function* GET_MESSAGE_NOAUTH({ payload }){ 
  yield put({
    type: 'chat/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(GET_CHAT_WOHEADER, payload);
    if (res.status) {
    //   msg.success(res.message)
      yield put({
        type: 'chat/SET_STATE',
        payload: {
          chatMessage:DataChat(res.data) 
        }
      })
    }

  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'chat/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}
export function* GET_MESSAGE(){ 
  yield put({
    type: 'chat/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(GET_CHAT);
    if (res.status) {
    //   msg.success(res.message)
      yield put({
        type: 'chat/SET_STATE',
        payload: {
          chatMessage:DataChat(res.data) 
        }
      })
    }

  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'chat/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}


export function* POST_NEW_MESSAGE({ payload }){  
  yield put({
    type: 'chat/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(POST_MESSAGE, payload);
    if (res.status) {
      // msg.success(res)
    //   yield put({
    //     type: 'chat/SET_STATE',
    //     payload: {
    //       chatMessage: res
    //     }
    //   })
 
    yield GET_MESSAGE()
    }

  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'chat/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* TOKEN_NOAUTH({ payload }){  
  yield put({
    type: 'chat/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(ADD_TOKEN_NOAUTH, payload);
    if (res.status) {
 
    }

  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'chat/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* ADD_TOKENAUTH({ payload }){  
  yield put({
    type: 'chat/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(ADD_TOKEN, payload);
    if (res.status) {
 
    }

  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'chat/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}
export function* POST_NEW_MESSAGE_WOHEADER({ payload }){  
  yield put({
    type: 'chat/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(POST_MESSAGE_WOHEADER, payload);
    if (res.status) {
      
      const resdata = yield call(GET_CHAT_WOHEADER, payload)  
      if (resdata.status) { 
        yield put({
          type: 'chat/SET_STATE',
          payload: {
            chatMessage:DataChat(resdata.data) 
          }
        })
      }
         
    }

  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'chat/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}


export function* HANDLE_STATE({ field, value }) {
  yield put({
    type: 'chat/SET_STATE',
    payload: {
      [field]: value
    }
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.HANDLE_STATE, HANDLE_STATE), 
    takeEvery(actions.GET_MESSAGE, GET_MESSAGE), 
    takeEvery(actions.GET_MESSAGE_NOAUTH, GET_MESSAGE_NOAUTH), 
    takeEvery(actions.POST_NEW_MESSAGE, POST_NEW_MESSAGE), 
    takeEvery(actions.POST_NEW_MESSAGE_WOHEADER, POST_NEW_MESSAGE_WOHEADER), 
    takeEvery(actions.TOKEN_NOAUTH, TOKEN_NOAUTH), 
    takeEvery(actions.ADD_TOKENAUTH, ADD_TOKENAUTH), 
    GET_MESSAGE(),
  ])
}
