 import React from  'react';
// import { Col, Row } from 'antd';
 
const AddressComponent = ({ address, noBorder = false }) => { 
  return (  
    <>
 
      <div className="bolde-address">
        <div className="bolde-address--container">
          <div className="bolde-address--name">
          {address.namaDepan} {address.namaBelakang}
            {/* <span className="bolde-address--email">
              ( kuntariw@gmail.com )   {address.namaDepan}
            </span> */}
            <div className="bolde-address--phone">
              {/* (+62) 81292830092 */}
              {address.noHandphone}
            </div>
            <div className="bolde-address--detail">
              {address.jenisAlamat}, {address.subdistrictName}, {address.cityType}, {address.cityName},  {address.provinceName} {address.postCode}
              {/* Jl.  dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
              erat volutpat. KOTA TANGERANG, BANTEN */}
            </div>
          </div>
        </div>
        {
          !noBorder &&
          <hr />
        }
      </div>  
      </>
  );
}

export default AddressComponent;