import axios from 'axios'
import APIS from 'utils/apis'
import { getHeader } from 'utils/headers'

export function PROFILE__GET() {  
  return axios.get(APIS.API_PROFILE, {headers: getHeader()}).then(res => res.data)
}

export function PROFILE__UPDATE(payload) {  
  return axios.put(APIS.API_PROFILE, payload, {headers: getHeader()}).then(res => res.data)
}

export function PROFILE__ADDRESS() {  
  return axios.get(APIS.API_PROFILE__ADDRESS, {headers: getHeader()}).then(res => res.data)
}

export function PROFILE__ADDRESS__UPDATE(payload) {  
  return axios.put(APIS.API_PROFILE__ADDRESS, payload, {headers: getHeader()}).then(res => res.data)
}

export function DELETE_ADDRESS(payload) { 
 
  const { CustomerAddressId } = payload 
  return axios.delete(`${APIS.API_PROFILE__ADDRESS}?CustomerAddressId=${CustomerAddressId}`, {headers: getHeader()}).then(res => res.data)
}


export function PROFILE__ADDRESS_ADD(payload) {  
  return axios.post(APIS.API_PROFILE__ADDRESS, payload, {headers: getHeader()}).then(res => res.data)
}
export function CANCEL_ORDER(payload) {  
  return axios.post(APIS.API_CANCEL, payload, {headers: getHeader()}).then(res => res.data)
}

export function ORDER__GET() {
  return axios.get(APIS.API_ORDER, {headers: getHeader()}).then(res => res.data)
}

export function ORDER__DETAIL(payload) {  
  const { orderID } = payload
  return axios.get(`${APIS.API_ORDER}?OrderId=${orderID}`, {headers: getHeader()}).then(res => res.data)
}
export function ORDER__DETAIL_BY_INV(payload) {  
  const { InvoiceNo } = payload
  return axios.get(`${APIS.API_ORDER}?InvoiceNo=${InvoiceNo}`, {headers: getHeader()}).then(res => res.data)
}

export function REVIEW__POST(payload) {  
  return axios.post(APIS.API_REVIEW, payload, {headers: getHeader()}).then(res => res.data)
}
 
export function GET_REVIEW() {  
  return axios.get(APIS.API_REVIEW, {headers: getHeader()}).then(res => res.data)
}
export function GET_POINT(){
  return axios.get(APIS.API_POINT, {headers: getHeader()}).then(res => res.data)
}

export function GET_WISHLIST(){
  return axios.get(APIS.API_WISHLIST, {headers:getHeader()}).then(res => res.data)
}

export function GET_TRACE(payload)
{
  const { InvoiceNo } = payload 
  return axios.get(`${APIS.API_TRACE}?InvoiceNo=${InvoiceNo}`,  {headers:getHeader()}).then(res => res.data)
  // return axios.get(APIS.API_TRACE, {headers:getHeader()}).then(res => res.data)

}
export function REMOVE_WISHLIST(payload){
  const { VariantId } = payload
  return axios.delete(`${APIS.API_WISHLIST}?VariantId=${VariantId}`,  {headers:getHeader()}).then(res => res.data)
}
export function ADD_WISHLIST(payload){
  return axios.post(APIS.API_WISHLIST, payload, {headers:getHeader()}).then(res => res.data)
}
export function GET_NOTIF(){
  return axios.get(APIS.API_ORDER_NOTIF, {headers:getHeader()}).then(res => res.data)
}