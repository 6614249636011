import actions from './actions'

const initialState = { 
  skinanalisloading: false, 
  skinQuestion:[],
  pageSetting:'skintype',
  resultSkin:[],
  data:[]
}

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}