import React, { lazy, useEffect, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'
import Layout from './layouts'
import HomePage from './pages/Home'
import { BackTop } from 'antd'
import { UpOutlined } from '@ant-design/icons'
import './i18n'
const style = {
  height: 40,
  width: 40,
  lineHeight: '40px',
  borderRadius: 50,
  backgroundColor: 'rgba(1, 1, 1, 0.6)',
  color: '#fff',
  textAlign: 'center',
  fontSize: 14,
};

const routes = [
  {
    path: '/login',
    Component: lazy(() => import('pages/Auth/login')),
    exact: true,
    authed: false,
  },
  {
    path: '/register',
    Component: lazy(() => import('pages/Auth/register.js')),
    exact: true,
    authed: false,
  },
  {
    path: '/journey/:idJourney',
    Component: lazy(() => import('pages/Formulir/index.js')),
    exact: true,
    authed: false,
  },
  {
    path: '/form-ba',
    Component: lazy(() => import('pages/Formulir/formBA.js')),
    exact: true,
    authed: false,
  },
  {
    path: '/form-review',
    Component: lazy(() => import('pages/Formulir/formReview.js')),
    exact: true,
    authed: false,
  },
  {
    path: '/form-review-product',
    Component: lazy(() => import('pages/Formulir/formReviewProduct.js')),
    exact: true,
    authed: false,
  },
  {
    path: '/skincare_anti_jerawat',
    Component: lazy(() => import('pages/Adds/index.js')),
    exact: true,
    authed: false,
  },
  {
    path: '/eyecream',
    Component: lazy(() => import('pages/Adds/eyecream.js')),
    exact: true,
    authed: false,
  },
  {
    path: '/products',
    Component: lazy(() => import('pages/Products')),
    exact: true,
    authed: false,
  },
  {
    path: '/products/:ctgSlug',
    Component: lazy(() => import('pages/Products/category')),
    exact: true,
    authed: false,
  },
  {
    path: '/category/:ctgSlug',
    Component: lazy(() => import('pages/Products/category')),
    exact: true,
    authed: false,
  },
  {
    path: '/product-detail/:dtlSlug',
    Component: lazy(() => import('pages/Products/detail')),
    exact: true,
    authed: false,
  },
  {
    path: '/articles',
    Component: lazy(() => import('pages/Article')),
    exact: true,
    authed: false,
  },
  {
    path: '/article-detail/:artDtlSlug',
    Component: lazy(() => import('pages/Article/detail')),
    exact: true,
    authed: false,
  },
  {
    path: '/contact-us',
    Component: lazy(() => import('pages/Contact')),
    exact: true,
    authed: false,
  },
  {
    path: '/about-us',
    Component: lazy(() => import('pages/About')),
    exact: true,
    authed: false,
  },
  {
    path: '/size-guide',
    Component: lazy(() => import('pages/Guide')),
    exact: true,
    authed: false,
  },
  {
    path: '/cerita-dream',
    Component: lazy(() => import('pages/Cerita')),
    exact: true,
    authed: false,
  },

  {
    path: '/dreamers-say',
    Component: lazy(() => import('pages/Dreamer_say')),
    exact: true,
    authed: false,
  },
  {
    path: '/help/terms',
    Component: lazy(() => import('pages/Help/terms_condition')),
    exact: true,
    authed: false,
  },
  {
    path: '/help/faq',
    Component: lazy(() => import('pages/Help/faq')),
    exact: true,
    authed: false,
  },
  {
    path: '/help/send-refund',
    Component: lazy(() => import('pages/Help/send_refund.js')),
    exact: true,
    authed: false,
  },
  {
    path: '/dreamers-say/detail/:idCerita',
    Component: lazy(() => import('pages/Dreamer_say/detail')),
    exact: true,
    authed: false,
  },
  {
    path: '/magazine',
    Component: lazy(() => import('pages/Magazine')),
    exact: true,
    authed: false,
  },
  {
    path: '/keranjang',
    Component: lazy(() => import('pages/Cart')),
    exact: true,
    authed: false,
  },
  {
    path: '/checkout',
    Component: lazy(() => import('pages/Checkout')),
    exact: true,
    authed: true,
  },
  {
    path: '/transaksi/konfirmasi',
    Component: lazy(() => import('pages/Confirm')),
    exact: true,
    authed: true,
  },
  {
    path: '/account',
    Component: lazy(() => import('pages/Account/Account')),
    exact: true,
    authed: true,
  },
  {
    path: '/order',
    Component: lazy(() => import('pages/Account/Order')),
    exact: true,
    authed: true,
  },
  {
    path: '/transaksi/payment/invoice/:noInvoice',
    Component: lazy(() => import('pages/Payment')),
    exact: true,
    authed: true,
  },
  {
    path: '/skin-analys',
    Component: lazy(() => import('pages/Skin_Analys')),
    exact: true,
    authed: false,
  },
  {
    path: '/order-detail/:trnID',
    Component: lazy(() => import('pages/Account/Order/detail')),
    exact: true,
    authed: true,
  },
  {
    path: '/address',
    Component: lazy(() => import('pages/Account/Address')),
    exact: true,
    authed: true,
  },
  {
    path: '/list-address',
    Component: lazy(() => import('pages/Account/Address_list')),
    exact: true,
    authed: true,
  },
  {
    path: '/list-member',
    Component: lazy(() => import('pages/Account/Member')),
    exact: true,
    authed: true,
  },
  {
    path: '/exmples_pages',
    Component: lazy(() => import('pages/page_examples')),
    exact: true,
    authed: false,
  },
  {
    path: '/catalog',
    Component: lazy(() => import('pages/Magazine_catalog')),
    exact: true,
    authed: false,
  },
  {
    path: '/address/edit',
    Component: lazy(() => import('pages/Account/Address/form')),
    exact: true,
    authed: true,
  },
  {
    path: '/review',
    Component: lazy(() => import('pages/Account/Review')),
    exact: true,
    authed: true,
  },
  {
    path: '/coming-soon',
    Component: lazy(() => import('pages/Coming_soon')),
    exact: true,
    authed: false,
  },
  {
    path: '/wishlist',
    Component: lazy(() => import('pages/Account/Wishlist')),
    exact: true,
    authed: true,
  },
  {
    path: '/transaksi/shipping',
    Component: lazy(() => import('pages/Checkouts')),
    exact: true,
    authed: true,
  },

  {
    path: '/transaksi/delivery',
    Component: lazy(() => import('pages/Delivery')),
    exact: true,
    authed: true,
  },

  {
    path: '/transaksi/summary',
    Component: lazy(() => import('pages/Konfirmasi')),
    exact: true,
    authed: true,
  },
  {
    path: '/review/:ordDtlID',
    Component: lazy(() => import('pages/Account/Review/form')),
    exact: true,
    authed: true,
  }


]

const Router = ({ history }) => {
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn)
  const location = useSelector(({ router }) => router.location)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])


  return (
    <>
      <BrowserRouter history={history}>
        <Layout>
          <Route
            render={state => {
              const { location } = state
              return (
                <Switch location={location}>
                  <Route exact path="/" component={HomePage} />
                  {routes.map(({ path, Component, exact, authed }) => (
                    <Route
                      path={path}
                      key={path}
                      exact={exact}
                      render={() => {
                        if (authed) {
                          if (isLoggedIn) {
                            return (
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            )
                          } else {
                            return <Redirect to={{ pathname: '/login' }} />
                          }
                        } else {
                          return (
                            <Suspense fallback={null}>
                              <Component />
                            </Suspense>
                          )
                        }
                        // return (
                        //   <Suspense fallback={null}>
                        //     <Component />
                        //   </Suspense>
                        // )
                      }}
                    />
                  ))}
                </Switch>
              )
            }}
          />
        </Layout>
      </BrowserRouter>

      {/* BACK TO TOP */}
      <BackTop visibilityHeight={10} >
        <div style={style}>
          <UpOutlined />
        </div>
      </BackTop>
    </>
  );
}

export default Router;