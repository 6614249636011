import React, { useEffect } from 'react';
import { Container } from 'components/grid';
import Variables from 'utils/variables';
import { Col, Row, Badge } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { currencyFormat } from 'utils/helpers';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

const HomeProductsComponent = () => {


   
  const productData = useSelector(({ home }) => home.productData)  
  const productLoading = useSelector(({ home }) => home.productLoading)  
  const { t } = useTranslation() 
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({
      type:'home/PRODUCT'
    })
  }, [ ])
  return (
    <> 
        <div className="bolde-home-prd">
          <div className="bolde-home-prd--wrapper">
            <Container> 
            {
              productLoading &&
              <>
                <div className="mb-5 text-center">
                    <Skeleton  height={40} width={140} className="mr-3 mb-2 mt-2" /> 
                    <Skeleton  height={40} width={140} className="ml-3 mb-2 mt-2" /> 
                </div> 
                <Row gutter={[40, 80]} justify="space-between">
                    <Col xs={{ span: 8}} sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }}> 
                        <Skeleton  height={340} width={'100%'} className="mr-3 mb-2 mt-2" />
                    </Col>
                    <Col xs={{ span: 8}} sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }}> 
                      <Skeleton  height={340}width={'100%'} className="mr-3 ml-3 mb-2 mt-2" />
                    </Col>
                    <Col xs={{ span: 8}} sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }}>
                      <Skeleton  height={340}width={'100%'} className="ml-3 mb-2 mt-2" /> 
                    </Col> 
                </Row>  
              </>  
            }  
            {
              !productLoading &&
              <>
                <h1 className=" text-center mb-5 bolde-font-normal_bebas_regular color-standar">
                {t('Home_title_product')}
              </h1> 
              <Row gutter={[40, 80]} justify="space-between">
                {
                  productData.slice(0, 3).map((x, y) => {
                    return (
                      <Col key={y} xs={{ span: 8}} sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }}>
                        <div className="bolde-product text-center">
                          {
                             ( x.variant.length > 0 && x.variant[0].isdiskon ) &&
                            <div class="discount-label"> <span> {x.variant[0].DiscountPercent} %</span> </div>
                          } 
                          <a href={`/product-detail/${x.Slug}`}>
                          <div className="bolde-product--img">
                            {
                              (Array.isArray(x.product_images) && x.product_images.length > 0) &&
                              <img src={`${Variables.BASE_IMG_PRODUCT}/${x.product_images[0].Image}`} className="img-fluid" alt="Dream" />
                            }
                            <div className="bolde-product--fly -btn-a">
                            <Link to={`/product-detail/${x.Slug}`}>
                              {t('Button_Shop')}
                              </Link> 
                            </div>
                          </div>
                          <Link to={`/product-detail/${x.Slug}`}>
                            <h2 className="mt-3 mb-2 bolde-font__lg py-1 color-standar">{x.ProductName}</h2>
                          </Link> 
                          {
                                (x.variant.length > 0 && x.variant[0].isdiskon ) &&
                            <> 
                              <div className="bolde-product--small-desc ">
                                <span className="bolde-product--small-desc__coret color-standar"  > Rp {currencyFormat(x.variant[0].Price)} </span>
                              {/* <span className="bolde-font__lg px-2 bolde-font__semiBold">
                                          <Badge
                                            className="site-badge-count-109"
                                            count={ `${x.variant[0].DiscountPercent}%` + `  ${x.variant[0].DiscountLabel}` }
                                            style={{ backgroundColor: '#dc3545', fontSize:'11px !important' }}
                                          />
                                          </span> */}
                              </div>
                          
                              <div className="bolde-product--small-desc bolde-font-custom__regular mt-2  disc-font mb-2" style={{color:'rgb(124, 119, 119)'}}> Rp  {currencyFormat(x.variant[0].DiscountEndPrice)}    
                                </div>
                            </>
                          }
                          { 
                             (x.variant.length > 0 &&  !x.variant[0].isdiskon ) &&
                            <>  
                              <div className="bolde-product--small-desc  bolde-font-custom__regular color-standar mb-2" style={{color:'rgb(124, 119, 119)'}}>Rp  {currencyFormat(x.variant[0].Price)}</div>
                            </>
                          } 
                          </a>
                        </div> 
                      </Col> 
                    )
                  })
                }   
              </Row>  
              <div className="mt-5 text-center"> 
                <Link  className="bolde-btn__float " to={'/products'}>
                 {t('Button_SeeALL')}
                </Link>  
              </div>  
                </> 

            }
             
            </Container>
          </div>
        </div>
 
     

    </>
  );
}

export default HomeProductsComponent;