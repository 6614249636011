import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import Variables from 'utils/variables'; 
import { useTranslation } from 'react-i18next'; 
import { Container } from 'components/grid'; 

import HTMLFlipBook from "react-pageflip";  
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"; 
import Slider from 'react-slick';
import { height } from 'dom7';


const settings = {
  dots: false,
  infinite: true,
  speed: 700,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  className: "center",
  centerMode: true,

  fade: true,
  centerPadding: "0px",
 
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
      }
    }
  ],
  nav: true,
};

function Arrow(props) {
  let className = props.type === "next" ? "nextArrow arrowiconNext" : "prevArrow arrowiconPrev";
  className += " arrow arrowicon";
  const char = props.type === "next" ? <BsChevronRight /> : <BsChevronLeft />; 
  return (
    <span className={className} onClick={props.onClick}>
      {char}
    </span>
  );
}

function customPaging(i) {
  return <span>{i + 1}</span>;
}

function appendDots(dots) {
  return (
    <div style={{ backgroundColor: "#eee" }}>
      <ul style={{ margin: "3px" }}> {dots} </ul>
    </div>
  );
}

const HomeArticleComponent = () => {
  const dispatch = useDispatch()
 
  const catalogLoading = useSelector(({ home }) => home.catalogLoading)
  const catalogData = useSelector(({ home }) => home.catalogData)
  
  const isMobileView = useSelector(({ settings }) => settings.isMobileView)
  const articleData = useSelector(({ home }) => home.articleData) 
  const widthdata = isMobileView ? '200': '400'; 
  const heightdata = isMobileView ? '300': '550'; 

  const children = articleData.concat(articleData); 
  const array = children.sort(function(a, b) {
    return b.ArticleId - a.ArticleId
    })
 
const logo = Variables.BASE_IMG_LOCAL + '/logo-dream.png';
const { t } = useTranslation() 
  useEffect(() => {
    dispatch({
      type: 'home/CATALOG'
    })
  }, [])

  return (
    <>
 
      <div className="bolde-home-article mt-5  "> 
        <Container>  
        {
          !isMobileView &&
           <>
          <div className="div-catalog" >  
               <HTMLFlipBook   
                width={widthdata} 
                maxShadowOpacity={0.5} 
                height={heightdata}  
                style={{ boxShadow:"0px 0px 5px 0px"}}  
                > 
                    {/* <div className="demoPage"> 
                        <div  style={{height:'100%', width:"100%", background:'#f1dae9', background:'linear-gradient(90deg, rgba(201,194,194,1) 0%, rgba(241,218,233,1) 9%)'}}>
                        <img  className="img-fluid "  style={{width:"100%", height:"100%", }} src={Variables.BASE_IMG_LOCAL + '/example/catalog1.jpg'}/ >  
                        <div className="layer-catalog-kiri"></div>
                        </div> 
                    </div>  */}
{/*                 
                    <div className="demoPage"> 
                        <div  style={{height:'100%', width:"100%", background:'#f1dae9', background:'linear-gradient(90deg, rgba(201,194,194,1) 0%, rgba(241,218,233,1) 9%)'}}>
                        <img  className="img-fluid "  style={{width:"100%", height:"100%", }} src={Variables.BASE_IMG_LOCAL + '/example/catalog2.jpg'}/ >  
                        <div className="layer-catalog-kanan"></div>
                        </div> 
                    </div>   */}
                    <div className="demoPage"> 
                        <div  style={{height:'100%', width:"100%", background:'#f1dae9', background:'linear-gradient(90deg, rgba(201,194,194,1) 0%, rgba(241,218,233,1) 9%)'}}>
                        <img  className="img-fluid "  style={{width:"100%", height:"100%", }} src={Variables.BASE_IMG_LOCAL + '/example/catalog2.jpg'}/ >  
                        <div className="layer-catalog-kiri"></div>
                        </div> 
                    </div>  
                    { 
                        catalogData.map((x, y) => {
                          return (  
                            <div className="demoPage"> 
                                 {
                                          y % 2 === 0 &&    
                                          <>
                                            <div style={{height:'100%', width:"100%", background:'#f1dae9' }}>
                                              <img  className="img-fluid "  style={{width:"100%", height:"100%"}} src={`${Variables.BASE_IMG_CATALOG}/${x.Image}`} />   
                                              <div className="layer-catalog-kanan"></div>
                                            </div>
                                          </>
                                  }
                                  { 
                                          y % 2 !== 0 &&
                                          <>
                                   
                                            <div style={{height:'100%', width:"100%", background:'#f1dae9' }}>
                                              <img  className="img-fluid "  style={{width:"100%", height:"100%"}} src={`${Variables.BASE_IMG_CATALOG}/${x.Image}`} />  
                                              <div className="layer-catalog-kiri"></div>
                                            </div>
                                          </> 
                                  } 
                                </div>   
                          )})
                   } 
              </HTMLFlipBook>   
               </div> 
               <div className="py-5"></div>
            </>
          }
        </Container>
      </div>
      

    </>

  )
}

export default HomeArticleComponent;