import React, { useEffect, useState } from 'react';
import { Col, Modal, Radio,  Row, Form, Input, Select, Steps, Button, Rate, Space, Checkbox, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CartInfoComponent, CartComponent } from './cart';
import AddressComponent from './address';
import { SummaryComponent, SummaryTransactionComponent } from './summary';
import { OrderItemComponent } from './order'; 
import { LoadingOutlined } from '@ant-design/icons';
import { HistoryStatus, SttsLabel, imgStts } from 'utils/helpers';
import Variables from 'utils/variables';
import { useTranslation } from 'react-i18next';
   
import { currencyFormat } from 'utils/helpers'; 
import moment from 'moment';

const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Option } = Select
const modalTop = '20px'


export const ModalSearchComponent = () => {
  const dispatch = useDispatch()

  const showModalSearch = useSelector(({ settings }) => settings.showModalSearch)

  const handleClose = () => {
    dispatch({
      type: 'settings/HANDLE_STATE',
      field: 'showModalSearch',
      value: false
    })
  }

  function renderTitle() {
    return (
      <>
        <input
          placeholder="Cari nama produk..."
        // onChange={handleChange}
        // value={keyword}
        />
      </>
    )
  }

  return (
    <>
      <Modal
        title={renderTitle()}
        visible={showModalSearch}
        onOk={handleClose}
        onCancel={handleClose}
        width={1000}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
        footer={null}
        maskClosable={false}
        className="bolde-modal"
      >
        <div className="bolde-modal--wrapper">
          <div className="bolde-modal--items bolde-modal--items__no-border">
            <p>Coba 'Bedcover'</p>
          </div>
        </div>
      </Modal>
    </>
  );
}

export const ModalCartComponent = () => {
  const dispatch = useDispatch()

  const isMobileView = useSelector(({ settings }) => settings.isMobileView)
  const showModalCart = useSelector(({ settings }) => settings.showModalCart)

  const handleClose = () => {
    dispatch({
      type: 'settings/HANDLE_STATE',
      field: 'showModalCart',
      value: false
    })
  }

  function renderTitle() {
    return <h3 className="bolde-font--avenir-light bolde-font--uppercase bolde-font--center ">Keranjang Belanja</h3>
  }

  return (
    <>
      <Modal
        title={renderTitle()}
        visible={showModalCart}
        onCancel={handleClose}
        style={{ top: isMobileView ? modalTop : '100px' }}
        okText="Checkout"
        cancelText="Lanjutkan Belanja"
        width={1440}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
        maskClosable={false}
      >
        <Row gutter={[30, 30]}>
          <Col span={18}>
            <CartComponent />
          </Col>
          <Col span={6}>
            <h3>
              <strong>RINGKASAN PESANAN</strong>
            </h3>
          </Col>
        </Row>
      </Modal>

    </>
  );
}

export const ModalAddressComponent = () => {
  const dispatch = useDispatch()

  const showModalAddress = useSelector(({ settings }) => settings.showModalAddress)
  const isMobileView = useSelector(({ settings }) => settings.isMobileView) 
  const handleClose = () => {
    dispatch({
      type: 'settings/HANDLE_STATE',
      field: 'showModalAddress',
      value: false
    })
  }

  function renderTitle() {
    return <h3 className="bolde-font--avenir-light bolde-font--uppercase bolde-font--center ">Pilih Alamat Lain</h3>
  }

  return (
    <>
      <Modal
        title={renderTitle()}
        visible={showModalAddress}
        onCancel={handleClose}
        okText="Pilih Alamat"
        cancelText="Batal"
        width={720}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
        maskClosable={false}
        style={{ top: isMobileView ? modalTop : '100px' }}
      >
        <Row gutter={[30, 30]}>
          <Col span={24}>
            <Radio.Group>
              <Radio value={1}>
                <AddressComponent />
              </Radio>
              <Radio value={2}>
                <AddressComponent />
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
      </Modal>

    </>
  );
}

export const ModalConfirmComponent = () => {
  const dispatch = useDispatch()

  const isMobileView = useSelector(({ settings }) => settings.isMobileView)
  const showModalConfirm = useSelector(({ settings }) => settings.showModalConfirm)

  const handleClose = () => {
    dispatch({
      type: 'settings/HANDLE_STATE',
      field: 'showModalConfirm',
      value: false
    })
  }

  function renderTitle() {
    return <h3 className="bolde-font--avenir-light bolde-font--uppercase bolde-font--center ">Konfirmasi Pesanan</h3>
  }

  return (
    <>
      <Modal
        title={renderTitle()}
        visible={showModalConfirm}
        onCancel={handleClose}
        okText="Bayar"
        cancelText="Batal"
        width={1200}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
        maskClosable={false}
        style={{ top: isMobileView ? modalTop : '100px' }}
      >
        <Row gutter={[30, 30]}>
          <Col xs={24} lg={14}>
            <CartInfoComponent />
          </Col>
          <Col xs={24} lg={10}>
            <h3 className="bolde-font--uppercase">
              Alamat Pengiriman
            </h3>
            <AddressComponent noBorder />
            <SummaryComponent />
          </Col>
        </Row>
      </Modal>

    </>
  );
}

export const ModalTransactionComponent = () => {
  const dispatch = useDispatch()

  const isMobileView = useSelector(({ settings }) => settings.isMobileView)
  const showModalTransaction = useSelector(({ settings }) => settings.showModalTransaction)
  

  const handleClose = () => {
    dispatch({
      type: 'settings/HANDLE_STATE',
      field: 'showModalTransaction',
      value: false
    })
  }

  function renderTitle() {
    return <h3 className="bolde-font--avenir-light bolde-font--uppercase bolde-font--center ">Detail Transaksi</h3>
  }

  return (
    <>
      <Modal
        title={renderTitle()}
        visible={showModalTransaction}
        onCancel={handleClose}
        okText="Bayar"
        cancelText="Tutup"
        width={700}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
        maskClosable={false}
        style={{ top: isMobileView ? modalTop : '100px' }}
      >
        <Row gutter={[30, 30]}>
          <Col span={24}>
            <OrderItemComponent otherItem={false} />
          </Col>
          <Col span={24}>
            <h3 className="bolde-font--uppercase">
              Alamat Pengiriman
            </h3>
            <AddressComponent noBorder />
            <SummaryTransactionComponent />
          </Col>
        </Row>
      </Modal>

    </>
  );
}

export const ModalFormMemberComponent = ({isEdit = false}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm();
  
  const isMobileView = useSelector(({ settings }) => settings.isMobileView)
  const showModalFormMember =  useSelector(({settings}) => settings.showModalFormMember)
  const [nama , setNama] = useState(null)
  const [email, setEmail] = useState(null)
  const [noHandphone, setNoHandphone] = useState(null)

  const memberSelected = useSelector(({reseller}) => reseller.memberSelected )

  const title = isEdit ? "Ubah Member" : "Tambah Member";

  const handleClose = () =>{
    dispatch({
      type: 'settings/HANDLE_STATE',
      field: 'showModalFormMember',
      value: false
    })  
    dispatch({
      type: 'reseller/HANDLE_STATE',
      field: 'memberSelected',
      value: {  
          memberId:'',
          nama: '',
          email: '',
          noHandphone:'',
      } 
  
    })  

  }
  useEffect(() => {
    form.setFieldsValue({
      nama: memberSelected.nama ? memberSelected.nama :'',
      email: memberSelected.email ? memberSelected.email :'',
      noHandphone: memberSelected.noHandphone ? memberSelected.noHandphone : '',

    }) 
  }, [memberSelected]);



  const onFinish = async (values) => { 
    const data =  {
                    id:memberSelected.memberId ? memberSelected.memberId :'',
                    Name: values.nama,
                    Email: values.email,
                    PhoneNumber:values.noHandphone,
                  }
    if(isEdit === false)
    {
      dispatch({
        type:'reseller/ADD_MEMBER', 
        payload:data
      })

    }else{

      dispatch({
        type:'reseller/UPDATE_MEMBER', 
        payload:data
      })

    } 
  }


  function renderTitle() {
    return <h3 className="bolde-font--avenir-light bolde-font--uppercase bolde-font--center ">{title}</h3>
  }

  return (
    <>
      <Modal
        title={renderTitle()}
        visible={showModalFormMember}
        onCancel={handleClose}
        okText="Simpan" 
        onOk={form.submit}
        cancelText="Batal"
        width={600}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
        maskClosable={false}
        style={{ top: isMobileView ? modalTop : '100px' }}
      >
        <Form
          name="formMember" 
          form={form}
          layout="vertical"
          requiredMark={false} 
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed} 
        >
          <Row gutter={[15, 15]}>
            <Col xs={24} md={24}>
              {/* <h3
                style={{ marginBottom: '15px' }}
              >
                PENERIMA
              </h3> */}
              <Form.Item
                name="nama"   
                rules={[{ required: true, message: 'Nama tidak boleh kosong!' }]}
              >
                <Input size="large"  type="text"  onChange={(e) => setNama(e.target.value)}     placeholder="Nama"  />
              </Form.Item>
              <Form.Item
                name="email"   
                rules={[{ required: true, message: 'Email tidak boleh kosong!' }]}
              >
                <Input size="large"  type="email"  onChange={(e) => setEmail(e.target.value)}     placeholder="Email"  />
              </Form.Item>

            
              <Form.Item
                name="noHandphone"
                rules={[{ required: true, message: 'No Telepon tidak boleh kosong!' }]}
              >
                <Input size="large"    onChange={(e) => setNoHandphone(e.target.value)}    type="text"    placeholder="No Telepon" />
              </Form.Item>
 

            </Col> 
          </Row>
        </Form>
      </Modal>
    </>
  )


}

export const ModalFormAddressComponent = ({ isEdit = false }) => {
  const dispatch = useDispatch()
  const isMobileView = useSelector(({ settings }) => settings.isMobileView)

  const [form] = Form.useForm();
  const provinces = useSelector(({ settings }) => settings.provinces) 
  const cities = useSelector(({ cart }) => cart.cities) 
  const subdistricts = useSelector(({ cart }) => cart.subdistricts)
  const dataaddress = useSelector(({account}) => account.profileAddressSelected)
  const loading = useSelector(({account}) => account.loading)

  // const [email, setEmail] = useState(null)
  const [namaDepan, setNamaDepan] = useState(null)
  const [namaBelakang, setNamaBelakang] = useState(null)
  const [perusahaan, setPerusahaan] = useState(null)
  const [alamat, setAlamat] = useState(null)
  const [jenisAlamat, setJenisAlamat] = useState(null)
  const [kecamatan, setKecamatan] = useState(null)
  const [kodePos, setKodePos] = useState(null)
  const [noHandphone, setNoHandphone] = useState(null)
 
  const {t} = useTranslation()


  useEffect(() => {

    
    form.setFieldsValue({
      // email: dataaddress.email,
      namaDepan: dataaddress.namaDepan ? dataaddress.namaDepan : '',
      namaBelakang: dataaddress.namaBelakang ? dataaddress.namaBelakang : '',
      perusahaan: dataaddress.perusahaan ? dataaddress.perusahaan : '',
      provinsi: dataaddress.provinceID ? dataaddress.provinceID: null,
      city: dataaddress.cityID ? dataaddress.cityID : null,
      subdistrict: dataaddress.subdistrictID ? dataaddress.subdistrictID : null,
      alamat: dataaddress.alamat ? dataaddress.alamat : '',
      jenisAlamat: dataaddress.jenisAlamat ? dataaddress.jenisAlamat : '',
      kodePos: dataaddress.kodePos ? dataaddress.kodePos : '',
      noHandphone: dataaddress.noHandphone ? dataaddress.noHandphone : '',
    })
  },[dataaddress])

  const title = isEdit ? t('HeaderModalAddresEdit') : t('HeaderModalAddresAdd')

  const handleClose = () => {
    dispatch({
      type: 'settings/HANDLE_STATE',
      field: 'showModalFormAddress',
      value: false
    }) 
    dispatch({
      type: 'account/HANDLE_STATE',
      field: 'profileAddressSelected',
      value: {
            customerAddressId:'',
            customerId:'',
            namaDepan:'',
            namaBelakang:'',
            perusahaan:'',
            alamat:'',
            jenisAlamat:'',
            kecamatan:'',
            kodePos:'',
            noHandphone:'',
            provinceID:'',
            provinceName:'',
            cityID:'',
            cityType:'',
            cityName:'',
            subdistrictID:'',
            subdistrictName:'',
            postCode:'',
      },
    }) 
      form.resetFields(); 

  } 

 


  const onFinish = async (values) => {
    const data =  {
                    CustomerAddressId: dataaddress.customerAddressId,
                    NamaDepan: values.namaDepan ? values.namaDepan : '',
                    NamaBelakang: values.namaBelakang ? values.namaBelakang : '',
                    Perusahaan: values.perusahaan ? values.perusahaan : '',
                    Alamat: values.alamat ? values.alamat : '',
                    JenisAlamat: values.jenisAlamat ? values.jenisAlamat : '',
                    Kecamatan: values.subdistrict ? values.subdistrict : '',
                    KodePos: values.kodePos ? values.kodePos : '',
                    NoHandphone: values.noHandphone ? values.noHandphone : '' 
                  }
    if(isEdit === false)
    {
      dispatch({
        type: 'account/ADD_ADDRESS', 
        payload:data
      })

    }else{

      dispatch({
        type: 'account/UPDATE_ADDRESS', 
        payload:data
      })

    }
   

    form.resetFields(); 

  }



  function handleProvinceChange(provinceID) {
    dispatch({
      type: 'cart/GET_CITY',
      payload: {
        province_id: provinceID
      }
    })
    form.setFieldsValue({
      city: null,
      subdistrict: null
    })
 
  }

  function handleCityChange(cityID) {
    dispatch({
      type: 'cart/GET_SUBDISTRICT',
      payload: {
        city_id: cityID
      }
    })
    form.setFieldsValue({
      subdistrict: null
    }) 
  }
  async function handleDsitrictChange(distID) {
    await dispatch({
      type: 'cart/HANDLE_STATE',
      field: 'shipment',
      value: []
    })

  
    setKecamatan(distID) 
  }
 
  useEffect(() => { 
      dispatch({
        type: 'cart/GET_CITY',
        payload: {
          province_id: dataaddress.provinceID
        }
      })
      dispatch({
        type: 'cart/GET_SUBDISTRICT',
        payload: {
          city_id: dataaddress.cityID
        }
      })
 
  }, [dataaddress])
  const showModalFormAddress = useSelector(({ settings }) => settings.showModalFormAddress)
  function renderTitle() {
    return <h3 className="bolde-font--avenir-light bolde-font--uppercase bolde-font--center ">{title}</h3>
  }

  return (
    <>
      <Modal
        title={renderTitle()}
        visible={showModalFormAddress}
        onCancel={handleClose}
        okText={t('ButtonSave')}
        // onOk={onFinish}
        onOk={form.submit}
        cancelText={t('ButtonCancel')}
        width={800}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
        maskClosable={false}
        style={{ top: isMobileView ? modalTop : '100px' }}
      >
        
      <Spin indicator={spinIcon} spinning={loading}>
        <Form
          name="formAddress"
          
          form={form}
          layout="vertical"
          requiredMark={false} 
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed} 
        >
          <Row gutter={[15, 15]}>
            <Col xs={24} md={12}>
              <h3
                style={{ marginBottom: '15px' }}
              >
                {t('HeaderModalAddresLabel1')}
              </h3>
              <Form.Item
                name="namaDepan"   
                rules={[{ required: true, message: 'Nama Depan tidak boleh kosong!' }]}
              >
                <Input size="large"  type="text"  onChange={(e) => setNamaDepan(e.target.value)}     placeholder={t('PlaceholderAddress1')} />
              </Form.Item>
              <Form.Item
                name="namaBelakang"   
                rules={[{ required: true, message: 'Nama Belakang tidak boleh kosong!' }]}
              >
                <Input size="large"  type="text"  onChange={(e) => setNamaDepan(e.target.value)}      placeholder={t('PlaceholderAddress2')} />
              </Form.Item>

               {/* <Form.Item
                name="email"
                rules={[{ required: true, message: 'Email tidak boleh kosong!' }]}
              > 
                <Input size="large" onChange={(e) => setEmail(e.target.value)}  type="email"    placeholder="Email" />
              </Form.Item> */}

              <Form.Item
                name="noHandphone"
                rules={[{ required: true, message: 'No Telepon tidak boleh kosong!' }]}
              >
                <Input size="large"    onChange={(e) => setNoHandphone(e.target.value)}    type="text"      placeholder={t('PlaceholderAddress3')} />
              </Form.Item>

              <Form.Item
                name="perusahaan"
              >
                <Input size="large"  type="text"  onChange={(e) => setPerusahaan(e.target.value)}      placeholder={t('PlaceholderAddress4')} />
              </Form.Item>

            </Col>
            <Col xs={24} md={12}>
              <h3
                style={{ marginBottom: '15px' }}
              >
                 {t('HeaderModalAddresLabel2')}
              </h3>
              <Form.Item
                name="provinsi"
                rules={[{ required: true, message: 'Provinsi harus diisi' }]}
              >
                <Select
                 
                 style={{ width: '100%' }}
                 placeholder={t('PlaceholderAddress5')}
                  size="large"
                  
                  onChange={e => handleProvinceChange(e)}
                >
                   { 
                            provinces.map((x, y) => <Option key={y} value={x.province_id}>{x.province}</Option>)
                   }
                </Select>
              </Form.Item>

              <Form.Item
                name="city"
                rules={[{ required: true, message: 'Kabupaten/Kota harus diisi' }]}
              >
                <Select
                 
                 style={{ width: '100%' }}
                 placeholder={t('PlaceholderAddress6')}
                  size="large" 
                  onChange={e => handleCityChange(e)}
                >
                <Option selected disabled> {t('PlaceholderAddress6')}</Option>
                {
                  cities.map((x, y) => <Option key={y} value={x.city_id}>{x.type} {x.city_name}</Option>)
                }
                </Select>
              </Form.Item>

              <Form.Item
                name="subdistrict"
                 
              >
                <Select
                 
                 style={{ width: '100%' }}
                 placeholder={t('PlaceholderAddress7')}
                  rules={[{ required: true, message: 'Kecamatan harus diisi' }]}
                  size="large" 
                  onChange={e => handleDsitrictChange(e)}
                >
                   <Option selected disabled>{t('PlaceholderAddress7')}</Option>
                          {
                            subdistricts.map((x, y) => <Option key={y} value={x.subdistrict_id}>{x.subdistrict_name}</Option>)
                          }
                </Select>
              </Form.Item>

              <Form.Item
                name="alamat"
                rules={[{ required: true, message: 'Alamat tidak boleh kosong!' }]}
              >
                <Input  type="text"   size="large"   placeholder={t('PlaceholderAddress8')}/>
              </Form.Item>

              <Form.Item
                name="kodePos"
                rules={[{ required: true, message: 'Kode Pos tidak boleh kosong!' }]}
              >
                <Input    type="text" size="large"     placeholder={t('PlaceholderAddress9')} />
              </Form.Item>

              <Form.Item
                name="jenisAlamat"
              >
                <Input   type="text" size="large"   placeholder={t('PlaceholderAddress10')} />
              </Form.Item>

            </Col>
          </Row>
        </Form>
        </Spin>
      </Modal>
    </>
  )
}


export const ModalBerlangganan = () =>{ 
  const dispatch = useDispatch() 
  const isMobileView = useSelector(({ settings }) => settings.isMobileView)
  const showModalSubs = useSelector(({ settings }) => settings.showModalSubs) 
  const loading2 = useSelector(({ contact }) => contact.loading)
  const [form] = Form.useForm();
  
  const { t } = useTranslation()
  const handleClose = () => { 
  const date = new Date(new Date().getTime());
  date.setHours(0, 0, 0, 0); 
  localStorage.setItem('showModalSubs', false)
  localStorage.setItem('showModalSubsClose', date)
    dispatch({
      type: 'settings/HANDLE_STATE',
      field: 'showModalSubs',
      value: false
    })
  } 
  const onFinished = async (values) => {
   if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(values.email)) {
          await dispatch({
            type:'contact/SEND_MESSAGE',
            payload:{
              "Email":values.email,
            }

        })
        form.setFieldsValue({
          email:'',
        })
   }else{


   }
   
    
  }
 
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const onChange = (e) => {
    const newValue = e.target.value;
  
    if (!newValue.match(/[%<>\\$'"]/)) {
      setError("");
    } else {
      setError("Unforbidden character: %<>$'\"");
    }
    setName(newValue);
  };
  

  const onKeyPress = (e: React.KeyboardEvent) => {
    const specialCharRegex = new RegExp("[a-zA-Z0-9@._]");
    const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (!specialCharRegex.test(pressedKey)) {
       e.preventDefault();
       return false;
    }
 }

  return (
    <>
    
    <Spin indicator={spinIcon} spinning={loading2}> 
      <Modal 
        visible={showModalSubs}
        onCancel={handleClose} 
        width={700}
        centered
        maskStyle={{ backgroundColor:  'rgba(0,0,0,.0001) !important' }}
        maskClosable={false} 
        footer={null}
        className="modal-berlangganan"
        style={{   
          top: isMobileView ? modalTop :'0px',
          bottom:'0px'
        }}  >  
        
                    <div className='img-cover'>  
                      <img className='img-promotion' src={Variables.BASE_IMG_LOCAL + '/popup/pop_up_website.jpg'}  width="100%" alt="Dream" />
                    </div> 
                  <div className="berlangganan-form">
                          <Form 
                            className="text-center"
                            name="subscribe"
                            layout="horizontal"
                            requiredMark={false}  
                            initialValues={{ remember: true }}
                            onFinish={onFinished}
                            form={form}
                            autoComplete="off"
                            style={{paddingLeft:'30px', paddingRight:'30px'}}
                          > 
                            <Form.Item  
                              label={false}
                              name="email"   
                              rules={[{ 
                                required: true, 
                                type: "email",
                                message: "Format email xxxxxxxx@xxxx.xxx"
                               },
                                // {
                                //   pattern: new RegExp(/^[A-Za-z0-9@._" "]+$/ ), 
                                //   message: 'Hanya huruf, angka dan spesial karakter yang ditentukan (., _ )'
                                // } 
                            
                              ]}
                            > 
                              <Input
                               size="medium" 
                               
                                placeholder={'example@email.com'}
                                onKeyPress={e => onKeyPress(e)}
                                value={name}
                                onChange={onChange}
                                helperText={error} // error message
                                error={!!error} // set 
                                /> 
                            </Form.Item> 
                            <Form.Item>
                              <button htmlType="submit" className="bolde-btn__float px-3 py-1 text-justify" style={{marginTop:"-9px"}}>Berlangganan</button>
                            </Form.Item> 
                          </Form>   
                        </div>  
        </Modal>
      </Spin> 
    </>
  )


}
export const ModalFormReviewComponent = () => {
  const dispatch = useDispatch()

  const isMobileView = useSelector(({ settings }) => settings.isMobileView)
  const showModalFormReview = useSelector(({ settings }) => settings.showModalFormReview)

  const [rateValue, setRateValue] = useState(5)

  const rating = ['kecewa sekali', 'tidak sesuai harapan', 'biasa saja', 'oke juga', 'sangat memuaskan']

  const handleClose = () => {
    dispatch({
      type: 'settings/HANDLE_STATE',
      field: 'showModalFormReview',
      value: false
    })
  }

  const handleRateChange = (value) => {
    setRateValue(value)
  }

  

  function renderTitle() {
    return <h3 className="bolde-font--avenir-light bolde-font--uppercase bolde-font--center ">Beri Ulasan</h3>
  }

  return (
    <>
      <Modal
        title={renderTitle()}
        visible={showModalFormReview}
        onCancel={handleClose}
        okText="Simpan"
        cancelText="Batal"
        width={800}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
        maskClosable={false}
        style={{ top: isMobileView ? modalTop : '100px' }}
      >
        <Form
          name="formAddress"
          layout="vertical"
          requiredMark={false} 
          autoComplete="off"
        >
          <div style={{ marginBottom: '15px' }}>
            Beri kami ulasan tentang pembelian Bolde Home Anda! <br />
            Umpan balik Anda sangat penting bagi kami.
          </div>
          <Form.Item
            label="Penilaian"
          >
            <Rate count={5} value={rateValue} onChange={handleRateChange} tooltips={rating} /> 
            <span style={{ marginLeft: '15px'}}>{rateValue} Bintang</span>
          </Form.Item>

          <Form.Item
            label="Nama"
          >
            <Input size="large" placeholder="Nama" value={`Syarif Syabana`} disabled />
            <Checkbox>Tampilkan Sebagai Anonim</Checkbox>
          </Form.Item>


          <Form.Item
            label="Judul"
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="Ulasan"
          >
            <Input.TextArea size="large" />
          </Form.Item>

        </Form>
      </Modal>
    </>
  )
}


export const ModalVoucher = () => {
  const dispatch = useDispatch()

  const showModalVoucher = useSelector(({ cart }) => cart.showModalVoucher)
  const voucher = useSelector(({ cart }) => cart.voucher)
  const voucherOngkir = useSelector(({ cart }) => cart.voucherOngkir)  
  const isMobileView = useSelector(({ settings }) => settings.isMobileView) 
  const [potonganOngkir, setPotonganOngkir] = useState(null)
  const [promo, setPromo] = useState(null) 
  const total = localStorage.getItem('total') 
  const [VoucherDiskon, setVoucherDiskon] = useState(0)
  const handleClose = () => {
    dispatch({
      type: 'cart/HANDLE_STATE',
      field: 'showModalVoucher',
      value: false
    })
  }
  useEffect(() => {
    dispatch({
      type:'cart/GET_VOUCHER'
    }) 
  }, []); 

  const handleChangeOngkir = (event) => {
    setPotonganOngkir(event.target.value) 
   };
  const handleChangePromo = (event) => {
    setPromo(event.target.value)
   }; 

   const  UseVoucher = () =>{  
        const dataPromo = voucher.filter(x => x.VoucherId === promo)
        const dataOngkir = voucherOngkir.filter(x => x.VoucherOngkirId === potonganOngkir)  
         
 


        localStorage.setItem('ongkirVoucher', potonganOngkir)
        localStorage.setItem('promoVoucher', promo)
        localStorage.setItem('ongkirVoucherAmount', dataOngkir[0] ? dataOngkir[0].MaksimalPotongan : 0)
        localStorage.setItem('promoVoucherAmount',dataPromo[0] ?  dataPromo[0].MaksimalPotongan : 0) 
        localStorage.setItem('ongkirVoucherName',dataOngkir[0] ? dataOngkir[0].Nama : '')
        localStorage.setItem('promoVoucherName', dataPromo[0] ? dataPromo[0].Nama : '')
 
        dispatch({ 
          type: 'cart/SET_STATE',
          payload: {
            voucherSelected: { 
                    VoucherID:  dataPromo[0] ? dataPromo[0].VoucherId : '',
                    VoucherNama:  dataPromo[0] ? dataPromo[0].Nama : '',
                    VoucherRingkasan:  dataPromo[0] ? dataPromo[0].Ringkasan : '',
                    VoucherKeterangan:  dataPromo[0] ? dataPromo[0].Keterangan : '',
                    VoucherKuota:  dataPromo[0] ? dataPromo[0].Kuota : '',
                    VoucherSingleVoucher:  dataPromo[0] ? dataPromo[0].SingleVoucher : '',
                    VoucherMinimalBelanja:  dataPromo[0] ? dataPromo[0].MinimalBelanja : 0,
                    VoucherMaksimalPotongan:  dataPromo[0] ? dataPromo[0].MaksimalPotongan : 0,
                    VoucherImage:  dataPromo[0] ? dataPromo[0].Image : '', 
                    VoucherBatasPerUser:  dataPromo[0] ? dataPromo[0].BatasPerUser : '',
                    VoucherPersentase: dataPromo[0] ? dataPromo[0].Persentase : '',
            },
            voucherOngkirSelected: { 
                  VoucherOngkirID:  dataOngkir[0] ? dataOngkir[0].VoucherOngkirId : '',
                  VoucherOngkirNama:   dataOngkir[0] ? dataOngkir[0].Nama : '',
                  VoucherOngkirRingkasan:  dataOngkir[0] ? dataOngkir[0].Ringkasan : '',
                  VoucherOngkirBatasPerUser:  dataOngkir[0] ? dataOngkir[0].BatasPerUser : '',
                  VoucherOngkirKeterangan:  dataOngkir[0] ? dataOngkir[0].Keterangan : '',
                  VoucherOngkirKuota:  dataOngkir[0] ? dataOngkir[0].Kuota : '', 
                  VoucherOngkirMinimalBelanja:  dataOngkir[0] ? dataOngkir[0].MinimalBelanja : 0,
                  VoucherOngkirMaksimalPotongan:  dataOngkir[0] ? dataOngkir[0].MaksimalPotongan : 0,
                  VoucherOngkirImage:  dataOngkir[0] ? dataOngkir[0].Image : '' 
            } 
          }
        })
       
        dispatch({
          type: 'cart/HANDLE_STATE',
          field: 'showModalVoucher',
          value: false
        })

  } 
  function renderTitle() {
    return <h3 className="bolde-font--avenir-light bolde-font--uppercase bolde-font--center ">Pilih Voucher/Kode Promo</h3>
  }

  return (
    <>
      <Modal
        title={renderTitle()}
        visible={showModalVoucher}
        onCancel={handleClose}
        okText="OK"
        cancelText="Batal"
        width={520}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
        maskClosable={false}
        style={{ top: isMobileView ? modalTop : '100px' }}
        className='voucher-modal'
        footer={null}
      >
        
        <div className="py-2">  
          <h1 className="header-voucher  color-standar" >Gratis Ongkir</h1> 
                <Radio.Group  name="voucher_ongkir" onChange={handleChangeOngkir}    >
                <Space direction="vertical">  
                  {
                      voucherOngkir.map((x)=> 
                      <>
{/*                                        
                       {
                         (x.voucher_history_count  x.BatasPerUser) &&  
                            <Radio  value={x.VoucherOngkirId} disabled="true"> 
                              <div className="voucher-list-card" > 
                                  <img src={Variables.BASE_IMG_VOUCHER + x.Image} /> 
                                  <div className="disable-voucher"></div>      
                              </div> 
                            </Radio>   
                      }   */}
                      {
                          (x.voucher_history_count <= x.BatasPerUser) && (x.Kuota == 0)  && (total >= x.MinimalBelanja  ) &&
                            <Radio  value={x.VoucherOngkirId} disabled="true"> 
                              <div className="voucher-list-card" > 
                                  <img src={Variables.BASE_IMG_VOUCHER + x.Image} /> 
                                  <div className="disable-voucher-ongkir">
                                    
                                  <p> Voucher Habis</p>
                                    </div>      
                              </div> 
                            </Radio>   
                      }  
                      {
                         (x.voucher_history_count <= x.BatasPerUser) && (x.Kuota != 0) && 
                            <Radio  value={x.VoucherOngkirId}> 
                              <div className="voucher-list-card"  > 
                                  <img src={Variables.BASE_IMG_VOUCHER + x.Image} />  
                              </div> 
                            </Radio>   
                      } 
                      {
                         (x.voucher_history_count < x.BatasPerUser) && (x.Kuota != 0) && (x.MinimalBelanja > total ) &&
                            <Radio  value={x.VoucherOngkirId}> 
                              <div className="voucher-list-card"  > 
                                  <img src={Variables.BASE_IMG_VOUCHER + x.Image} />  
                                  <div className="disable-voucher-ongkir">
                                  <p>Minimal belanja {currencyFormat(x.MinimalBelanja)}</p>

                                    </div>    
                              </div> 
                            </Radio>   
                      } 
                      
                      </>
      
                      )
                    } 
                </Space>
              </Radio.Group>  

          <h1 className="header-voucher color-standar mt-4">Rekomendasi Promo </h1> 
              <Radio.Group name="voucher_promo" onChange={handleChangePromo}  >
                <Space direction="vertical">  
                  {
                      voucher.map((x)=> 
                        <>
                            {/* {
                              (x.voucher_history_count === x.BatasPerUser) &&  
                              <Radio value={x.VoucherId}  disabled="true">
                                <div className="voucher-list-card">
                                    <img src={Variables.BASE_IMG_VOUCHER + x.Image} /> 
                                    <div className="disable-voucher"></div>      
                                </div>
                              </Radio>   
                            } */}
                            {
                              (x.voucher_history_count <= x.BatasPerUser) && (x.Kuota == 0) && (total >= x.MinimalBelanja  ) &&
                              <Radio value={x.VoucherId} disabled="true"> 
                                <div className="voucher-list-card mb-2" >
                                    <img src={Variables.BASE_IMG_VOUCHER + x.Image} /> 
                                    <div className="disable-voucher"> 
                                        <p> Voucher Habis</p>
                                      </div>      
                                </div>
                              </Radio>   
                            }
                            {
                              (x.voucher_history_count <= x.BatasPerUser) && (x.Kuota != 0) && (total > x.MinimalBelanja  ) &&
                              <Radio value={x.VoucherId}>
                                <div className="voucher-list-card mb-2">
                                    <img src={Variables.BASE_IMG_VOUCHER + x.Image} />  
                                </div>
                              </Radio>   
                            }


                            { 
                            
                               (x.voucher_history_count < x.BatasPerUser) && (x.Kuota != 0) && (x.MinimalBelanja > total ) &&
                              <Radio   value={x.VoucherId}  disabled="true">
                                <div className="voucher-list-card">
                                    <img src={Variables.BASE_IMG_VOUCHER + x.Image} />  
                                    <div className="disable-voucher">
                                    <p>Minimal Belanja {currencyFormat(x.MinimalBelanja)}</p>
                                      
                                      </div>    
                                </div>
                              </Radio>   
                            }
                        </> 
                      )
                    } 
                </Space>
              </Radio.Group>
        </div>
        
        <div className="pt-5 text-center"> 
        <button className="bolde-btn__float px-3 py-2" onClick={()=>UseVoucher()}>Gunakan Voucher</button>
        </div>

      </Modal>

    </>
  );
}


export const ModalTraceOrder = () => {
  const dispatch = useDispatch() 
  const isMobileView = useSelector(({ settings }) => settings.isMobileView)
  const ShowModalTraceOrder = useSelector(({ account }) => account.ShowModalTraceOrder)    
  const orderStatus = useSelector(({ account }) => account.orderStatus)    
  const trackingStatus = useSelector(({ account }) => account.trackingStatus)    

  const handleClose = () => {
    dispatch({
      type: 'account/HANDLE_STATE',
      field: 'ShowModalTraceOrder',
      value: false
    })
  } 
 
  function renderTitle() {
    return <h3 className="bolde-font--avenir-light bolde-font--uppercase bolde-font--center text-center ">STATUS PESANAN</h3>
  }

  return (
    <>
      <Modal
        title={renderTitle()}
        visible={ShowModalTraceOrder}
        onCancel={handleClose} 
        width={650}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
        maskClosable={false}
        style={{ top: isMobileView ? modalTop : '100px' }}
        footer={false}
      > 
       
          <Row>
          {
                orderStatus && orderStatus.map((x)=>
                      <>
                          <Col span={6} className='mb-4 text-center'>
                            <div className='dream-status-order '>
                                <img className='img-status' src={Variables.BASE_IMG_LOCAL + '/status/'+ imgStts(x.Status)}/>
                                <p className="text-center">{SttsLabel(x.Status)}</p>
                              </div> 
                            </Col>  
                            <Col span={18}  className='mb-4'> 
                                <p>{moment(x.created_at).format('lll')}</p>
                                <h1 className="color-standar font-xbold">{HistoryStatus(x.Status)}</h1> 
                                {
                                  (x.Status == 4) && (trackingStatus.length > 0) &&
                                    <ul className="tracking-list">
                                      {
                                        trackingStatus.map((x)=>
                                          <li> 
                                            <p>{x.desc}</p> 
                                          </li>   
                                        )
                                      } 
                                    </ul>    
                                }
                            </Col>   
                      </> 
                      )
          }

 
          </Row> 
            
      </Modal>
    </>
  )
}


export const ModalCancelOrder = () => {
  const dispatch = useDispatch() 
  const isMobileView = useSelector(({ settings }) => settings.isMobileView)
  const showModalCancelOrder = useSelector(({ settings }) => settings.showModalCancelOrder)  
  const orderIdSelected = useSelector(({ account }) => account.orderIdSelected)  
  const [note, setNote] = useState('')
  const handleClose = () => {
    dispatch({
      type: 'settings/HANDLE_STATE',
      field: 'showModalCancelOrder',
      value: false
    })
  }

  const CancelPost = () =>{ 
    dispatch({
      type: 'account/CANCEL_ORDER', 
      payload:{
        OrderId:orderIdSelected,
        NoteRequest:note
      }
    })
  }
  const handleChangePromo = (event) => {
    setNote(event.target.value)
   }; 

 
  function renderTitle() {
    return <h3 className="bolde-font--avenir-light bolde-font--uppercase bolde-font--center text-center ">Choose a Reason</h3>
  }

  return (
    <>
      <Modal
        title={renderTitle()}
        visible={showModalCancelOrder}
        onCancel={handleClose} 
        width={500}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
        maskClosable={false}
        style={{ top: isMobileView ? modalTop : '100px' }}
        footer={false}
      >
        <Form
          name="formAddress"
          layout="vertical"
          requiredMark={false} 
          autoComplete="off"
        >
          <div style={{ marginBottom: '15px' }}>
           <span className="bullet-radio"></span> If you want cancel order, you should wait and give us a reason. Please choose one reason
          </div>  
              <Radio.Group onChange={handleChangePromo}>
                  <Space direction="vertical">
                    <Radio value={"Ingin mengubah alamat pengiriman/Want to change address"}>Ingin mengubah alamat pengiriman/Want to change address</Radio>
                    <Radio value={"Ingin menambah rincian produk lain/Buy more products"}>Ingin menambah rincian produk lain/Buy more products</Radio>
                    <Radio value={"Pesanan terlalu lama dikirimkan (lebih dari 3 hari)/Delivery process is to long"}>Pesanan terlalu lama dikirimkan (lebih dari 3 hari)/Delivery process is to long</Radio>
                    <Radio value={"(Lainnya/Berubah pikiran/Etc)"}>(Lainnya/Berubah pikiran/Etc)</Radio> 
                  </Space>
                </Radio.Group>   
          <Form.Item className='text-center'> 
            <Button onClick={CancelPost} className="ant-btn ant-btn-lg  bolde-bnt btn-global-bolde mt-3 px-4 py-2 shadow-sm"> Ok</Button>
          </Form.Item> 
        </Form>
      </Modal>
    </>
  )
}



export const ModalOtpComponent = () => {
  return (
    <>
      Modal OTP
    </>
  )
}

export const ModalLoginComponent = () => {
  return (
    <>
      Modal Login
    </>
  )
}
