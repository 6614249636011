const actions = {
  SET_STATE: 'auth/SET_STATE',
  HANDLE_STATE: 'auth/HANDLE_STATE',
  REGISTER: 'auth/REGISTER',
  CONFIRM_OTP_REGISTER: 'auth/CONFIRM_OTP_REGISTER',
  LOGIN: 'auth/LOGIN',
  CONFIRM_OTP_LOGIN: 'auth/CONFIRM_OTP_LOGIN',
  LOGOUT: 'auth/LOGOUT',
}

export default actions
