import { all } from 'redux-saga/effects'
import settings from './settings/sagas'
import home from './home/sagas'
import contact from './contact/sagas'
import article from './article/sagas'
import product from './product/sagas'
import cart from './cart/sagas'
import auth from './auth/sagas'
import account from './account/sagas'
import reseller from './reseller/sagas'
import skinanalis from './skinanalis/sagas'
import chat from './chat/sagas'
import journey from './journey/sagas'

export default function* rootSaga() {
  yield all([
    settings(),
    home(),
    contact(),
    article(),
    product(),
    auth(),
    cart(),
    account(),
    reseller(),
    skinanalis(),
    chat(),
    journey()
  ])
}
