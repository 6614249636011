import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './actions'
import { CART__ADD, CART__DELETE, CART__GET,  CART__NOTE, CART__UPDATE, CHECKOUT, PAYMENT, SHIPMENT, PAYMENT_METHOD, VOUCHER} from 'services/axios/cart'
import { GET_PRD_ALL } from 'services/axios/product';
import { message as msg } from 'antd';
import { REG__CITY, REG__SUBDISTRICT } from 'services/axios/region';
import { Redirect } from 'react-router-dom'; 
import {store} from 'index';

function cartItem(cartDetail) {
  const items = [] 
  cartDetail.map(x => {
    const varDetail = []
    const productCart = []
    if (x.variant !== null) {
      x.variant.variant_detail.map(y => varDetail.push({
        variantDetailID: parseInt(y.VariantDetailId),
        variantTypeID: parseInt(y.VarianTypeId),
        variantValueID: parseInt(y.ValueId),
        variantValue: y.value.Value,
       
      }))

      // x.variant.product.map(k => productCart.push({

      //     imageCart:k.product_image.Image

      // }))

      let cartItemTotal = 0
      if (x.variant.isdiskon === true) {
        if (parseInt(x.Qty) > parseInt(x.variant.DiscountKuota)) {
          const totalDiscPrice = parseInt(x.variant.DiscountEndPrice) * parseInt(x.variant.DiscountKuota)
          const totalNonDiscPrice = (parseInt(x.Qty) - parseInt(x.variant.DiscountKuota)) * parseInt(x.variant.Price)
          cartItemTotal = totalDiscPrice + totalNonDiscPrice
          console.log('totalDiscPrice + totalNonDiscPrice', totalDiscPrice, totalNonDiscPrice);
        } else {
          cartItemTotal = parseInt(x.Qty) * parseInt(x.variant.DiscountEndPrice)
        }
      } else {
        cartItemTotal = parseInt(x.Qty) * parseInt(x.variant.Price)
      }
      items.push({
        cartItemID: parseInt(x.CartDetailId),
        cartItemProductID: parseInt(x.variant.ProductId),
        cartItemName: x.variant.product.ProductName,
        cartItemNote: x.Note,
        cartItemQty: parseInt(x.Qty),
        cartItemVariantID: parseInt(x.VariantId),
        cartItemPrice: parseInt(x.variant.Price),
        cartItemTotal: cartItemTotal,
        cartItemImage: x.variant.product.product_image.Image,
        // cartProductImage:productCart,
        // cartItemImage: x.variant.Image,
        stock: x.variant.Stok,
        cartItemVarianDetail: varDetail, 
        cartItemIsDiscount: x.variant.isdiskon,
        cartItemDiscountAmount: x.variant.DiscountAmount,
        cartItemDiscountEndDate: x.variant.DiscountEndDate,
        cartItemDiscountEndPrice: x.variant.DiscountEndPrice,
        cartItemDiscountKuota: x.variant.DiscountKuota,
        cartItemDiscountLabel: x.variant.DiscountLabel,
        cartItemDiscountPercent: x.variant.DiscountPercent,
        cartItemDiscountStartDate: x.variant.DiscountStartDate,
        cartItemVarianDetail: varDetail,
        cartItemStock: parseInt(x.variant.Stok),
        cartWarning: parseInt(x.Qty) > parseInt(x.variant.Stok) ? true : false, 
        cartItemSlug: x.variant.product.Slug, 
        
      })
    }
  })
  return items
} 

function clearStorage() {
  localStorage.removeItem('email')
  localStorage.removeItem('namaDepan')
  localStorage.removeItem('namaBelakang')
  localStorage.removeItem('perusahaan')
  localStorage.removeItem('provinsi')
  localStorage.removeItem('city')
  localStorage.removeItem('subdistrict')
  localStorage.removeItem('provinsiName')
  localStorage.removeItem('cityName')
  localStorage.removeItem('subdistrictName')
  localStorage.removeItem('alamat')
  localStorage.removeItem('jenisAlamat')
  localStorage.removeItem('kodePos')
  localStorage.removeItem('noHandphone')
  localStorage.removeItem('shipment')
  localStorage.removeItem('services')
  localStorage.removeItem('ongkirVoucher')
  localStorage.removeItem('promoVoucher')
  localStorage.removeItem('ongkirVoucherAmount')
  localStorage.removeItem('promoVoucherAmount')
  localStorage.removeItem('ongkirVoucherName')
  localStorage.removeItem('promoVoucherName')
}

function toAccount() {
  window.location.href = 'checkout'
} 
export function* GET_DATA_PRODUCT() {
  yield put({
    type: 'cart/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const prd = yield call(GET_PRD_ALL);
 
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        productDataCart: prd.data,
        // currentPage: prd.current_page,
        // lastPage: prd.last_page,
        // totalProduct: prd.total
      }
    }) 
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* GET_CART() {
  const token = localStorage.getItem('token')
  yield put({
    type: 'cart/SET_STATE', 
    payload: {
      loading: true
    }
  })
  try {
    if (token) {
      const res = yield call(CART__GET); 
      if (res.Cart.length > 0) {
        const cartItems = yield call(cartItem, res.Cart[0].cart_detail)
        // console.log(res)
        // console.log(cartItems, )
        yield put({
          type: 'cart/SET_STATE',
          payload: {
            // cartID: res.Cart[0].CartId,
            // cartItems: cartItems,
            // cartTotalItems: cartItems.length
            cartID: res.Cart[0].CartId,
            cartNote: res.Cart[0].Note,
            cartItems: cartItems,
            cartTotalItems: cartItems.length
          }
        })
      } else {
        yield put({
          type: 'cart/SET_STATE',
          payload: {
            cartID: null,
            cartItems: [],
            cartTotalItems: 0
            
          }
        })
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* ADD_CART({ payload }) {
  yield put({
    type: 'cart/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(CART__ADD, payload);
    
   const lang = localStorage.getItem('i18nextLng')
    if (res.status & res.Cart > 0) {
      yield put({
        type: 'cart/SET_STATE',
        payload: {
          cartTotalItems: payload.currentCartTotalItems + 1
        }
      })

      const resd = yield call(CART__GET); 
      const cartItems = yield call(cartItem, resd.Cart[0].cart_detail) 
      yield put({
        type: 'cart/SET_STATE',
        payload: { 
          cartID: resd.Cart[0].CartId,
          cartNote: resd.Cart[0].Note,
          cartItems: cartItems, 
        }
      })
 
      if(lang === 'id'){
        msg.success('Produk berhasil ditambahkan ke keranjang')
      }else{ 
        msg.success('Item successfully added to cart')
      }
      store.dispatch({
        type:'settings/HANDLE_STATE',
        field:'ShowDrawerCart',
        value:true 
      })
    }
    if (res.status & res.Cart < 1) {

      if(lang === 'id'){
        msg.success('Produk berhasil dihapus dari keranjang')
      }else{  
       msg.success('Item successfully added to cart')
      }
      store.dispatch({
        type:'settings/HANDLE_STATE',
        field:'ShowDrawerCart',
        value:true 
      })

      const resd = yield call(CART__GET); 
      const cartItems = yield call(cartItem, resd.Cart[0].cart_detail) 
      yield put({
        type: 'cart/SET_STATE',
        payload: { 
          cartID: resd.Cart[0].CartId,
          cartNote: resd.Cart[0].Note,
          cartItems: cartItems, 
        }
      })
    }
    if (!res.status) {
      msg.error('Item failed to add to cart')
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* UPDATE_CART({ payload }) {
  yield put({
    type: 'cart/SET_STATE',
    payload: {
      cartItemID: payload.cartItemID,
      priceLoading: true
    }
  })
  try {
    yield call(CART__UPDATE, payload);
    yield GET_CART()
    // if (res.status & res.Cart > 0) {
    //   yield put({
    //     type: 'cart/SET_STATE',
    //     payload: {
    //       cartTotalItems: payload.currentCartTotalItems + 1
    //     }
    //   })
    //   message.success('Item successfully added to cart')
    // }
    // if (res.status & res.Cart < 1) {
    //   message.success('Item successfully added to cart')
    // }
    // if (!res.status) {
    //   message.error('Item failed to add to cart')
    // }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        cartItemID: null,
        priceLoading: false
      }
    })
  }
}

export function* DELETE_CART({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    yield call(CART__DELETE, payload);
    yield GET_CART()
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
          payload: {
         loading: false
    }
    })
  }
}

export function* GET_CHECKOUT({ payload }) {
  yield put({
    type: 'cart/SET_STATE',
    payload: {
      loading: true,
    }
  })
  try {
    const res = yield call(CHECKOUT, payload);
    if (res.status) {
      const toAccount = () => window.location.href = '/transaksi/shipping'
      yield toAccount()
    }

  } catch (error) {
    const { data: { message } } = error.response
    console.log(error.response);
    msg.error(message)
  } finally {
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        loading: false,
      }
    })
  }
}


export function* PAYMENTMETHOD(){ 
  yield put({
    type: 'cart/SET_STATE',
    payload: {
      loading: true,
    }
  })
  try {
    const res = yield call(PAYMENT_METHOD);
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        methodPaymentList: res
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        loading: false,
      }
    })
  }
}
export function* GET_CITY({ payload }) {
  yield put({
    type: 'cart/SET_STATE',
    payload: {
      loading: true,
    }
  })
  try {
    const res = yield call(REG__CITY, payload);
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        cities: res
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        loading: false,
      }
    })
  }
}

export function* GET_SUBDISTRICT({ payload }) {
  yield put({
    type: 'cart/SET_STATE',
    payload: {
      loading: true,
    }
  })
  try {
    const res = yield call(REG__SUBDISTRICT, payload);
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        subdistricts: res
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        loading: false,
      }
    })
  }
}

export function* GET_SHIPMENT({ payload }) {
  yield put({
    type: 'cart/SET_STATE',
    payload: {
      loading: true,
    }
  })
  try {
    const res = yield call(SHIPMENT, payload); 
    if (Array.isArray(res)) {
      yield put({
        type: 'cart/SET_STATE',
        payload: {
          shipment: res
        }
      })

    } else {
      yield put({
        type: 'cart/SET_STATE',
        payload: {
          shipment: []
        }
      })
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        loading: false,
      }
    })
  }
}

// export function* GET_PAYMENT({ payload }) {
//   yield put({
//     type: 'cart/SET_STATE',
//     payload: {
//       loading: true,
//     }
//   })
//   try {
//     const res = yield call(PAYMENT, payload);
//     if (res.status) {
//       localStorage.removeItem('email')
//       localStorage.removeItem('namaDepan')
//       localStorage.removeItem('namaBelakang')
//       localStorage.removeItem('perusahaan')
//       localStorage.removeItem('provinsi')
//       localStorage.removeItem('city')
//       localStorage.removeItem('subdistrict')
//       localStorage.removeItem('provinsiName')
//       localStorage.removeItem('cityName')
//       localStorage.removeItem('subdistrictName')
//       localStorage.removeItem('alamat')
//       localStorage.removeItem('jenisAlamat')
//       localStorage.removeItem('kodePos')
//       localStorage.removeItem('noHandphone')
//       localStorage.removeItem('shipment')
//       localStorage.removeItem('services')
//       window.location.href=res.data.redirect_url
//     }
//   } catch (error) {
//     console.log(error);
//   } finally {
//     yield put({
//       type: 'cart/SET_STATE',
//       payload: {
//         loading: false,
//       }
//     })
//   }
// }
export function* GET_PAYMENT({ payload }) {
  yield put({
    type: 'cart/SET_STATE',
    payload: {
      loading: true,
    }
  })
  try {
    const res = yield call(PAYMENT, payload);
    if (res.status) {
      yield call(clearStorage);
      yield put({
        type: 'cart/SET_STATE',
        payload: {
          cartID: null,
          cartNote: null,
          cartItemID: null,
          cartTotalItems: 0,
          cartItems: [],
          cities: [],
          subdistricts: [],
          shipment: [],
        }
      })
      if(res.data.OrderPaymentMethodDetail.PaymentMethodId === 4){
        window.location.href = res.data.OrderDream['generate-qr-code']
      }else{
        window.location.href =  '/transaksi/payment/invoice/' +  res.data.order_id
      }
      // window.location.href = res.data.redirect_url

    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        loading: false,
      }
    })
  }
}


export function* GET_VOUCHER(){

  yield put({
    type:'cart/SET_STATE',
    payload:{
      loading:true,
    }
  })
  try{
    const res = yield call(VOUCHER);
    if(res.status){
      yield put({
        type:'cart/SET_STATE',
        payload:{
          voucher:res.Voucher,
          voucherOngkir:res.VoucherOngkir
        }
      })

    }

  }catch(error) {
    console.log(error);
  } finally {
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        loading: false
      }
    })
  }

  
}
export function* POST_CART_NOTE({payload})
{ 
 
  yield put({
    type: 'cart/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {  
    
    const res = yield call(CART__NOTE, payload); 
    if (res.status) {
 
      } 

  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'cart/SET_STATE',
      payload: {
        loading: false
      }
    })
  }

}

export function* HANDLE_STATE({ field, value }) {
  yield put({
    type: 'cart/SET_STATE',
    payload: {
      [field]: value
    }
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.HANDLE_STATE, HANDLE_STATE),
    takeEvery(actions.GET, GET_CART),
    takeEvery(actions.ADD, ADD_CART),
    takeEvery(actions.UPDATE, UPDATE_CART),
    takeEvery(actions.DELETE, DELETE_CART),
    takeEvery(actions.CHECKOUT, GET_CHECKOUT),
    takeEvery(actions.CITY, GET_CITY),
    takeEvery(actions.SUBDISTRICT, GET_SUBDISTRICT),
    takeEvery(actions.SHIPMENT, GET_SHIPMENT),
    takeEvery(actions.ORDER, GET_PAYMENT),
    takeEvery(actions.GET_PRODUCT, GET_DATA_PRODUCT),
    takeEvery(actions.POST_NOTE_ORDER, POST_CART_NOTE),
    takeEvery(actions.GET_VOUCHER, GET_VOUCHER),
    takeEvery(actions.PAYMENTMETHOD, PAYMENTMETHOD),
    GET_CART()
  ])
} 
