import axios from 'axios'
import APIS from 'utils/apis'
import { getHeader } from 'utils/headers'

export function CART__GET() {
  return axios.get(APIS.API_CART, {headers: getHeader()}).then(res => res.data)
}

export function CART__ADD(payload) {
  return axios.post(APIS.API_CART, payload, {headers: getHeader()}).then(res => res.data)
}

export function CART__UPDATE(payload) {
  return axios.post(APIS.API_CART__QTY, payload, {headers: getHeader()}).then(res => res.data)
}

export function CART__DELETE(payload) {
  return axios.delete(APIS.API_CART, {headers: getHeader(), data: payload}).then(res => res.data)
}
export function CART__NOTE(payload) {  
  return axios.post(APIS.API_ORDER_NOTE, payload, {headers: getHeader()}).then(res => res.data)
} 

export function CHECKOUT(payload) {
  return axios.post(APIS.API_CHECKOUT, payload, {headers: getHeader()}).then(res => res.data)
}

export function SHIPMENT(payload) {
  return axios.post(APIS.API_SHIP, payload, {headers: getHeader()}).then(res => res.data)
}

export function PAYMENT(payload) {
  return axios.post(APIS.API_PAYMENT, payload, {headers: getHeader()}).then(res => res.data)
} 

export function VOUCHER(){
  return axios.get(APIS.API_VOUCHER, {headers: getHeader()}).then(res => res.data)
}

export function PAYMENT_METHOD(){
  return axios.get(APIS.API_METHODPAYMENT, {headers: getHeader()}).then(res => res.data)
}
