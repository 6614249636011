import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'components/grid';
import { CartDrawer } from 'components/cart_drawer';
import { Col, Dropdown, Menu, Row, Modal, Select } from 'antd';
import Variables from 'utils/variables';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ConsoleSqlOutlined, MenuOutlined } from '@ant-design/icons';
import Skeleton from 'react-loading-skeleton';
import { set } from 'store';
import { DownOutlined, MailOutlined } from '@ant-design/icons';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Redirect } from 'react-router-dom';

const logo = Variables.BASE_IMG_LOCAL + '/logo-dream.png';

//   const useWindowPosition = () => {
//   const [scrollPosition, setPosition] = useState(0);
//   useLayoutEffect(() => {
//     function updatePosition() {
//       setPosition(window.pageYOffset);
//     }
//     window.addEventListener('scroll', updatePosition);
//     updatePosition();
//     return () => window.removeEventListener('scroll', updatePosition);
//   }, []);
//  console.log(scrollPosition)
// }
const SubMenu = Menu.SubMenu;

const MenuCtg = ({ parent }) => {
  // const history = useHistory()
  return (
    <SubMenu className="link" title={<span
      className="bolde-font-custom_caviar_bold color-standar"
    // onClick={() => history.push('/products')}
    >

      {parent.Category}
    </span>}>
      {/* <Menu.Item key="5">Option 5</Menu.Item>
        <Menu.Item key="6">Option 6</Menu.Item>
        <SubMenu key="sub3" title="Submenu">
          <Menu.Item key="7">Option 7</Menu.Item>
          <Menu.Item key="8">Option 8</Menu.Item>
        </SubMenu> */}
    </SubMenu>
  )
}

const HeaderComponent = () => {
  const history = useHistory()
  const dispatch = useDispatch();
  const ref = useRef();
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn)
  const settings = useSelector(({ settings }) => settings);
  // const categoryData = useSelector(({ settings }) => settings.categoryData)
  // const categoryProduct = useSelector(({ settings }) => settings.categoryProduct)
  const categoryLoading = useSelector(({ home }) => home.categoryLoading)
  const searchLoading = useSelector(({ product }) => product.searchLoading)
  const searchData = useSelector(({ product }) => product.searchData)
  const cartTotalItems = useSelector(({ cart }) => cart.cartTotalItems)
  const wishlistCount = useSelector(({ account }) => account.wishlistCount)
  const cartItems = useSelector(({ cart }) => cart.cartItems)
  const marqueetext = useSelector(({ home }) => home.marquee)
  const categoryData = useSelector(({ home }) => home.categoryData)
  const notificationlist = useSelector(({ account }) => account.notification)
  const countNotif = useSelector(({ account }) => account.countNotif)
  // const auth = useSelector(({ auth }) => auth)


  const [productByCategory, setProductByCategory] = useState([])
  const [categorySelected, setCategorySelected] = useState(null)
  const [visible, setVisible] = useState(false);
  const [keyword, setKeyword] = useState('')
  const { isSidebarOpen, isMobileView } = settings;
  const [navbar, setNavbar] = useState(false)
  const [langSelected, setLangSelected] = useState(localStorage.getItem("i18nextLng"))
  const [container, setContainer] = useState(null);
  // const accountUrl = auth.isLoggedIn ? '/account' : '/login';
  // const { SubMenu } = Menu;
  const [current, setCurrent] = useState('mail')
  const { Option } = Select;
  const SubMenu = Menu.SubMenu;
  const MenuItemGroup = Menu.ItemGroup;

  const { t } = useTranslation()

  // const [menuAnchor, setMen 
  const handleClick = (e) => {

    setCurrent(e.key)
  }

  function changeLang(lang) {
    // alert(lang)
    i18next.changeLanguage(lang);
    setLangSelected(lang)
  }


  const handleSidebar = () => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isSidebarOpen',
        value: !isSidebarOpen,
      }
    });
  }


  const handleClose = () => {
    setVisible(false)
    setKeyword('')
    dispatch({
      type: 'product/HANDLE_STATE',
      field: 'searchData',
      value: [],
    });
  }


  const showDrawer = () => {
    dispatch({
      type: 'settings/HANDLE_STATE',
      field: 'ShowDrawerCart',
      value: true
    })

  }


  const handleChange = (e) => {
    const { value } = e.target
    setKeyword(value)
    if (value !== '') {
      dispatch({
        type: 'product/SEARCH',
        payload: {
          keyword: value,
        }
      });
    } else {
      dispatch({
        type: 'product/HANDLE_STATE',
        field: 'searchData',
        value: [],
      });
    }
  }
  const handleLogoutAccount = () => {
    dispatch({
      type: 'auth/LOGOUT'
    })
  }

  function renderTitle() {
    return (
      <>
        <input
          placeholder={t('SearchText1')}
          onChange={handleChange}
          value={keyword}
        />
      </>
    )
  }


  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setNavbar(false)

    } else if (window.scrollY > 70) {
      return setNavbar(true)

    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);




  const menu = (
    <>
      {
        !isLoggedIn &&
        <Menu className="bolde-font-custom__medium" style={{ top: '26px', width: '150px', padding: '0px' }}>
          <Menu.Item key="1"><Link to="/login">{t('Menu_overlay3')} </Link></Menu.Item>
          <Menu.Item key="2"  ><Link to="/register">{t('Menu_overlay4')} </Link></Menu.Item>
        </Menu>
      }
      {
        isLoggedIn &&
        <Menu className="bolde-font-custom__medium" style={{ top: '26px', width: '150px', padding: '0px' }}>
          <Menu.Item key="1"><Link to="/login">{t('Menu_overlay1')} </Link></Menu.Item>
          <Menu.Item key="2" ><Link to="#" onClick={handleLogoutAccount}>{t('Menu_overlay2')} </Link></Menu.Item>
        </Menu>
      }
    </>
  );


  const notification = (
    <Menu>
      <Menu.Item key="topIcon">
        <span style={{ color: '#cdc1c1' }}>{t('NotificationText1')}</span>
      </Menu.Item>
      <Menu.Divider />

      {
        notificationlist.length > 0 &&
        notificationlist.slice(0, 5).map((x, y) => {
          return <>
            <Menu.Item key={y}>
              {
                x.order &&
                <a rel="noopener noreferrer" href={`/order-detail/${x.order.OrderId}`}>
                  <div className="px-2" style={{ minWidth: "300px" }}>
                    {
                      (langSelected === 'id') &&
                      <>
                        <h1 ><b>{x.Judul}</b></h1>
                        <p>{x.Keterangan}</p>
                      </>
                    }
                    {
                      (langSelected === 'en-US') &&
                      <>
                        <h1 ><b>{x.Title}</b></h1>
                        <p>{x.Description}</p>
                      </>
                    }
                  </div>
                </a>
              }

              {
                !x.order &&
                <a rel="noopener noreferrer" href="#">
                  <div className="px-2" style={{ minWidth: "300px" }}>
                    {
                      (langSelected === 'id') &&
                      <>
                        <h1 ><b>{x.Judul}</b></h1>
                        <p>{x.Keterangan}</p>
                      </>
                    }
                    {
                      (langSelected === 'en-US') &&
                      <>
                        <h1 ><b>{x.Title}</b></h1>
                        <p>{x.Description}</p>
                      </>
                    }
                  </div>
                </a>
              }

            </Menu.Item>
            <Menu.Divider />
          </>
        })
      }
      {
        notificationlist.length < 1 &&
        <Menu.Item key="topNotif">
          <p>{t('NotificationText2')}</p>
        </Menu.Item>

      }

      <Menu.Item key="topNotifData">
        <div style={{ textAlign: 'center' }}>
          <span style={{ color: '#cdc1c1' }}>{t('NotificationText3')}</span>
        </div>
      </Menu.Item>
    </Menu>

  );




  const handleRedirect = () => {
    history.push('/')
  }
  const [isShowingAlert, setShowingAlert] = useState(false);

  const redirectPage = (cslug) => {
    return <Redirect to={`/category/${cslug}`} />
  }

  return (
    <>

      <div id="sticky-footer" className={`${isShowingAlert ? 'hidden' : ''}`}>
        <Container>
          <div className="bolde-font-custom__regular text-justify text-center">


            {
              (langSelected === 'id') &&
              marqueetext.text_id
            }
            {
              (langSelected === 'en-US') &&
              marqueetext.text_en
            }
          </div>
        </Container>
        <label className="float-right" style={{ marginTop: '-20px' }} onClick={() => setShowingAlert(true)} htmlFor="shut">  <img width="8" src={Variables.BASE_IMG_LOCAL + '/close icon.png'} /></label>
      </div>

      {/* <Affix offsetTop={1} onChange={(affixed) => console.log(affixed)}> */}

      <div className={navbar ? 'bolde-header  active_navbar' : 'bolde-header '}  >
        {/* <div className={navbar ? 'bolde-header    ' : 'bolde-header '} style={{backgroundColor:'white'}}> */}

        <Container position="static" justify="center">

          <Row justify="space-between px-4" align="middle">


            {
              isMobileView &&
              <>
                <Col xs={8} sm={8} md={8} lg={8} >
                  <div className="text-left bolde-menu bolde-menu__left">
                    <MenuOutlined onClick={handleSidebar} style={{ fontSize: '1.5rem' }} />
                  </div>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} >
                  <div className="text-right bolde-menu text-center">
                    <div className="bolde-logo  text-center" >
                      <img onClick={handleRedirect} src={logo} style={{ width: '45%' }} className="img-fluid" alt="" />
                    </div>
                  </div>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} >
                  <div className="text-right bolde-menu bolde-menu__right">
                    <ul>
                      <li className="bolde-menu--cart" onClick={showDrawer}>
                        <img src={Variables.BASE_IMG_LOCAL + '/trolley.png'} style={{ width: '25px' }} alt="Dream" />
                        {
                          cartTotalItems > 0 &&
                          <span>
                            {cartTotalItems}
                          </span>
                        }
                      </li>
                    </ul>
                  </div>
                </Col>
              </>
            }
            {
              !isMobileView &&
              <>
                <Col xs={6} sm={6} md={6} lg={8} >

                  <div className="d-flex flex-nowrap bd-highlight  justify-content-left mt-2">
                    <div className="order-3 p-2 bd-highlight text-center"><img src={Variables.BASE_IMG_LOCAL + '/no-alcohol.png'} style={{ width: '50px', height: '50px' }} alt="Alcohol Free" className="img-fluid img-footer-alchohol" /></div>
                    <div className="order-3 p-2 bd-highlight text-center"><img src={Variables.BASE_IMG_LOCAL + '/logo-bpom.png'} alt="BPOM logo" className="img-fluid img-footer-bpom" style={{ width: '63px', height: '51px' }} /></div>
                    <div className="order-2 p-2 bd-highlight text-center"><img src={Variables.BASE_IMG_LOCAL + '/No-tested-Animal-Logo.png'} style={{ width: '50px', height: '50px' }} alt="Alcohol Free" className="img-fluid img-footer-alchohol" /></div>
                  </div>
                </Col>
                <Col className="justify-content-center text-center  mt-1" xs={6} sm={6} md={6} lg={8} >
                  <div className="bolde-logo text-center" >
                    <img onClick={handleRedirect} src={logo} style={{ width: '22%' }} className="img-fluid" alt="" />
                  </div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={8} >

                  <div className="text-right bolde-menu bolde-menu__right">
                    <ul>
                      <li>
                        <div onClick={() => setVisible(true)}>
                          <img src={Variables.BASE_IMG_LOCAL + '/search.png'} style={{ width: '23px' }} alt="Dream" />
                        </div>
                      </li>
                      <li>
                        <Dropdown className="link" overlay={menu}>
                          <Link to="#" className="ant-dropdown-link">
                            <img src={Variables.BASE_IMG_LOCAL + '/account.png'} style={{ width: '23px' }} alt="Dream" />
                          </Link>
                        </Dropdown>
                      </li>
                      <li className="bolde-menu--cart" onClick={showDrawer}>

                        <img src={Variables.BASE_IMG_LOCAL + '/trolley.png'} style={{ width: '20px' }} alt="Dream" />
                        {
                          cartTotalItems > 0 &&
                          <span>
                            {cartTotalItems}
                          </span>
                        }
                      </li>
                      {
                        isLoggedIn &&
                        <li className="bolde-menu--cart">
                          <Link type="link" to="/wishlist">
                            <img src={Variables.BASE_IMG_LOCAL + '/Ikon Favorit 1.png'} style={{ width: '23px' }} alt="Dream" />
                            {
                              wishlistCount > 0 &&
                              <span>
                                {wishlistCount}
                              </span>
                            }
                          </Link>
                        </li>
                      }
                      {
                        isLoggedIn &&
                        <li className="bolde-menu--cart">
                          <Dropdown overlay={notification} placement="topRight" arrow>
                            <a href="#">
                              <img src={Variables.BASE_IMG_LOCAL + '/notification icon.png'} style={{ width: '23px' }} alt="Dream" />
                              {
                                countNotif > 0 &&
                                <span>
                                  {countNotif}
                                </span>
                              }
                            </a>
                          </Dropdown>
                        </li>
                      }
                      <li style={{ textAlign: "left !important", marginLeft: "none !important" }}>
                        <Select
                          onChange={e => changeLang(e)}
                          suffixIcon={<img src={Variables.BASE_IMG_LOCAL + '/panah subtitle.png'} style={{ width: '10px', marginTop: '-6px' }} />}
                          style={{ width: '79px', fontSize: '25px' }}
                          defaultValue={langSelected} style={{ width: 79 }} bordered={false}>
                          <Option value="en-US">ENG</Option>
                          <Option value="id">ID</Option>
                        </Select>
                      </li>
                    </ul>
                  </div>
                </Col>
              </>
            }
            <Col xs={12} sm={12} md={6} lg={7}>
              {/* {
                !isMobileView &&
                <div className="bolde-logo">
                  <img onClick={handleRedirect} src={logo} style={{width:'35%'}} className="img-fluid" alt="" />
                </div>
              }
              {
                isMobileView &&
                <div className="bolde-logo mx-auto">
                  <img onClick={handleRedirect} src={logo}  style={{width:'60px'}} className="img-fluid" alt="" />
                </div>
              } */}
            </Col>

            <Col style={{ position: 'static' }} xs={0} sm={0} md={24} lg={24} className=" align-middle">
              <Menu
                onClick={handleClick}
                selectedKeys={current}
                mode="horizontal"
                className="text-center bolde-menu"
                style={{ background: 'transparent', borderBottom: '0px', padding: '0px 29px !important' }}
              >
                {
                  categoryLoading &&
                  <Skeleton height={27} width={80} />
                }
                {
                  !categoryLoading &&
                  categoryData.map((x, y) =>
                    <>
                      {
                        !x.IsActive &&
                        <Menu.Item key={y}>
                          <Link to="/coming-soon" className="bolde-font-custom_caviar_bold color-standar" > {x.Category}</Link>
                        </Menu.Item>
                      }
                      {
                        x.IsActive &&
                        <SubMenu className="link" title={<span className="bolde-font-custom_caviar_bold color-standar" onClick={() => history.push('/products')}     >
                          {x.Category}
                        </span>}>
                          {
                            x.subMenu.length > 0 &&
                            x.subMenu.map((val, key) =>
                              <>
                                {
                                  val.productMenu.length < 1 &&
                                  <Menu.Item key={key}   >
                                    <Link to={`/category/${val.subSlug}`}> {val.Category}</Link>
                                  </Menu.Item>
                                }
                                {
                                  val.productMenu.length > 0 &&
                                  <SubMenu
                                    key={'sub' + key}
                                    title={val.Category}
                                  //  onClick={() => redirectPage(val.subSlug)}  

                                  >
                                    {
                                      val.productMenu.map((value, index) =>
                                        <Menu.Item key={index + key}>
                                          <Link to={`/product-detail/${value.productSlug}`}>    {value.ProductName}</Link>
                                        </Menu.Item>
                                      )
                                    }
                                  </SubMenu>
                                }
                              </>
                            )
                          }
                        </SubMenu>
                      }
                    </>
                  )
                }
                <Menu.Item key="ceritadream">
                  {
                    categoryLoading &&
                    <Skeleton height={27} width={80} />
                  }

                  {
                    !categoryLoading &&
                    <Link to="/dreamers-say" className="bolde-font-custom_caviar_bold color-standar" >{t('Menu1')}</Link>
                  }
                </Menu.Item>
                <Menu.Item key="artikel">
                  {
                    categoryLoading &&
                    <Skeleton height={27} width={80} />
                  }
                  {
                    !categoryLoading &&
                    <Link to="/magazine" className="bolde-font-custom_caviar_bold color-standar" >{t('Menu2')}</Link>
                  }
                </Menu.Item>
                <Menu.Item key="masalahkulit">
                  {
                    categoryLoading &&
                    <Skeleton height={27} width={80} />
                  }
                  {
                    !categoryLoading &&
                    <Link to="/skin-analys" className="bolde-font-custom_caviar_bold color-standar" >{t('Menu3')}</Link>
                  }
                </Menu.Item>
              </Menu>

            </Col>
            <Col xs={6} sm={6} md={4} lg={2}>

            </Col>
          </Row>
        </Container>
      </div>

      {/* </Affix> */}
      <CartDrawer cartproduct={cartItems} />

      <Modal
        title={renderTitle()}
        visible={visible}
        onOk={handleClose}
        onCancel={handleClose}
        width={1000}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
        footer={null}
        className="bolde-search"
      >
        <div className="bolde-search--wrapper">
          {
            searchLoading &&
            <div className="bolde-search--items">
              <div className="mb-1">
                <Skeleton height={30} width={300} />
              </div>
              <Skeleton height={20} width={150} />
            </div>
          }
          {
            !searchLoading &&
            searchData.map((val, key) =>
              <div key={key} className="bolde-search--items">
                <a onClick={handleClose} href={`/product-detail/${val.productSlug}`}>
                  {val.productName}
                </a>
                <p>{val.productCategory}</p>
              </div>
            )
          }
          {
            !searchLoading && searchData.length < 1 &&
            <div className="bolde-search--items bolde-search--items__no-border">
              <p> {t('SearchText2')} 'Facial Wash'</p>
            </div>
          }
        </div>
      </Modal>
    </>
  );
}

export default HeaderComponent;

// ReactDOM.render(<HeaderComponent />, mountNode);