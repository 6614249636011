 
import { all, takeEvery, put, call, take } from 'redux-saga/effects'
import actions from './actions' 
import {store} from 'index';
import { ORDER__DETAIL, ORDER__DETAIL_BY_INV,  GET_TRACE, GET_POINT, CANCEL_ORDER, GET_NOTIF, GET_WISHLIST, ADD_WISHLIST, DELETE_ADDRESS,  REMOVE_WISHLIST,  ORDER__GET, PROFILE__ADDRESS, PROFILE__ADDRESS_ADD, PROFILE__ADDRESS__UPDATE, PROFILE__GET, PROFILE__UPDATE, REVIEW__POST } from 'services/axios/account'; 
// import { ORDER__DETAIL, ORDER__GET, SUBSCRIBRE_POST, PROFILE__ADDRESS, PROFILE__ADDRESS__UPDATE, PROFILE__GET, PROFILE__UPDATE, REVIEW__POST } from 'services/axios/account';
import { message as msg } from 'antd';



function orderProccess(order) {
  const res = []
  order.map(x => res.push({
    createdAt: x.CreatedAt,
    courierCode: x.CourierCode,
    courierService: x.CourierService,
    invoiceNo: x.InvoiceNo,
    midtransRedirect: x.MidtransRedirect,
    midtransToken: x.MidtransToken,
    note: x.Note,
    orderId: x.OrderId,
    status: x.Status,
    resi: x.Resi,
    statusOrder: x.StatusOrder,
    statusPayment: x.StatusPayment,
    shippingCharge: x.ShippingCharge,
    total: x.Total,
    updatedAt: x.UpdatedAt,
    userId: x.UserId,
    totalItems: x.order_detail.length,
    order_cancel: x.order_cancel ? x.order_cancel : '',
    voucher: x.voucher ? x.voucher : ''
  }))

  return res
}


function itemProductOrderDetail(order){

const res = []
order.map(x => res.push({   

  productName: x.variant.product.ProductName,
  createdAt: x.CreatedAt,
  note: x.Note,
  orderDetailId: x.OrderDetailId,
  orderId: x.OrderId,
  qty: x.Qty,
  updatedAt: x.UpdatedAt,
  variantId: x.VariantId,
  variantImage: x.variant.product.product_image.Image,
  variantPrice: x.variant.Price,
  variantProductId: x.variant.ProductId,
  variantDetail: x.variant.variant_detail,
  discountAmount :x.order_detail_product.DiscountAmount,
  discountEndPrice:x.order_detail_product.DiscountEndPrice,
  discountLabel:x.order_detail_product.DiscountLabel,
  discountPercent:x.order_detail_product.DiscountPercent,   
  isdiskon:x.order_detail_product.isdiskon
}))
return res
}


function reviewProccess(order) {
  const res = [] 
  order.map(x => {
    x.order_detail.map(val =>
      res.push({
        orderDetailId: val.OrderDetailId,
        orderId: val.OrderId,
        productImage: val.variant.product.product_image.Image,
        productPrice: val.variant.Price,
        productName: val.variant.product.ProductName,
        qty: val.Qty,
        variantDetail: val.variant.variant_detail,
        review: val.review,
        statusOrder:x.StatusOrder
      }))
  })

  return res

}


function dataJsonToArray(address)
{ 
  const res = []  
    address.CustomerAddress.map(val => 
      res.push({  
        customerAddressId: val.CustomerAddressId,
        customerId: val.CustomerId,
        namaDepan: val.NamaDepan,
        namaBelakang: val.NamaBelakang,
        perusahaan: val.Perusahaan,
        alamat: val.Alamat,
        jenisAlamat: val.JenisAlamat,
        kecamatan: val.Kecamatan,
        kodePos: val.KodePos,
        noHandphone: val.NoHandphone,
        provinceID: val.kecamatan.province_id,
        provinceName: val.kecamatan.province,
        cityID: val.kecamatan.city_id,
        cityType: val.kecamatan.city.type,
        cityName: val.kecamatan.city.city_name,
        subdistrictID: val.kecamatan.subdistrict_id,
        subdistrictName: val.kecamatan.subdistrict_name,
        postCode: val.kecamatan.city.postal_code, 
      })) 
  return res

 
}

function orderDetailProccess(orderDetail) {
  const res = []
  orderDetail.map(x => res.push({
    productName: x.variant.product.ProductName,
    createdAt: x.CreatedAt,
    note: x.Note,
    orderDetailId: x.OrderDetailId,
    orderId: x.OrderId,
    qty: x.Qty,
    updatedAt: x.UpdatedAt,
    variantId: x.VariantId,
    variantImage: x.variant.product.product_image.Image,
    variantPrice: x.variant.Price,
    variantProductId: x.variant.ProductId,
    variantDetail: x.variant.variant_detail
  }))

  return res
}

function orderDetailAddress(order_alamat){  
  const x = order_alamat
  const  res = {
        NamaDepan:x.NamaDepan ? x.NamaDepan : '' ,
        NamaBelakang:x.NamaBelakang  ? x.NamaBelakang : '',
        NoHandphone:x.NoHandphone  ? x.NoHandphone : '',
        KodePos:x.KodePos ? x.KodePos : '',
        Kabupaten:x.Kabupaten ? x.Kabupaten : '',
        Kecamatan:x.Kecamatan  ? x.Kecamatan : '', 
        Alamat:x.Alamat  ? x.Alamat : ''
    }
  return res
} 

// function get_count_notif(data){

//   const datacount = data.filter(x => x.isRead === false)
//   const notifCount = datacount.length 
//   return parseInt(notifCount);  
// }
export function* GET_ORDER() {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(ORDER__GET);
    yield put({
      type: 'account/SET_STATE',
      payload: {
        orderData: orderProccess(res.Order),
        // currentPage: prd.current_page,
        // lastPage: prd.last_page,
        // totalProduct: prd.total
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* GET_MY_POINT(){
  
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(GET_POINT);
    if (res.status) {
      const order = res.Order
      yield put({
        type: 'account/SET_STATE',
        payload: {
          myPoint:res.Point 
        }
      })
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }

}

export function* GET_ORDER_DETAIL({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(ORDER__DETAIL, payload);
    if (res.status) {
      const order = res.Order 
      yield put({
        type: 'account/SET_STATE',
        payload: {
          orderDetail: {
            courierCode: order.CourierCode,
            courierService: order.CourierService,
            orderId: order.OrderId,
            userId: order.UserId,
            invoiceNo: order.InvoiceNo,
            midtransToken: order.MidtransToken,
            createdAt: order.CreatedAt,
            updatedAt: order.UpdatedAt,
            note: order.Note,
            resi: order.Resi,
            shippingCharge: order.ShippingCharge,
            total: order.Total,
            midtransRedirect: order.MidtransRedirect,
            bank:order.bank,
            vaNumber:order.va_number,
            metodePembayran:order.payment_type,
            statusPayment: order.StatusPayment,
            statusOrder: order.StatusOrder,
            items: orderDetailProccess(order.order_detail), 
            order_Product: itemProductOrderDetail(order.order_detail),
            namaDepan:order.order_alamat.NamaDepan,
            namaBelakang:order.order_alamat.NamaBelakang,
            noHandphone:order.order_alamat.NoHandphone,
            kodePos:order.order_alamat.KodePos,
            kabupaten:order.order_alamat.Kabupaten,
            kecamatan:order.order_alamat.Kecamatan, 
            alamat:order.order_alamat.Alamat,
            order_cancel:order.order_cancel ,
            payment_method_detail: order.payment_method_detail,

          },
          voucher:order.voucher, 
          orderStatus:order.order_status
        }
      })
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* GET_ORDER_DETAIL_BY_INV({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(ORDER__DETAIL_BY_INV, payload);
    if (res.status) {
      const order = res.Order 
      yield put({
        type: 'account/SET_STATE',
        payload: {
          orderDetail: {
            courierCode: order.CourierCode,
            courierService: order.CourierService,
            orderId: order.OrderId,
            userId: order.UserId,
            invoiceNo: order.InvoiceNo,
            midtransToken: order.MidtransToken,
            createdAt: order.CreatedAt,
            updatedAt: order.UpdatedAt,
            note: order.Note,
            resi: order.Resi,
            shippingCharge: order.ShippingCharge,
            total: order.Total,
            midtransRedirect: order.MidtransRedirect,
            bank:order.bank,
            vaNumber:order.va_number,
            metodePembayran:order.payment_type,
            statusPayment: order.StatusPayment,
            statusOrder: order.StatusOrder,
            items: orderDetailProccess(order.order_detail), 
            order_Product: itemProductOrderDetail(order.order_detail), 
            namaDepan:order.order_alamat.NamaDepan,
            namaBelakang:order.order_alamat.NamaBelakang,
            noHandphone:order.order_alamat.NoHandphone,
            kodePos:order.order_alamat.KodePos,
            kabupaten:order.order_alamat.Kabupaten,
            kecamatan:order.order_alamat.Kecamatan, 
            alamat:order.order_alamat.Alamat,
            order_cancel:order.order_cancel,
            payment_method_detail: order.payment_method_detail,
          },
          voucher:order.voucher, 
          orderStatus:order.order_status
        }
      })
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* GET_PROFILE() {
  const token = localStorage.getItem('token')
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    if (token) {
      const res = yield call(PROFILE__GET);
      yield put({
        type: 'account/SET_STATE',
        payload: {
          profileData: {
            ID: res.Customer.id,
            birthDate: res.Customer.BirthDate,
            createdAt: res.Customer.CreatedAt,
            createdBy: res.Customer.CreatedBy,
            email: res.Customer.Email,
            emailConfirm: res.Customer.EmailConfirm,
            emailConfirmCode: res.Customer.EmailConfirmCode,
            firstname: res.Customer.FirstName,
            lastname: res.Customer.LastName,
            password: res.Customer.Password,
            phoneNumber: res.Customer.PhoneNumber,
            phoneNumberConfirm: res.Customer.PhoneNumberConfirm,
            phoneNumberConfirmCode: res.Customer.PhoneNumberConfirmCode,
            updatedAt: res.Customer.UpdatedAt,
            updatedBy: res.Customer.UpdatedBy,
            userCode: res.Customer.UserCode,
            gender: res.Customer.Gender,
          },
        }
      })
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* GET_ALL_ADDRESS() {
  const token = localStorage.getItem('token')
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    if (token) {
      const address = yield call(PROFILE__ADDRESS); 
      // if (Array.isArray(address.CustomerAddress) && address.CustomerAddress.length > 0) {
        yield put({
          type: 'account/SET_STATE',
          payload: {
            listaddress: dataJsonToArray(address)
          }
        })
      // }
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* GET_TRACKING({ payload }) {
  const token = localStorage.getItem('token')
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    if (token) {
      const tracking = yield call(GET_TRACE, payload); 
      yield put({
        type: 'account/SET_STATE',
        payload: {
          trackingStatus:tracking.Trace
        } 
      })
    
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}


export function* GET_ADDRESS() {
  const token = localStorage.getItem('token')
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    if (token) {
      const address = yield call(PROFILE__ADDRESS);
       
      if (Array.isArray(address.CustomerAddress) && address.CustomerAddress.length > 0) {
        yield put({
          type: 'account/SET_STATE',
          payload: {
            profileAddress: {
              customerAddressId: address.CustomerAddress[0].CustomerAddressId,
              customerId: address.CustomerAddress[0].CustomerId,
              namaDepan: address.CustomerAddress[0].NamaDepan,
              namaBelakang: address.CustomerAddress[0].NamaBelakang,
              perusahaan: address.CustomerAddress[0].Perusahaan,
              alamat: address.CustomerAddress[0].Alamat,
              jenisAlamat: address.CustomerAddress[0].JenisAlamat,
              kecamatan: address.CustomerAddress[0].Kecamatan,
              kodePos: address.CustomerAddress[0].KodePos,
              noHandphone: address.CustomerAddress[0].NoHandphone,
              provinceID: address.CustomerAddress[0].kecamatan.province_id,
              provinceName: address.CustomerAddress[0].kecamatan.province,
              cityID: address.CustomerAddress[0].kecamatan.city_id,
              cityType: address.CustomerAddress[0].kecamatan.city.type,
              cityName: address.CustomerAddress[0].kecamatan.city.city_name,
              subdistrictID: address.CustomerAddress[0].kecamatan.subdistrict_id,
              subdistrictName: address.CustomerAddress[0].kecamatan.subdistrict_name,
              postCode: address.CustomerAddress[0].kecamatan.city.postal_code,
            }
          }
    
        })
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* UPDATE_PROFILE({ payload }) {

  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const lang = localStorage.getItem('i18nextLng')
    const res = yield call(PROFILE__UPDATE, payload);
    if (res.status) {
      yield call(GET_PROFILE)
      if(lang ==='id'){ 
        msg.success('Data akun berhasil disimpan.')
      }else{
        msg.success('Saved data successfully')
      }
    }
    // yield put({
    //   type: 'account/SET_STATE',
    //   payload: {
    //     profileData: {
    //       ID: res.Customer.id,
    //       birthDate: res.Customer.BirthDate,
    //       createdAt: res.Customer.CreatedAt,
    //       createdBy: res.Customer.CreatedBy,
    //       email: res.Customer.Email,
    //       emailConfirm: res.Customer.EmailConfirm,
    //       emailConfirmCode: res.Customer.EmailConfirmCode,
    //       name: res.Customer.Name,
    //       password: res.Customer.Password,
    //       phoneNumber: res.Customer.PhoneNumber,
    //       phoneNumberConfirm: res.Customer.PhoneNumberConfirm,
    //       phoneNumberConfirmCode: res.Customer.PhoneNumberConfirmCode,
    //       updatedAt: res.Customer.UpdatedAt,
    //       updatedBy: res.Customer.UpdatedBy,
    //       userCode: res.Customer.UserCode,
    //     }
    //   }
    // })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}
export function* ADD_ADDRESS({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {  
    
    const res = yield call(PROFILE__ADDRESS_ADD, payload); 
    if (res.status) {
      
    const lang = localStorage.getItem('i18nextLng')
    if(lang ==='id'){ 
      msg.success('Alamat berhasil disimpan.') 
    }else{ 
      msg.success('Address saved successfully') 
    }
      store.dispatch({
        type: 'settings/HANDLE_STATE',
        field: 'showModalFormAddress',
        value: false
      })

      const address = yield call(PROFILE__ADDRESS); 
        yield put({
          type: 'account/SET_STATE',
          payload: {
            listaddress: dataJsonToArray(address)
          }
        })
 
      }


  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}

export function* UPDATE_ADDRESS({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {  
    
    const res = yield call(PROFILE__ADDRESS__UPDATE, payload); 
    if (res.status) { 
      const lang = localStorage.getItem('i18nextLng')
      if(lang ==='id'){ 
        msg.success('Alamat berhasil disimpan.') 
      }else{ 
        msg.success('Address saved successfully') 
      }
      store.dispatch({
        type: 'settings/HANDLE_STATE',
        field: 'showModalFormAddress',
        value: false
      })

      const address = yield call(PROFILE__ADDRESS); 
        yield put({
          type: 'account/SET_STATE',
          payload: {
            listaddress: dataJsonToArray(address)
          }
        })



    }



   
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}



export function* GET_REVIEW() {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const order = yield call(ORDER__GET); 
    yield put({
      type: 'account/SET_STATE',
      payload: {
        orderReview: reviewProccess(order.Order)
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
} 

export function* GET_LIST_WISHLIST(){
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(GET_WISHLIST); 
    yield put({
      type: 'account/SET_STATE',
      payload: {
        wishlist:  res.Wishlist,
        wishlistCount:  res.Wishlist.length
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  } 
}
export function* GET_NOTIF_LIST(){
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(GET_NOTIF); 
    const data = res.data
    const count = data.filter(x => x.isRead === true)
    const notif = count.length

    yield put({
      type: 'account/SET_STATE',
      payload: {
        notification:  res.data,
        countNotif: notif
      }
    })
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  } 
}

export function* POST_WISHLIST({payload}){
  yield put({ 
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(ADD_WISHLIST, payload);
    if (res.status) {
     
         
          const lang = localStorage.getItem('i18nextLng')
          if(lang ==='id'){ 
            msg.success('Produk berhasil ditambahkan ke favorit')
          }else{ 
            msg.success('Item successfully added to wishlist')
          }

          yield GET_LIST_WISHLIST()
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }

} 


export function* DELETE_WISHLIST({payload}){
  yield put({ 
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(REMOVE_WISHLIST, payload);
    if (res.status) {
 
          // msg.success('Item successfully remove from wishlist')
          const lang = localStorage.getItem('i18nextLng')
          if(lang ==='id'){ 
            msg.success('Produk berhasil dihapus dari favorit')
          }else{ 
            msg.success('Item successfully remove from wishlist')
          }
          yield GET_LIST_WISHLIST()
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }

} 

export function* POST_CANCEL_ORDER({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(CANCEL_ORDER, payload);
    if (res.status) { 

      store.dispatch({
        type: 'settings/HANDLE_STATE',
        field: 'showModalCancelOrder',
        value: false
      })

      yield GET_ORDER()
    }
  } catch (error) {
    const { data: { message } } = error.response
    msg.error(message)
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}
export function* POST_REVIEW({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(REVIEW__POST, payload);
    if (res.status) {
      const toReview = () => window.location.href = '/review'
      yield toReview()
    }
  } catch (error) {
    const { data: { message } } = error.response
    msg.error(message)
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }
}


export function* DELETE_ADDRESS_USER({payload}){ 
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true
    }
  })
  try {
    const res = yield call(DELETE_ADDRESS, payload);
    if (res.status) {
      // const toReview = () => window.location.href = '/review'
      // yield toReview()
      yield GET_ALL_ADDRESS()
    }
  } catch (error) {
    const { data: { message } } = error.response
    msg.error(message)
  } finally {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false
      }
    })
  }

  
}
export function* HANDLE_STATE({ field, value }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      [field]: value
    }
  })
}


// export function* SUBSCRIBRE_EMAIL({ payload }) {
//   yield put({
//     type: 'account/SUBSCRIBE',
//     payload: {
//       loading: true
//     }
//   })
//   try {
//     const res = yield call(SUBSCRIBRE_POST, payload);

//     console.log(res);
//     if (res.status) {
//       msg.success("Subscribe berhasil.")
//     }
//   } catch (error) { 
//     msg.error("Terjadi kesalahan. Silahkan coba beberapa saat lagi.")
//   } finally {
//     yield put({
//       type: 'account/SUBSCRIBE',
//       payload: {
//         loading: false
//       }
//     })
//   }
// }


export default function* rootSaga() {
  yield all([
    takeEvery(actions.HANDLE_STATE, HANDLE_STATE),
    takeEvery(actions.ORDER, GET_ORDER),
    takeEvery(actions.ORDER_DETAIL, GET_ORDER_DETAIL),
    takeEvery(actions.GET_ORDER_DETAIL_BY_INV, GET_ORDER_DETAIL_BY_INV),
    takeEvery(actions.GET_PROFILE, GET_PROFILE),
    takeEvery(actions.UPDATE_PROFILE, UPDATE_PROFILE),
    takeEvery(actions.GET_ADDRESS, GET_ADDRESS),
    takeEvery(actions.UPDATE_ADDRESS, UPDATE_ADDRESS),
    takeEvery(actions.ADD_ADDRESS, ADD_ADDRESS),
    takeEvery(actions.GET_REVIEW, GET_REVIEW),
    takeEvery(actions.ADD_REVIEW, POST_REVIEW),
    takeEvery(actions.LIST_ADDRESS, GET_ALL_ADDRESS),
    takeEvery(actions.GET_MYPOINT, GET_MY_POINT),
    takeEvery(actions.POST_WISHLIST, POST_WISHLIST),
    takeEvery(actions.DELETE_WISHLIST, DELETE_WISHLIST),
    takeEvery(actions.DELETE_ADDRESS, DELETE_ADDRESS_USER),
    takeEvery(actions.CANCEL_ORDER, POST_CANCEL_ORDER),
    takeEvery(actions.GET_TRACKING, GET_TRACKING),
    // takeEvery(actions.GET_NOTIF, GET_NOTIF_LIST),
    // takeEvery(actions.SUBSCRIBE, SUBSCRIBRE_EMAIL),
    GET_NOTIF_LIST(),
    GET_PROFILE(),
    GET_ADDRESS(),
    GET_LIST_WISHLIST()
  ])
}
