import actions from './actions'

const initialState = {
  loading: false,
  bannerLoading: false,
  articleLoading: false,
  categoryLoading: false,
  catalogLoading: false,
  productLoading: false,
  testimoniloading:false,
  bannerData: [],
  categoryData: [],
  catalogData: [], 
  productData: [],
  articleData: [],
  testimoniData:[],
  marquee: '',
  marqueeLoading:false
}

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
