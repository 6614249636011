import ENDPOINTS from './endpoints';

// import Variable from 'utils/variables';

// const BASE_URL = 'https://demodashboard.doctorxeam.com/';
const BASE_URL = 'https://dashboard.doctorxeam.com/';


const APIS = {
  API_SIGNUP: BASE_URL + ENDPOINTS.EP_SIGNUP,
  API_LOGIN: BASE_URL + ENDPOINTS.EP_LOGIN,
  API_SUBCRIPTION: BASE_URL + ENDPOINTS.EP_SUBCRIPTION,
  API_BANNER: BASE_URL + ENDPOINTS.EP_BANNER,
  API_BANNER__CTG: BASE_URL + ENDPOINTS.EP_BANNER__CTG,
  API_CATEGORY: BASE_URL + ENDPOINTS.EP_CATEGORY,
  API_PRODUCT: BASE_URL + ENDPOINTS.EP_PRODUCT,
  API_VARIANTS: BASE_URL + ENDPOINTS.EP_VARIANTS,
  API_VARIANT__DETAIL: BASE_URL + ENDPOINTS.EP_VARIANT__DETAIL,
  API_VARIANT__TYPE: BASE_URL + ENDPOINTS.EP_VARIANT__TYPE,
  API_VARIANT__VALUE: BASE_URL + ENDPOINTS.EP_VARIANT__VALUE,
  API_ARTICLE: BASE_URL + ENDPOINTS.EP_ARTICLE,
  API_CART: BASE_URL + ENDPOINTS.EP_CART,
  API_CART__QTY: BASE_URL + ENDPOINTS.EP_CART__QTY,
  API_CHECKOUT: BASE_URL + ENDPOINTS.EP_CHECKOUT,
  API_PROVINCE: BASE_URL + ENDPOINTS.EP_PROVINCE,
  API_CITY: BASE_URL + ENDPOINTS.EP_CITY,
  API_SUBDISTRICT: BASE_URL + ENDPOINTS.EP_SUBDISTRICT,
  API_SHIP: BASE_URL + ENDPOINTS.EP_SHIP,
  API_PAYMENT: BASE_URL + ENDPOINTS.EP_PAYMENT,
  API_ORDER: BASE_URL + ENDPOINTS.EP_ORDER,
  API_PROFILE: BASE_URL + ENDPOINTS.EP_PROFILE,
  API_PROFILE__ADDRESS: BASE_URL + ENDPOINTS.EP_PROFILE__ADDRESS,
  API_REVIEW: BASE_URL + ENDPOINTS.EP_REVIEW,
  API_RESELLER: BASE_URL + ENDPOINTS.EP_RESELLER,
  API_MEMBER_ADDRES: BASE_URL + ENDPOINTS.EP_MEMBER_ADDRESS,
  API_POINT: BASE_URL + ENDPOINTS.EP_MEMBER_POINT,
  API_MARQUEE: BASE_URL + ENDPOINTS.EP_MARQUEE,
  API_WISHLIST: BASE_URL + ENDPOINTS.EP_WISHLIST,
  API_CATALOG: BASE_URL + ENDPOINTS.EP_CATALOG,
  API_SKIN_ANALIS: BASE_URL + ENDPOINTS.EP_SKIN_ANALIS,
  API_ORDER_NOTE: BASE_URL + ENDPOINTS.EP_ORDER_NOTE,
  API_ORDER_NOTIF: BASE_URL + ENDPOINTS.EP_ORDER_NOTIF,
  API_CONTACT_US: BASE_URL + ENDPOINTS.EP_CONTACT_US,
  API_VOUCHER: BASE_URL + ENDPOINTS.EP_VOUCHER,
  API_CANCEL: BASE_URL + ENDPOINTS.EP_CANCEL,
  API_TRACE: BASE_URL + ENDPOINTS.EP_TRACE,
  API_CHAT: BASE_URL + ENDPOINTS.EP_CHAT,
  API_CERITA: BASE_URL + ENDPOINTS.EP_CERITA,
  API_METHODPAYMENT: BASE_URL + ENDPOINTS.EP_METHODPAYMENT,
  API_JOURNEY: BASE_URL + ENDPOINTS.EP_JOURNEY,
  API_JENIS_KULIT: BASE_URL + ENDPOINTS.EP_JENIS_KULIT,
  API_KEBIASAAN: BASE_URL + ENDPOINTS.EP_KEBIASAAN,
  API_PERAWATANKULIT: BASE_URL + ENDPOINTS.EP_PERAWATANKULIT,
  API_BA: BASE_URL + ENDPOINTS.EP_BA,
  API_BA_REV: BASE_URL + ENDPOINTS.EP_BA_REV,
}

export default APIS