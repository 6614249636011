const actions = {
  SET_STATE: 'product/SET_STATE',
  HANDLE_STATE: 'product/HANDLE_STATE',
  DATA: 'product/DATA',
  BY_CAT: 'product/BY_CAT',
  SEARCH: 'product/SEARCH',
  DETAIL: 'product/DETAIL',
  LOGIN:'product/LOGIN',
}

export default actions
 