const actions = {
  SET_STATE: 'home/SET_STATE',
  HANDLE_STATE: 'home/HANDLE_STATE',
  BANNER: 'home/BANNER',
  CATEGORY: 'home/CATEGORY',
  PRODUCT: 'home/PRODUCT',
  ARTICLE: 'home/ARTICLE',
  CATALOG: 'home/CATALOG',
  TESTIMONIAL: 'home/TESTIMONIAL',
  // GET_MARQUEETEXT: 'home/GET_MARQUEETEXT',
}

export default actions
