import React from 'react';
import HeaderComponent from '../Header';
import FooterComponent from '../Footer';
import SidebarComponent from '../Sidebar';
import { Affix } from 'antd';
 
const Public = ({ children }) => {
  fetch('https://geolocation-db.com/json/')
  .then(response => response.json())
  .then((jsonData) => { 
       localStorage.setItem('ip_address', jsonData.IPv4) 
  })
  .catch((error) => {
    // handle your errors here
    console.error(error)
  })

return (
<> 

        <SidebarComponent>
        {/* <Affix offsetTop={1} onChange={(affixed) => console.log(affixed)}>  */}
        <HeaderComponent   />
        {/* </Affix>  */}
        {children}
        <FooterComponent /> 
        
        </SidebarComponent> 
      </>
)
}
export default Public;