import React from 'react';
import HeaderMarket from '../HeaderMarket'; 




const Market = ({ children }) => {
    return (
    <> 
    <HeaderMarket/>
            {children} 
   </>
    )
}
export default Market;