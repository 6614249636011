import actions from './actions'

const initialState = {
  loading: false,
  bannerLoading: false,
  account: [],
  token: '',
  user: [],
  exp: '',
  iat: '',
  iss: '',
  IsReseller:'',
  isLoggedIn: false, 
  otpModal: false,
  otpStatus: 'signin',
  account: '',
}

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
